import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { UtilsModule } from '@tgw-clients/shared/utils/utils.module';
import { InlineSVGModule } from 'ng-inline-svg';
import { MaterialModule } from '../material';
import { LocalizePipe } from './pipes/localize.pipe';
import { SortPipe } from './pipes/sort.pipe';

const MODULES = [
  CommonModule,
  ReactiveFormsModule,
  MaterialModule,
  InlineSVGModule,
  UtilsModule
];
const PIPES = [
  LocalizePipe,
  SortPipe
];

@NgModule({
  imports: MODULES,
  declarations: PIPES,
  exports: [...MODULES, ...PIPES]
})
export class SharedModule { }
