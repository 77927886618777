import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { CoreConfigService, Footer, fromRouter, Logo, MenuItem } from '@tgw-clients/shared';
import { map } from 'rxjs/operators';

@Component({
  selector: 'tgw-old-section-footer',
  template: `
    <div [class.footer-b2b]="businessType === 'b2b'" class="container">
        <div class="footer-intro" *ngIf="businessType === 'b2b'">
          <p class="title">{{'tgw-winery-header-title' | translate}}</p>
          <p>{{'tgw-winery-header-subtitle' | translate}}</p>
        </div>
        <div class="logo-mobile" [class.footer-b2b-logo]="businessType === 'b2b'">
              <a [routerLink]="['/' | localize:lang]">
                  <img [src]="logo" alt="The Grand Wines" />
              </a>
          </div>

        <div class="row footer-row" [class.footer-b2b-footer-row]="businessType === 'b2b'">
          <div class="logo-desktop">
              <a [routerLink]="['/' | localize:lang]">
                  <img [src]="logo" alt="The Grand Wines" />
              </a>
          </div>
          <div class="social-wrapper" [class.footer-b2b-social-wrapper]="businessType === 'b2b'">
            <div class="social"  [class.footer-b2b-social]="businessType === 'b2b'" >
              <a [class.fillwhite]="businessType === 'b2c'" *ngFor="let item of menus?.social" [href]="item.url" target="_blank">
                <span [class.fillwhite]="businessType === 'b2c'" *ngIf="item.tgw_icon" [inlineSVG]="item.tgw_icon"></span>
              </a>
            </div>

            <div  class="newsletter">
              <label>{{'tgw-newsletter-text' | translate}}</label>
              <mat-form-field  [class.footer-b2b-button]="businessType === 'b2b'"  [class.mat-form-field-black]="businessType === 'b2c'" appearance="outline">
                <input matInput [formControl]="newsletterEmail" type="email"/>
                <button [class.button-black]="businessType === 'b2c'" mat-raised-button matSuffix type="button" (click)="subscribeNewsletter()" [disabled]="newsletterEmail.disabled">{{'tgw-newsletter-button' | translate}}</button>
              </mat-form-field>
              <mat-checkbox [class.mat-checkbox-black]="businessType === 'b2c'" [formControl]="privacyCheck">{{'tgw-newsletter-privacy-check-label' | translate}}</mat-checkbox>
            </div>
          </div>
          <div class="main">
            <div *ngFor="let col of menus?.main && getColumns(menus?.main, 4)" class="main-mobile">
              <a [class.span-black]="businessType === 'b2c'" *ngFor="let item of col" [href]="item.tgw_slug">{{item.title}}</a>
            </div>
            <div *ngFor="let col of menus?.main && getColumns(menus?.main, 3)" class="main-desktop">
              <!-- fix bodegas href to take it from twg_slug -->
              <a [class.span-black]="businessType === 'b2c'" *ngFor="let item of col" [href]="item.title === 'Bodegas' ? '/bodegas' : item.tgw_slug ">{{item.title}} </a>
            </div>
          </div>
        </div>

        <div class="bottom" [class.rao]="brand === 'rao'" [class.footer-b2b-bottom]="businessType === 'b2b'">
          <div class="bottom-wrapper"  [class.footer-b2b-wrapper]="businessType === 'b2b'" >
            <span>{{'tgw-footer-copyright' | translate | uppercase }}</span>
            <a [class.span-black]="businessType === 'b2c'" *ngFor="let item of menus?.bottom" [href]="item.tgw_slug">{{item.title}}</a>
          </div>
          <div *isBrand="'rao'" class="footer-bottom-logo" [class.footer-b2b-bottom]="businessType === 'b2b'">
            <img *ngIf="businessType == 'b2c' " src="assets/icons/araba_foral_white.png" />
            <span *ngIf="businessType != 'b2c'" inlineSVG="assets/icons/logo_alava.svg"></span>
          </div>
          <div class="trust-logo" *ngIf="businessType === 'b2b'">
            <span [inlineSVG]="trustLogo"></span>
          </div>
        </div>
      </div>
  `,
  styleUrls: ['./old-section-footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OldSectionFooterComponent {
  @Input() lang: string;
  @Input() menus: Footer;
  @Input() set newsletterPending(pending: boolean) {
    if (pending && this.newsletterEmail.enabled) {
      this.newsletterEmail.disable();
    } else if (!pending && this.newsletterEmail.disabled) {
      this.newsletterEmail.reset({ value: '', disabled: false });
    }
  }
  @Output() subscribe = new EventEmitter<string>();
  @Output() notifyError = new EventEmitter<string>();

  businessType =  this._coreConfig.businessType;
  logo = this.businessType === "b2c" ? this._coreConfig.getLogo(Logo.Footer) : this._coreConfig.getLogo(Logo.Header);
  brand = this._coreConfig.brand;
  newsletterEmail = new FormControl('', [Validators.email, Validators.required]);
  privacyCheck = new FormControl(false, [Validators.requiredTrue]);
  trustLogo = this._coreConfig.getLogo(Logo.Trust);
  constructor(private _coreConfig: CoreConfigService, private store: Store) { }

  subscribeNewsletter(): void {
    if (this.privacyCheck.invalid) {
      this.notifyError.next('tgw-newsletter-privacy-check-required');
      return;
    }
    if (this.newsletterEmail.invalid) {
      const message = this.newsletterEmail.errors?.required ? 'tgw-newsletter-email-required' : 'tgw-form-errors-email';
      this.notifyError.next(message);
      return;
    }

    this.subscribe.next(this.newsletterEmail.value);
  }

  getColumns(menu: MenuItem[], itemsPerColumn: number): Array<MenuItem[]> {
    const columns = [];
    for (let i = 0; i < menu.length; i += itemsPerColumn) {
      columns.push(menu.slice(i, i + itemsPerColumn));
    }
    return columns;
  }
}