import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromAuth from './auth.reducer';

export const authKey = 'auth';

export interface State {
  [authKey]: fromAuth.State
};

export const reducer = fromAuth.reducer;

export const getAuthState = createFeatureSelector<State, fromAuth.State>(authKey);

export const isLoginPending = createSelector(
  getAuthState,
  fromAuth.isLoginPending
);

export const getUser = createSelector(
  getAuthState,
  fromAuth.getUser
);

export const getUserId = createSelector(
  getAuthState,
  fromAuth.getUserId
);

export const getBilling = createSelector(
  getAuthState,
  fromAuth.getUserBilling
);

export const getUsername = createSelector(
  getAuthState,
  fromAuth.getUsername
);

export const isLoggedIn = createSelector(
  getUser,
  (user) => !!user
);

export const getRegisterError = createSelector(
  getAuthState,
  fromAuth.getRegisterError
);

export const getUserIdDocumentNumber = createSelector(
  getAuthState,
  fromAuth.getUserIdDocumentNumber
);

export const getUserRappel = createSelector(
  getAuthState,
  fromAuth.getUserRappel
);

export const getBillingInfo = createSelector(
  getUserId,
  getBilling,
  getUserIdDocumentNumber,
  (id, billing, nid) => ({ ...billing, id, nid })
);

export const getRestorePasswordPending = createSelector(
  getAuthState,
  fromAuth.getRestorePasswordPending
);

export const getNewPasswordPending = createSelector(
  getAuthState,
  fromAuth.getNewPasswordPending
);

export const getRegisterPending = createSelector(
  getAuthState,
  fromAuth.getRegisterPending
);

export const getShippingAddressStatus = createSelector(
  getAuthState,
  fromAuth.getShippingAddressStatus
);

export const getBillingAddressStatus = createSelector(
  getAuthState,
  fromAuth.getBillingAddressStatus
);

export const getShippingAddresses = createSelector(
  getAuthState,
  fromAuth.getShippingAddresses
);

export const getShippingAddressById = (id: string) => createSelector(
  getShippingAddresses,
  (shippingAddresses) => shippingAddresses?.find(({ id: shippingId }) => id === shippingId.toString())
);

export const getShippingAddressesStates = createSelector(
  getShippingAddresses,
  (addresses) => addresses.map(({ state }) => state)
);

export const getShippingAddressesPostcodes = createSelector(
  getShippingAddresses,
  (addresses) => addresses.map(({ postcode }) => postcode)
);

export const getAccountInfoPending = createSelector(
  getAuthState,
  fromAuth.getAccountInfoPending
);

export const getUserOrders = createSelector(
  getAuthState,
  fromAuth.getUserOrders
);

export const getUserOrderById = (orderId: string) => createSelector(
  getUserOrders,
  (orders) => (orders || []).find(({ id }) => id === +orderId)
);

export const getUserOrdersError = createSelector(
  getAuthState,
  fromAuth.getUserOrdersError
);

export const getWarehouses = createSelector(
  getAuthState,
  fromAuth.getWarehouses
);

export const getProductInventoryId = (id: string) => createSelector(
  getWarehouses,
  (productInventory) => productInventory?.[id]
);

export const getUserOrdersPending = createSelector(
  getAuthState,
  fromAuth.getUserOrdersPending
);

export const isWarehousePending = createSelector(
  getAuthState,
  fromAuth.isWarehousePending
);

export const getPending = createSelector(
  getAuthState,
  fromAuth.getPending
);

export const hasWarehouseError = createSelector(
  getAuthState,
  fromAuth.hasWarehouseError
);

export const getMethodsPerAddress = createSelector(
  getAuthState,
  fromAuth.getMethodsPerAddress
);

export const getAddressMethod = (addressId: number) => createSelector(
  getMethodsPerAddress,
  (methodsPerAddress) => methodsPerAddress?.[addressId] || null
);
