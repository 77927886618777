import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { RouterEffects } from './effects/router.effects';

@NgModule({
  imports: [
    EffectsModule.forFeature([RouterEffects]),
    StoreRouterConnectingModule.forRoot()
  ]
})
export class AutoRouterModule { }
