

import { createReducer, on } from '@ngrx/store';
import { LayoutActions } from '../actions';
import { Carousel, Footer, MenuItem } from '../models';

export interface State {
  footer: Footer;
  header: MenuItem[];
  carousels?: { [name: string]: Carousel };
  menus: { [name: string]: MenuItem[] }
  newsletterPending: boolean;
};

const initialState: State = {
  newsletterPending: false,
  header: [],
  footer: null,
  menus: {},
};

const layoutReducer = createReducer(
  initialState,
  on(LayoutActions.loadLayoutSuccess, (state, { header, footer }) => ({
    ...state, header, footer: footer
  })),
  on(LayoutActions.loadMenuSuccess, (state, { name, menu }) => ({
    ...state, menus: { ...state.menus, [name]: menu }
  })),
  on(LayoutActions.subscribeNewsletter, (state) => ({ ...state, newsletterPending: true })),
  on(LayoutActions.subscribeNewsletterSuccess, LayoutActions.subscribeNewsletterFailure, (state) => ({ ...state, newsletterPending: false })),
  on(LayoutActions.notifySubscribeB2cSuccess, LayoutActions.notifySubscribeB2cError, (state) => ({ ...state, newsletterPending: false })),
  on(LayoutActions.saveCarousel, (state, { name, carousel }) => ({ ...state, carousels: { ...(state.carousels || {}), [name]: carousel } })),
  on(LayoutActions.subscribeB2c, (state) => ({ ...state, newsletterPending: false })),
);

export function reducer(state: State | undefined, action: LayoutActions.LayoutActionsUnion) {
  return layoutReducer(state, action);
};

export const getFooter = (state: State) => state?.footer;

export const getHeader = (state: State) => state?.header;

export const getNewsletterPending = (state: State) => state.newsletterPending;

export const getCarousels = (state: State) => state?.carousels || {};

export const getMenus = (state: State) => state?.menus || {};
