import { createReducer, on } from '@ngrx/store';
import { ClubsActions } from '../actions';
import { Product } from '../models';

export const clubsKey = 'clubs';

export interface State {
  products: { [page: number]: Product[] };
  currentPage: number;
  totalPages: number;
  pending:boolean;
}

const initialState: State = {
  products: {},
  currentPage: 1,
  totalPages: 1,
  pending: false
}

const cubsReducer = createReducer(
  initialState,
  on(ClubsActions.loadProductsClub, (state) => ({...state, pending: true})),
  on(ClubsActions.saveProductsClub, (state, { page, products, totalPages }) => {
    return {
      ...state,
      products: { ...state.products, [page]: products },
      totalPages,
      currentPage: page,
      pending: false
    }
  })
);

export function reducer(state: State | undefined, action: ClubsActions.ClubsActionsUnion) {
  return cubsReducer(state, action);
}

export const getCurrentPage = (state: State) => state.currentPage;

export const getTotalPages = (state: State) => state.totalPages;

export const getClubsProducts = (state: State) => state.products;

export const getPending = (state: State) => state?.pending;



