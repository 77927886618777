import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ProductsModule } from '../products/products.module';
import { WineryEffects } from './effects/winery.effects';
import * as fromWinery from './reducers';

@NgModule({
  imports: [
    ProductsModule,
    StoreModule.forFeature(fromWinery.wineryKey, fromWinery.reducer),
    EffectsModule.forFeature([WineryEffects])
  ]
})
export class WineriesModule { }
