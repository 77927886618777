
import { NgModule } from '@angular/core';
import { IsBrandDirective } from './directives/is-brand.directive';
import { IsBusinessTypeDirective } from './directives/is-business-type.directive';

@NgModule({
  declarations: [
    IsBusinessTypeDirective,
    IsBrandDirective
  ],
  exports: [
    IsBusinessTypeDirective,
    IsBrandDirective
  ]
})
export class UtilsModule { }
