import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { CartActions, CartItem } from '@tgw-clients/shared';

@Component({
  selector: 'tgw-remove-confirm-dialog',
  template: ``,
  styleUrls: ['./remove-confirm-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RemoveConfirmDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public item: CartItem, private store: Store) {
    if (item) this.store.dispatch(CartActions.removeProductFromCart({ item }));
  }

}
