import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { LayoutEffects } from './effects/layout.effects';
import * as fromLayout from './reducers';

@NgModule({
  imports: [
    StoreModule.forFeature(fromLayout.layoutFeatureKey, fromLayout.reducers),
    EffectsModule.forFeature([LayoutEffects])
  ]
})
export class LayoutModule { }
