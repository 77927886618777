import { createAction, union, props } from '@ngrx/store';

export const notifyUser = createAction(
  '[Error Handler] Notify user',
  props<{ message: string, title?: string, type?: string }>()
);

const all = union({
  notifyUser
});

export type ErrorHandlerActionsUnion = typeof all;
