import { createReducer, on } from '@ngrx/store';
import { CartItem } from '../../cart';
import { PacksActions } from '../actions';
import { Product } from '../models';

export const packsKey = 'packs';

export interface State {
  packs: { [page: number]: Product[] };
  currentPage: number;
  totalPages: number;
  productsPerPack: { [packId: number]: CartItem[] };
}


const initialState: State = {
  packs: {},
  currentPage: 1,
  totalPages: 1,
  productsPerPack: {}
}

const packsReducer = createReducer(
  initialState,
  on(PacksActions.savePacks, (state, { page, packs, totalPages }) => ({
    ...state,
    packs: { ...state.packs, [page]: packs },
    totalPages,
    currentPage: page
  })),
  on(PacksActions.savePackProducts, (state, { packId, productsInPack }) => ({
    ...state,
    productsPerPack: { ...state.productsPerPack, [packId]: productsInPack }
  }))
);

export function reducer(state: State | undefined, action: PacksActions.ActionsUnion) {
  return packsReducer(state, action);
}

export const getCurrentPage = (state: State) => state.currentPage;

export const getTotalPages = (state: State) => state.totalPages;

export const getPacks = (state: State) => state.packs;

export const getProductsPerPack = (state: State) => state.productsPerPack;



