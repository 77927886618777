import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { CartActions, ErrorHandlerActions, ErrorHandlerService, fromAuth, fromCart } from '@tgw-clients/shared';
import { filter, map, mapTo, switchMap, withLatestFrom } from 'rxjs/operators';
import { ProductAddedDialogComponent } from '../components/product-added-dialog.component';
import { RemoveConfirmDialogComponent } from '../components/remove-confirm-dialog.component';


@Injectable()
export class CartWebEffects {

  addToCart$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CartActions.addToCart),
      withLatestFrom(
        this.store.pipe(select(fromAuth.getShippingAddressesStates)),
        this.store.pipe(select(fromAuth.getShippingAddressesPostcodes)),
        this.store.pipe(select(fromCart.getCart))
      ),
      switchMap(([{ product, date }, addressesStates, postcodes, cart]) => {
        const productIdx = cart.findIndex(({ product: { id }, date: itemDate }) => id === product.id && (!date || date === itemDate));
        const showMessageProvinces = !!Object.keys(product.selling_denied_states || {}).some(id => addressesStates.includes(product.selling_denied_states[id].slug.toUpperCase())) || false;
        const showMessagePostalCode = product.selling_denied_postcodes.some(postcode => postcodes.includes(postcode));
        let add = ' ';
        if (productIdx < 0) {
          add = ' no ';
          this._errorHandler.notify({ message: 'La suscripción de colecciones solo se puede tener una en el carrito. Si tienes otros productos en el carrito realiza la compra por separado.', title: 'No se puede añadir al carrito' });
        }
        let dialogRef = this.dialog.open(ProductAddedDialogComponent, { panelClass: 'custom-dialog-container', minHeight: "100px", maxHeight: "100px", maxWidth: "320px", minWidth: "300px", hasBackdrop: false, position: { top: "0", right: "0" }, data: { product, date, showMessageProvinces, showMessagePostalCode, add } });
        setTimeout(() => {
          dialogRef.close();
        }, 3000)
        return dialogRef.afterClosed();
      })
    ), { dispatch: false }
  );

  askRemoveConfirm$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CartActions.askRemoveConfirm),
      map(({ item }) => CartActions.removeProductFromCart({ item }))
    )
  );

  messageFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CartActions.addToCartByUrlFailure),
      map(({ message }: any) => ErrorHandlerActions.notifyUser({ message, title: 'kirolapp-common.errorOccurred' }))
    )
  );



  constructor(
    private actions$: Actions,
    private dialog: MatDialog,
    private store: Store,
    private _errorHandler: ErrorHandlerService
  ) { }

}


