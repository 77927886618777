import { createAction, props, union } from '@ngrx/store';
import { Winery } from '../models/winery.model';

export const loadWinery = createAction('[Winery] Load winery', props<{ wineryId: string; }>());
export const saveWinery = createAction('[Winery] Save winery', props<{ winery: Winery; }>());

export const loadWineries = createAction('[Winery] Load Wineries', props<{ newPage: number; perPage: number }>());
export const saveWineries = createAction('[Winery] Save Wineries', props<{ newPage: number; allWineries: Winery[]; totalWineries: number; totalPages: number; }>());

export const loadSingleWinery = createAction('[Winery] Load Single Winery', props<{ winerySlug: string }>());
export const saveSingleWinery = createAction('[Winery] Save Single Winery', props<{ winery: Winery, slug: string }>());

const all = union({
  loadWinery,
  saveWinery,
  loadWineries,
  saveWineries,
  loadSingleWinery,
  saveSingleWinery
});

export type WineryActionsUnion = typeof all;
