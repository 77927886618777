import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { EMPTY } from 'rxjs';
import { catchError, filter, map, switchMap, tap } from 'rxjs/operators';
import { ProductsService } from '../../products';
import { RouterActions } from '../../router';
import { WineryActions } from '../actions';
import { WineryService } from '../services/winery.service';
import * as fromWinery from './../reducers';

@Injectable()
export class WineryEffects {

  loadWinery$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WineryActions.loadWinery),
      filter(({ wineryId }) => wineryId != null),
      switchMap(({ wineryId }) =>
        this.store.pipe(
          select(fromWinery.getWineryById(wineryId)),
          filter((winery) => winery == null),
          switchMap(() =>
            this._winery.getWinery(wineryId).pipe(
              map((winery) => WineryActions.saveWinery({ winery })),
              catchError((error) => {
                console.error(error);
                return EMPTY;
              })
            )
          )
        )
      )
    )
  );

  loadWineries$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WineryActions.loadWineries),
      switchMap(({ newPage, perPage }) => {
        return this._winery.getWineries(newPage, perPage)
          .pipe(
            map(data => {
              const wineries: any = data?.products.sort((a, b) => {
                if (a?.title?.rendered > b?.title?.rendered) { //comparación lexicogŕafica
                  return 1;
                } else if (a?.title?.rendered < b?.title?.rendered) {
                  return -1;
                }
                return 0;
              })
              return {
                wineries,
                totalWineries: data.totalWineries,
                totalPages: data.totalPages
              }
            }),
            map(response => WineryActions.saveWineries({ newPage: newPage, allWineries: response.wineries, totalWineries: response.totalWineries, totalPages: response.totalPages })),
            catchError(() => [WineryActions.saveWineries({ newPage: 0, allWineries: [], totalWineries: 0, totalPages: 0 })])
          );
      })
    )
  );

  loadSingleWinery$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WineryActions.loadSingleWinery),
      switchMap(({ winerySlug }) =>
        this._winery.getWineryBySlug(winerySlug).pipe(
          switchMap((winery) =>
            this._product.getProductsByWinery(winery.id.toString()).pipe(
              map((products) => WineryActions.saveSingleWinery({ winery: { ...winery, products }, slug: winerySlug })),
              catchError(() => [WineryActions.saveSingleWinery({ winery, slug: winerySlug })])
            )
          ),
          catchError(() => [RouterActions.navigateTo({ path: '404' })])
        )
      )
    )
  );
  constructor(
    private actions$: Actions,
    private _winery: WineryService,
    private _product: ProductsService,
    private store: Store
  ) { }
}
