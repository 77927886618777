import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromRemoteStatus from './remote-status.reducer';

export const remoteStatusKey = 'remoteStatus';

export interface State {
  [remoteStatusKey]: fromRemoteStatus.State
};

export const reducer = fromRemoteStatus.reducer;

export const getRemoteStatusState = createFeatureSelector<State, fromRemoteStatus.State>(remoteStatusKey);

export const isUnderMaintenance = createSelector(
  getRemoteStatusState,
  fromRemoteStatus.isUnderMaintenance
);
