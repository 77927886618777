import { createAction, props, union } from '@ngrx/store';
import { Carousel, Footer, MenuItem } from '@tgw-clients/shared';

export const loadLayout = createAction('[Layout] Load layout');
export const loadLayoutSuccess = createAction('[Layout] Load layout success', props<{ header: MenuItem[]; footer: Footer }>());

export const loadMenu = createAction('[Layout] Load menu', props<{ name: string }>());
export const loadMenuSuccess = createAction('[Layout] Load menu success', props<{ name: string, menu: MenuItem[] }>());

export const subscribeNewsletter = createAction('[Layout] Subscribe to newsletter', props<{ email: string, selection: any[], status?: string }>());
export const subscribeNewsletterSuccess = createAction('[Layout] Subscribe to newsletter success');
export const subscribeNewsletterFailure = createAction('[Layout] Subscribe to newsletter failure', props<{ error: string }>());
export const notifySubscribeB2cSuccess = createAction('[Layout] Subscribe to SubscribeB2c success');
export const notifySubscribeB2cError = createAction('[Layout] Subscribe to SubscribeB2c failure', props<{ error: string }>());

export const loadCarousel = createAction('[Layout] Load carousel', props<{ name: string }>());
export const saveCarousel = createAction('[Layout] Save carousel', props<{ name: string; carousel: Carousel }>());

export const openRedirectDialog = createAction('[Layout] Open redirect dialog', props<{ path: string; }>());

export const subscribeB2c= createAction('[Layout] Subscribe to subscribeB2c', props<{ email: string, nameRest: string,province: string,name: string,telefon: string }>());



const all = union({
  loadLayoutSuccess,
  loadMenu,
  loadMenuSuccess,
  subscribeNewsletter,
  subscribeNewsletterSuccess,
  subscribeNewsletterFailure,
  loadCarousel,
  saveCarousel,
  openRedirectDialog,
  subscribeB2c
});

export type LayoutActionsUnion = typeof all;
