import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Product } from '@tgw-clients/shared';

@Component({
  selector: 'tgw-product-not-added-dialog',
  template: `
  <span mat-dialog-close class="icon-close" inlineSVG="assets/icons/close.svg"></span>
  <p class="dialog-title">{{'tgw-product-not-added-dialog' | translate}}</p>
  <div class="producto-add-wrapper">
    <div class="img-wrapper">
      <img [src]="image"/>
    </div>
    <p [innerHTML]="name"></p>
  </div>
  <button mat-dialog-close>{{'tgw-commons-accept' | translate}}</button>
`,
  styleUrls: ['./product-not-added-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductNotAddedDialogComponent {

  image: string;
  name: string;
  showMessageProvinces: boolean;

  constructor(@Inject(MAT_DIALOG_DATA) data: { product: Product;}) {
    this.image = data.product.images[0]?.src;
    this.name = data.product.name;
  }


}
