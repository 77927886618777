import { createReducer, on } from '@ngrx/store';
// import { userInfo } from 'os';
import { AuthActions } from '../actions';
import { User } from '../models/auth.model';


interface Status {
  pending: boolean;
  error?: string;
}

export interface State {
  loginStatus?: Status;
  restorePasswordStatus?: Status;
  newPasswordStatus?: Status;
  registerStatus?: Status;
  shippingAddressStatus?: Status;
  billingAddressStatus?: Status;
  accountInfoStatus?: Status;
  ordersStatus?: Status;
  warehouseStatus?: Status;
  pending?: boolean;
  user?: User;
}

const initialState: State = {
  user: null

};

const authReducer = createReducer(
  initialState,
  /* Login */
  on(AuthActions.login, AuthActions.autologin, state => ({ ...state, loginStatus: { pending: true } })),
  on(AuthActions.loginFailure, AuthActions.autologinFailure, (state, { error }) => ({ ...state, loginStatus: { pending: false, error } })),
  on(AuthActions.loginSuccess, AuthActions.autologinSuccess, (state, { user }) => ({ ...state, loginStatus: { pending: false }, user })),
  on(AuthActions.logout, AuthActions.forceLogout, state => ({ ...state, user: null })),
  on(AuthActions.resetLogin, state => ({ ...state, loginStatus: { pending: false } })),
  /* Restore password */
  on(AuthActions.restorePassword, state => ({ ...state, restorePasswordStatus: { pending: true } })),
  on(AuthActions.restorePasswordFailure, (state, { error }) => ({ ...state, restorePasswordStatus: { pending: false, error } })),
  on(AuthActions.restorePasswordSuccess, state => ({ ...state, restorePasswordStatus: { pending: false } })),
  /* Set new password */
  on(AuthActions.setNewPassword, state => ({ ...state, newPasswordStatus: { pending: true } })),
  on(AuthActions.setNewPasswordFailure, (state, { error }) => ({ ...state, newPasswordStatus: { pending: false, error } })),
  on(AuthActions.setNewPasswordSuccess, state => ({ ...state, newPasswordStatus: { pending: false } })),
  /* Register */
  on(AuthActions.register, AuthActions.registerShort, AuthActions.directRegister, state => ({ ...state, registerStatus: { pending: true } })),
  on(AuthActions.registerFailure, AuthActions.registerShortFailure, AuthActions.directRegisterFailure, (state, { error }) => ({ ...state, registerStatus: { pending: false, error } })),
  on(AuthActions.registerSuccess, AuthActions.registerShortSuccess, AuthActions.directRegisterSuccess, state => ({ ...state, registerStatus: { pending: false } })),
  /* Shipping address */
  on(AuthActions.deleteShippingAddress, state => ({ ...state, shippingAddressStatus: { pending: true } })),
  on(AuthActions.updateShippingAddress, state => ({ ...state, shippingAddressStatus: { pending: true } })),
  on(AuthActions.updateShippingAddressFailure, (state, { error }) => ({ ...state, shippingAddressStatus: { pending: false, error } })),
  on(AuthActions.updateShippingAddressSuccess, (state, { shippingAddresses }) => ({ ...state, shippingAddressStatus: { pending: false }, user: { ...state?.user, shippingAddresses } })),
  /* Billing address */
  on(AuthActions.updateBillingAddress, state => ({ ...state, billingAddressStatus: { pending: true } })),
  on(AuthActions.updateBillingAddressFailure, (state, { error }) => ({ ...state, billingAddressStatus: { pending: false, error } })),
  on(AuthActions.updateBillingAddressSuccess, (state, { billingAddress: billingInfo, id_document_number }) => ({ ...state, billingAddressStatus: { pending: false }, user: { ...state?.user, billingAddress: billingInfo, id_document_number } })),
  /* Account */
  on(AuthActions.updateAccountInfo, state => ({ ...state, accountInfoStatus: { pending: true } })),
  on(AuthActions.updateAccountInfoFailure, (state, { error }) => ({ ...state, accountInfoStatus: { pending: false, error } })),
  on(AuthActions.updateAccountInfoSuccess, (state, { user }) => ({ ...state, accountInfoStatus: { pending: false }, user: { ...state?.user, ...user } })),
  /* Orders */
  on(AuthActions.loadUserOrders, (state) => ({ ...state, ordersStatus: { pending: true } })),
  on(AuthActions.loadUserOrdersSuccess, (state, { orders }) => ({ ...state, ordersStatus: { pending: false }, user: { ...state?.user, orders } })),
  on(AuthActions.loadUserOrdersFailure, (state, { error }) => ({ ...state, ordersStatus: { pending: false, error }, user: { ...state?.user, orders: [] } })),
  /* Warehouse */
  on(AuthActions.initializeWarehouse, (state) => ({
    ...state,
    user: {
      ...state.user,
      warehouses: state.user.shippingAddresses.reduce((acc, { id }) => ({ ...acc, [`${id}`]: null }), {})
    }
  })),
  on(AuthActions.loadWarehouseProducts, AuthActions.deleteWarehouseProduct, (state, { addressId }) => ({
    ...state,
    user: {
      ...state.user,
      warehouses: {
        ...state.user.warehouses,
        [addressId]: null
      }
    },
    warehouseStatus: { pending: true, error: null }
  })),
  on(AuthActions.loadWarehouseProductsFailure, (state, { error, addressId }) => ({
    ...state,
    user: {
      ...state.user,
      warehouses: {
        ...state.user.warehouses,
        [addressId]: []
      }
    },
    warehouseStatus: { pending: false, error }
  })),
  on(AuthActions.saveWarehouseProducts, (state, { addressId, products }) => ({
    ...state,
    user: {
      ...state?.user,
      warehouses: { ...state?.user?.warehouses, [addressId]: products }
    },
    warehouseStatus: { pending: false, error: null }
  })),
  on(AuthActions.saveMethodsPerAddress, (state, { methodsPerAddress }) => ({ ...state, user: { ...state.user, methodsPerAddress } })),
);



export function reducer(state: State | undefined, action: AuthActions.AuthActionsUnion) {
  return authReducer(state, action);
}

export const isLoginPending = (state: State) => !!state?.loginStatus?.pending;

export const getUser = (state: State) => state?.user;

export const getUserId = (state: State) => state?.user?.id;

export const getUsername = (state: State) => state?.user?.first_name;

export const getUserIdDocumentNumber = (state: State) => state?.user?.id_document_number;

export const getUserRappel = (state: State) => state?.user?.rappel;

export const getRestorePasswordPending = (state: State) => state?.restorePasswordStatus?.pending;

export const getNewPasswordPending = (state: State) => state?.newPasswordStatus?.pending;

export const getRegisterPending = (state: State) => state?.registerStatus?.pending;

export const getShippingAddressStatus = (state: State) => state?.shippingAddressStatus?.pending;

export const getBillingAddressStatus = (state: State) => state?.billingAddressStatus?.pending;

export const getUserBilling = (state: State) => state?.user?.billingAddress;

export const getShippingAddresses = (state: State) => state?.user?.shippingAddresses || [];

export const getShippingAddress = (id: string) => (state: State) => state?.user?.shippingAddresses?.find(({ id: shippingId }) => id === shippingId.toString()) || <any>{};

export const getUserRole = (state: State) => state?.user?.role;

export const getUserOrders = (state: State) => state?.user?.orders;

export const getUserOrdersPending = (state: State) => state?.ordersStatus?.pending;

export const isWarehousePending = (state: State) => state?.warehouseStatus?.pending;

export const getUserOrdersError = (state: State) => state?.ordersStatus?.error;

export const getAccountInfoPending = (state: State) => state?.accountInfoStatus?.pending;

export const getWarehouses = (state: State) => state?.user?.warehouses;

export const getPending = (state: State) => state?.pending;

export const hasWarehouseError = (state: State) => state?.warehouseStatus?.error;

export const getMethodsPerAddress = (state: State) => state?.user?.methodsPerAddress;

export const getRegisterError = (state: State) => state?.registerStatus?.error;
