import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { ErrorHandlerService } from '../../error-handler';
import { Observable } from 'rxjs';
import { first, map, switchMap, take, tap } from 'rxjs/operators';
import * as fromAuth from './../reducers';
@Injectable({ providedIn: 'root' })
export class GuestGuard implements CanActivate {
  constructor(private store: Store, private router: Router, private _errorHandler: ErrorHandlerService,) { }

  canActivate(): Observable<boolean | any> {
    return this.store.pipe(
      select(fromAuth.isLoginPending),
      first(pending => !pending),
      switchMap(() => this.store.pipe(select(fromAuth.isLoggedIn))),
      map((isLoggedIn) => !isLoggedIn || this._errorHandler.notify({ message: 'tgw-login-session-registered-message', title: 'tgw-login-session-registered-title' })),
      take(1)
    );
  }

}
