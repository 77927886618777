import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { OffersActions } from '../actions';
import { ProductsService } from '../services/products.service';

@Injectable()
export class OffersEffects {

  loadOfferProducts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OffersActions.loadOfferProducts),
      switchMap(({ page }) =>
        this._products.getOfferProducts(page).pipe(
          map((offerProductPage) => OffersActions.saveOfferProducts(offerProductPage)),
          catchError(() => [OffersActions.saveOfferProducts({ page: 1, products: [], totalPages: 1, found: 0, maxPrice:0, minPrice:0 })])
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private _products: ProductsService
  ) { }
}
