import { Inject, Injectable } from '@angular/core';
import { Category, Environment, ENVIRONMENT, Form, Logo, Page, ProductAttributes, Tag } from '../models/token';

export enum BusinessType {
  B2B = 'b2b',
  B2C = 'b2c',
  Winery = 'winery',
  Unknown = 'unknown'
}

export enum Brands {
  TGW = 'tgw',
  RAO = 'rao',
  Unknown = 'unknown'
}

export interface CoreConfig {
  defaultLang: string;
  langs: string[];
  defaultBrand?: 'tgw' | 'rao';
  brands?: string[];
  businessType: BusinessType;
  brand: Brands;
  products?: {
    perPage: number
    perPageWinery: number
    perPageMagazine: number
  }
  
}

export enum EndpointType {
  Custom = 'tgw/v1/',
  Woocommerce = 'wc/v3/',
  Wordpress = 'wp/v2/',
  Auth = 'api-bearer-auth/v1/',
  Contact = 'contact-form-7/v1/',
  paypal = '/v1/oauth2/token'
}


@Injectable({
  providedIn: 'root'
})
export class CoreConfigService {
  protected _config: CoreConfig;

  constructor(@Inject(ENVIRONMENT) private _env: Environment) { }

  importConfig(coreConfigRaw: any): void {
    this._config = {
      defaultLang: coreConfigRaw.DefaultLang,
      langs: coreConfigRaw.Languages,
      businessType: coreConfigRaw.BusinessType || BusinessType.Unknown,
      brand: coreConfigRaw.Brand || Brands.Unknown,
      products: {
        perPage: coreConfigRaw.ProductsConfig.perPage,
        perPageWinery: coreConfigRaw.ProductsConfig.perPageWinery,
        perPageMagazine: coreConfigRaw.ProductsConfig.perPageMagazine,
        perPageExperience: coreConfigRaw.ProductsConfig.perPageExperience
      }
    } as CoreConfig;
  }

  importAppConfig(coreConfigRaw: any): void {
    this._config = {
      defaultLang: coreConfigRaw.DefaultLang,
      langs: coreConfigRaw.Languages,
      businessType: coreConfigRaw.BusinessType || BusinessType.Unknown,
      defaultBrand: coreConfigRaw.DefaultBrand,
      brands: coreConfigRaw.Brands,
      products: {
        perPage: coreConfigRaw.ProductsConfig.perPage
      }

    } as CoreConfig;
  }

  get businessType(): BusinessType {
    if (!this._config) {
      throw Error('Condition race. <this.config> is not ready!');
    }
    return this._config.businessType;
  }

  get brand(): Brands {
    if (!this._config) {
      throw Error('Condition race. <this.config> is not ready!');
    }
    return this._config.brand;
  }

  getEnv(): Environment {
    return this._env;
  }

  getPaypalClientId(): string {
    return this._env.paypalClientId;
  }

  getPaypalClientIdPass(): string {
    return this._env.paypalClientIdPass;
  }

  getPaypalLink(): string {
    return this._env.paypalLink;
  }

  getTag(tag: Tag): string {
    return this._env.tags[tag].toString();
  }

  getCategory(category: Category): string {
    return this._env.categories?.[category].toString();
  }

  getAttributes(): ProductAttributes {
    return this._env.attributes;
  }

  getForm(form: Form): string {
    return this._env.forms[form].toString();
  }

  getLogo(type: Logo): string {
    return this._env.logos[type];
  }

  getPage(page: Page): number {
    return this._env.pages[page];
  }

  getLangs(): string[] {
    return this._config.langs;
  }

  getBrands(): string[] {
    return this._config.brands;
  }

  getProductsConfig(): any {
    return this._config.products;
  }

  getEndpoint(type: EndpointType): string {
    return `${this._env.apiBaseEndpoint}${type}`;
  }

}
