import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { AuthService } from '../../auth/services';
import { CoreConfigService, EndpointType } from '../../utils';
import { CouponAPI } from '../models/coupons.model';

@Injectable({
  providedIn: 'root'
})
export class CouponsService {

  private couponURL = `${this._coreConfig.getEndpoint(EndpointType.Woocommerce)}coupons/`;

  constructor(private _coreConfig: CoreConfigService, private http: HttpClient, private _auth: AuthService) { }

  getFeaturedCoupons(): Observable<CouponAPI> {
    return this.http.get<CouponAPI[]>(this.couponURL).pipe(
      map((coupons) => {
        return coupons[0];
      })
    );
  }

  getCoupon(coupon: string): Observable<CouponAPI> {
    const params = new HttpParams().append('code', coupon);
    let headers = new HttpHeaders();
    if (this._auth.hasToken()) {
      headers = this._auth.getHeaders();
    }

    return this.http.get<CouponAPI>(`${this._coreConfig.getEndpoint(EndpointType.Custom)}coupon`, { params, headers }).pipe(
      catchError(() => throwError('tgw-coupon-non-existent')),
      switchMap((coupon) => {
        // Comprobar caducidad
        const resDate = new Date(coupon.date_expires?.date) //fecha que viene
        const now = new Date() //fecha de hoy
        if (resDate < now) {
          return throwError('tgw-coupon-expired'); // Caducado
        }

        // Comprobar nº de usos total
        const usageCount = coupon.usage_count;
        const usageLimit = coupon.usage_limit;
        if (usageLimit && usageCount === usageLimit) { // Límite de usos
          return throwError('tgw-coupon-non-existent');
        }

        // Comprobar nº de usos por usuario
        const usageLimitPerUser = coupon.usage_limit_per_user;
        const used_by = coupon.used_by?.length || 0;
        if (usageLimitPerUser && usageLimitPerUser === used_by) {
          return throwError('tgw-coupon-use-limit'); // Límite de usos por usuario
        }

        return [coupon];
      })
    );
  }
}