import { createReducer, on } from '@ngrx/store';
import { ProductsActions } from '../actions';
import { Product, Term } from '../models';

export interface State {
  featuredProducts?: Product[];
  productPages?: { [page: number]: Product[] };
  pending?: boolean;
  error?: boolean;
  currentPage?: number;
  totalPages?: number;
  attributeCounts?: Term[];
  monthProducts?: Product[];
  found?: number;
  max_price: number;
  min_price: number;
  products_ids: number[]
}


const initialState: State = {
  featuredProducts: [],
  productPages: {},
  // productsClub: {},
  pending: false,
  currentPage: 1,
  totalPages: 1,
  found:0,
  max_price:0,
  min_price:0,
  products_ids: []
}

const productsReducer = createReducer(
  initialState,
  on(ProductsActions.loadProductsPage, (state,) => ({ ...state, pending: true, error: false })),
  on(ProductsActions.saveProductsPage, (state, { products, totalPages, page, attributeCounts, found,max_price,min_price, products_ids, error }) => ({
    ...state,
    productPages: { ...state.productPages, [page]: products },
    totalPages,
    currentPage: page,
    attributeCounts,
    pending: false,
    found,
    max_price,
    min_price,
    products_ids,
    error
  })),
  on(ProductsActions.updateCurrentPage, (state, { page }) => ({ ...state, currentPage: page })),
  on(ProductsActions.clearProductPages, (state) => ({ ...state, productPages: {}, featuredProducts: [], currentPage: 1, totalPages: 1 })),
  on(ProductsActions.saveFeaturedProducts, (state, { products }) => ({ ...state, featuredProducts: products })),
  on(ProductsActions.saveMenuItems, (state, { item }) => ({ ...state, menuItems: item })),
  on(ProductsActions.saveMonthProducts, (state, { products }) => ({ ...state, monthProducts: products })),
  on(ProductsActions.clearProductPages, (state) => ({ ...state, productPages: {}, featuredProducts: [], currentPage: 1, totalPages: 1 })),
  on(ProductsActions.saveFeaturedProducts, (state, { products }) => ({ ...state, featuredProducts: products })),
);

export function reducer(state: State | undefined, action: ProductsActions.ProductsActionsUnion) {
  return productsReducer(state, action);
}

export const getProductPages = (state: State) => state?.productPages;

export const getProductPending = (state: State) => state?.pending;

export const hasError = (state: State) => state.error;

export const getCurrentPage = (state: State) => state?.currentPage;

export const getTotalPages = (state: State) => state?.totalPages;

export const getTotalfound = (state: State) => state?.found;

export const getMaxPrice = (state: State) => state?.max_price;

export const getMinPrice= (state: State) => state?.min_price;

export const getProductsIds= (state: State) => state?.products_ids;

export const getFeaturedProducts = (state: State) => state?.featuredProducts;

// export const getProductClub = (state: State) => state?.productsClub;

// export const getProductClubPending = (state: State) => state?.pending;
export const getMonthProducts = (state: State) => state?.monthProducts;

export const getAttributeCounts = (state: State) => state?.attributeCounts;



