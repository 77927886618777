import { Component, Input, Output, EventEmitter } from '@angular/core';
import { MenuItem } from '@tgw-clients/shared';

@Component({
  selector: 'tgw-header-items-submenu',
  template: `
  <ng-container>
    <p class="sub-submenu-parent" [routerLink]="subItem.tgw_slug" >{{subItem.title}}
    <span style="font-weight:bold" (click)="hideMenu()" [ngClass]="hideSubMenu ? 'desplegado' : 'sin-desplegar'">
      <svg id="Capa_1" data-name="Capa 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13.38 7.4"><title>arrow</title><polyline points="0.35 0.35 6.69 6.69 13.03 0.35" style="fill:none;stroke:#1d1e1c;stroke-miterlimit:10"/></svg>
    </span><p>
    <tgw-header-submenu *ngIf="hideSubMenu" (click)="hideSubMenuOnCheck.next()" [show]="show" [menuId]="subItem?.tgw_id" [slug]="subItem?.title" ></tgw-header-submenu>
   </ng-container>
  `,
  styleUrls: ['./header-items-submenu.component.scss']
})
export class HeaderItemsSubmenuComponent {
  @Input() subItem: MenuItem;
  @Input() show: boolean;
  @Output() hideSubMenuOnCheck = new EventEmitter();
  hideSubMenu = false;

  constructor() { }

  hideMenu(): void {
    this.hideSubMenu = this.hideSubMenu ? false : true;
  }


}
