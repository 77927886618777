
import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { tap } from "rxjs/operators";
import { ErrorHandlerActions } from '../actions';
import { ErrorHandlerService } from '../services';


@Injectable()
export class ErrorHandlerEffects {

  notify$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ErrorHandlerActions.notifyUser),
      tap(({ message, title }) => this.notifier.notify({ message, title })),
    ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private notifier: ErrorHandlerService,
  ) { }
}
