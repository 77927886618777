import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { ExperiencesActions } from '../actions';
import { ProductsService } from '../services/products.service';



@Injectable()
export class ExperiencesEffects {

  loadExperiences$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ExperiencesActions.loadExperiences),
      switchMap(({ page, id, postId }) =>
        this.product.getAllExperiences(page, id, postId).pipe(
          map(({ experiences, page, totalPages}) => ExperiencesActions.saveExperiences({ experiences, page, totalPages,id,postId })),
          catchError(() => [ExperiencesActions.loadExperiencesFailure()])
        )
      )
    )
  );

  loadExperiencesearch$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ExperiencesActions.loadExperiencesSearch),
      switchMap(({ search }) =>
        this.product.getExperienceSearch(search).pipe(
          map(({ experiences,search}) => ExperiencesActions.saveExperiencesSearch({ experiences,search})),
          catchError(() => [ExperiencesActions.loadExperiencesFailure()])
        )
      )
    )
  );

  loadExperiencesCategories$ = createEffect(() =>
  this.actions$.pipe(
    ofType(ExperiencesActions.loadExperiencesCategories),
    switchMap(({}) =>
      this.product.getExperienceCategories().pipe(
        map((categories) => ExperiencesActions.saveExperiencesCategories({ categories})),
        catchError(() => [ExperiencesActions.loadExperiencesFailure()])
      )
    )
  )
);



  constructor(
    private actions$: Actions,
    private product: ProductsService,
  ) { }
}
