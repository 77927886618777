import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({ name: 'localize' })
export class LocalizePipe implements PipeTransform {
  constructor(private translate: TranslateService) { }

  transform(route: string, lang: string = this.translate.currentLang): string {
    const currentLang = lang;
    if (currentLang !== this.translate.defaultLang) {
      return `/${currentLang}/${route}`;
    }
    return `/${route}`;
  }
}