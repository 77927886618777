import { createAction, props, union } from '@ngrx/store';
import { CouponAPI } from '../models/coupons.model';

export const loadFeaturedCoupon = createAction('[Coupons] Load featured coupon');
export const loadFeaturedCouponSuccess = createAction('[Coupons] Load featured coupon success', props<{ coupon: CouponAPI }>());
export const loadFeaturedCouponFailure = createAction('[Coupons] Load featured coupon failure', props<{ error: string; }>());

const all = union({
  loadFeaturedCoupon,
  loadFeaturedCouponSuccess,
  loadFeaturedCouponFailure
});

export type CouponsActionsUnion = typeof all;
