import { NgcCookieConsentConfig } from "ngx-cookieconsent";

export const cookieConfig: NgcCookieConsentConfig = {
  cookie: {
    domain: window.location.hostname
  },
  palette: {
    popup: {
      background: '#ffffff'
    },
    button: {
      background: '#b68160',
      text: '#ffffff',

    },
    highlight: {
      background: '#b68160',
      text: '#ffffff',
    }

  },
  theme: 'edgeless',
  type: 'opt-out',
  layout: 'my-custom-layout',
  layouts: {
    "my-custom-layout": '{{messagelink}}'
  },
  autoOpen: false,
  elements: {
    messagelink: `
    <div role='dialog' style='min-height:200px; display: flex; flex-direction: column; justify-content: center; align-items: center;' aria-live='polite' aria-label='cookieconsent'aria-describedby='cookieconsent:desc' class='cookies-message cc-window cc-banner cc-type-opt-out cc-theme-edgeless cc-bottom cc-color-override--1393189295'>
      <div>
        <div class="btn-close">
          <input class="cc-btn cc-deny" type='button' value='X'/>
        </div>
        <span id="cookieconsent:desc" class="cc-message">{{message}}
          <a aria-label="learn more about cookies" tabindex="0" class="cc-link" style='font-weight: bold; text-decoration: none;' href="{{cookiePolicyHref}}" target="_blank">{{cookiePolicyLink}}</a>.
          {{secondMessage}}
          <a aria-label="learn more about our privacy policy" tabindex="1" class="cc-link" style='font-weight: bold; text-decoration: none;' href="{{privacyPolicyHref}}" target="_blank">{{privacyPolicyLink}}</a>.
        </span>
        <div class='cc-compliance cc-highlight'>
          <a aria-label="allow cookies" role='button' tabindex="0" class="cc-btn cc-allow btn btn-primary">{{allow}}</a>
          <a aria-label="deny cookies" role='button' tabindex="0" class="cc-btn cc-deny btn btn-primary" >{{deny}}</a>
        </div>
      </div>
    </div>
    `
  },
  content: {
    message: 'Le informamos que en The Grand Wines S.L.U. Usamos cookies propias y de terceros con la finalidad analítica y publicidaria. Consulte la',
    secondMessage: 'Puede informarse más sobre qué cookies estamos utilizando o desactivarlas haciendo clic en',
    cookiePolicyLink: 'Política de cookies',
    cookiePolicyHref: '',
    privacyPolicyLink: 'Configurar las cookies',
    privacyPolicyHref: '',
    allow: 'ACEPTO TODAS',
    deny: 'RECHAZO TODAS'
  }
};
