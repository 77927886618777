import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { BusinessType, CoreConfigService } from '../services/core-config.service';

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '[isBusinessType]'
})
export class IsBusinessTypeDirective {
  private types = [];

  private businessType: BusinessType;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    coreConfig: CoreConfigService) {
    this.businessType = coreConfig.businessType;
  }

  @Input()
  set isBusinessType(val: any) {
    if (!Array.isArray(val)) {
      this.types = [val];
    } else {
      this.types = val;
    }
    this.updateView();
  }

  private updateView() {
    if (this.checkPermission()) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }

  private checkPermission() {
    let isBusinessType = false;
    let allNegative = true;

    if (this.businessType) {
      isBusinessType =
        this.types.filter(app => {
          const t = app === this.businessType || (app.indexOf('!') === 0 && app.substr(1) !== this.businessType);
          if (t && allNegative && (app.indexOf('!') === -1)) {
            allNegative = false;
          }
          return t;
        }).length > (allNegative ? this.types.length - 1 : 0);
    }
    return isBusinessType;
  }
}
