import { createReducer, on } from '@ngrx/store';
import { Product } from '../../products';
import { WineryActions } from '../actions';
import { Winery } from '../models/winery.model';

export interface State {
  wineryPages?: { [page: number]: Winery[] };
  totalWineries: number;
  totalPages: number;
  wineries?: { [id: number]: Winery };
  products?: Product[];
}

const initialState: State = {
  totalPages: 1,
  totalWineries: 0
};

const wineryReducer = createReducer(
  initialState,
  on(WineryActions.saveWinery, (state, { winery }) => ({ ...state, wineries: { ...state.wineries, [winery.id]: winery } })),
  on(WineryActions.saveWineries, (state, { newPage, allWineries, totalWineries, totalPages }) => {
    return {
      ...state,
      wineryPages: { ...state.wineryPages, [newPage]: allWineries },
      totalWineries,
      totalPages
    }
  }),
  on(WineryActions.saveSingleWinery, (state, { winery, slug }) => {
    return {
      ...state,
      wineries: { ...state.wineries, [slug]: winery }
    }
  })
);

export function reducer(state: State | undefined, action: WineryActions.WineryActionsUnion) {
  return wineryReducer(state, action);
}


export const getWineryPages = (state: State) => state?.wineryPages;

export const getTotalPages = (state: State) => state?.totalPages;

export const getTotalWineries = (state: State) => state?.totalWineries;

export const getWineries = (state: State) => state?.wineries;

export const getProducts = (state: State) => state?.products;
