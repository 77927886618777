import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { TranslateModule } from '@ngx-translate/core';
import { CartModule } from '@tgw-clients/shared';
import { SharedModule } from '../shared';
import { CartProfesionalesComponent } from './components/cart-profesionales.component';
import { CartRedirectDialogComponent } from './components/cart-redirect-dialog.component';
import { CartComponent } from './components/cart.component';
import { ProductAddedDialogComponent } from './components/product-added-dialog.component';
import { ProductNotAddedDialogComponent } from './components/product-not-added-dialog.component';
import { RemoveConfirmDialogComponent } from './components/remove-confirm-dialog.component';
import { UnavailableProductsDialogComponent } from './components/unavailable-products-dialog.component';
import { CartWebEffects } from './effects/cart-web.effects';



@NgModule({
  declarations: [CartComponent, CartProfesionalesComponent, RemoveConfirmDialogComponent, ProductAddedDialogComponent, ProductNotAddedDialogComponent, CartRedirectDialogComponent, UnavailableProductsDialogComponent],
  imports: [
    SharedModule,
    CartModule,
    RouterModule,
    TranslateModule.forChild(),
    EffectsModule.forFeature([CartWebEffects])
  ],
  exports: [
    CartComponent,
    CartProfesionalesComponent
  ]
})
export class CartWebModule { }
