import { Component, OnInit } from '@angular/core';
import { CoreConfigService, Logo } from '@tgw-clients/shared';

@Component({
  selector: 'rao-header',
  templateUrl: './rao-header.component.html',
  styleUrls: ['./rao-header.component.scss']
})
export class RaoHeaderComponent implements OnInit {
    logo = this._coreConfig.getLogo(Logo.Header);
    

  constructor(
    private _coreConfig: CoreConfigService,
  ) { }

  ngOnInit(): void {
  }

}
