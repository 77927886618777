import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromLayout from './layout.reducer';

export const layoutFeatureKey = 'layout';

export interface State {
  [layoutFeatureKey]: fromLayout.State;
};

export const reducers = fromLayout.reducer;

export const getLayoutState = createFeatureSelector<State, fromLayout.State>(layoutFeatureKey);


export const getFooter = createSelector(
  getLayoutState,
  fromLayout.getFooter
);

export const getHeader = createSelector(
  getLayoutState,
  fromLayout.getHeader
);


export const isLayoutLoaded = createSelector(
  getHeader,
  getFooter,
  (header, footer) => !!header && !!footer
);


export const getNewsletterPending = createSelector(
  getLayoutState,
  fromLayout.getNewsletterPending
);

export const getCarousels = createSelector(
  getLayoutState,
  fromLayout.getCarousels
);


export const getCarousel = (name: string) => createSelector(
  getCarousels,
  (carousels) => carousels[name]
);


export const isCarouselLoaded = (name: string) => createSelector(
  getCarousels,
  (carousels) => !!carousels[name]
);

export const getMenus = createSelector(
  getLayoutState,
  fromLayout.getMenus
);

export const getMenu = (name: string) => createSelector(
  getMenus,
  (menus) => menus?.[name]
);


