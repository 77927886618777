import { InjectionToken } from '@angular/core';


export enum Logo {
  Header = 'header',
  Footer = 'footer',
  Trust = 'trust',
  Prize = 'prize',
  White = 'white',
}

export interface Logos {
  [Logo.Header]: string;
  [Logo.Footer]: string;
  [Logo.White]: string;
  [Logo.Prize]: string;
  [Logo.Trust]: string;
}

export enum Tag {
  BestSellers = 'bestSellers'
}

export interface Tags {
  [Tag.BestSellers]: number;
}

export enum Category {
  Wine = 'wine',
  Pack = 'weeklyPack',
  Experience = 'experience',
  Collection = 'colecciones',
  Club = 'club'
}

export interface Categories {
  [Category.Wine]: number;
  [Category.Pack]?: number;
  [Category.Experience]?: number;
  [Category.Club]?: number;
  [Category.Collection]?: number;
}

export enum Form {
  Contact = 'contact'
}

export interface Forms {
  [Form.Contact]: number;
}

export enum ProductAttribute {
  Vintage = 'vintage',
  Specials = 'specials',
  OriginDenomination = 'originDenomination',
  TypeOfWine = 'typeOfWine',
  VarietyOfGrape = 'varietyOfGrape'
}

export interface ProductAttributes {
  [ProductAttribute.Vintage]: number;
  [ProductAttribute.Specials]: number;
  [ProductAttribute.OriginDenomination]: number;
  [ProductAttribute.TypeOfWine]: number;
  [ProductAttribute.VarietyOfGrape]: number;
}

export enum Page {
  News = 'news'
}

export interface Pages {
  [Page.News]: number;
}

export interface Environment {
  paypalClientId: string;
  paypalClientIdPass: string;
  paypalLink?: string;
  production: boolean;
  brand?: string;
  baseEnv?: 'stage' | 'pre' | 'prod' | 'dev';
  apiBaseEndpoint: string;
  literalEndpoint: string;
  imagesEndpoint?: string;
  tpvEndpoint?: string;
  logos: Logos;
  tags?: Tags;
  categories?: Categories;
  forms?: Forms;
  attributes?: ProductAttributes;
  pages?: Pages;
  access_token_facebook?: string;
  firebase_server_key?: string;
}

export const ENVIRONMENT = new InjectionToken<Environment>('environment');
