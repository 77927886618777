import { createReducer, on } from '@ngrx/store';
import { CollectionActions } from '../actions';
import { Collection, ProductCategory } from '../models/index';

export const collectionKey = 'collection';

export interface State {
  collectionPages?: {
    [id: number]: {
      [page: number]: Collection[],
      [post: string]: Collection[]
    }
  };
  currentPage?: number;
  totalPages?: number;
  pending?: boolean;
  error?: boolean;
  categories?: ProductCategory[];
  collectionPagesSearch?: { [search: string]: Collection[] };

}

const initialState: State = {};

const collectionReducer = createReducer(
  initialState,
  on(CollectionActions.loadCollection, (state) => ({ ...state, pending: true, error: false })),
  on(CollectionActions.loadCollectionFailure, (state) => ({ ...state, pending: false, error: true })),
  on(CollectionActions.saveCollection, (state, { collection, totalPages, page, id, postId }) =>
  ({
    ...state, collectionPages: {
      ...(state.collectionPages || {}),
      [id]: {
        ...(state.collectionPages?.[id] || { collection }),
        [page]: collection,
        [postId]: collection
      }
    },
    totalPages,
    currentPage: page,
    pending: false,
    error: false
  })),
  on(CollectionActions.loadCollectionCategories, (state) => ({ ...state })),
  on(CollectionActions.saveCollectionCategories, (state, { categories }) => ({ ...state, categories: categories })),
  on(CollectionActions.loadCollectionSearch, (state) => ({ ...state, pending: true, error: false })),
  on(CollectionActions.saveCollectionSearch, (state, { collection, search }) => ({ ...state, collectionPagesSearch: { ...(state.collectionPagesSearch || {}), [search]: collection }, pending: false, error: false })),
);

export function reducer(state: State | undefined, action: CollectionActions.CollectionActionsUnion) {
  return collectionReducer(state, action);
}

export const getPending = (state: State) => state?.pending;

export const getCollectionPending = (state: State) => state?.pending;

export const getCollection = (state: State) => state?.collectionPages;

export const getTotalPages = (state: State) => state?.totalPages;

export const getCurrentPage = (state: State) => state?.currentPage;

export const hasError = (state: State) => state?.error;

export const getCollectionSearch = (state: State) => state?.collectionPagesSearch;

export const getCollectionCategories = (state: State) => state?.categories;

