import { HttpClient, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { AutoRouterModule } from '@tgw-clients/shared/router/router.module';
import { appInitTranslations, CookiesModule, CoreConfigService, CustomI18nLoader, DynamicLocaleId, ENVIRONMENT } from '@tgw-clients/shared';
import { ErrorHandlerWebModule } from '@tgw-clients/shared-web';
import { environment } from '../environments/environment';
import * as appConfig from './../app-config';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent, CoreModule } from './core';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';


export function appInitializerFactory(translate: TranslateService, coreConfig: CoreConfigService): Function {
  coreConfig.importConfig(appConfig);
  return () => appInitTranslations(translate, appConfig.Languages, appConfig.DefaultLang);
};

export function localeIdFactory(translate: TranslateService): DynamicLocaleId {
  return new DynamicLocaleId(translate, appConfig.Languages);
}
@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HammerModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: CustomI18nLoader,
        deps: [HttpClient, ENVIRONMENT]
      }
    }),
    ErrorHandlerWebModule,
    AutoRouterModule,
    StoreModule.forRoot({}, { runtimeChecks: { strictActionImmutability: true, strictStateImmutability: true } }),
    EffectsModule.forRoot([]),
    CoreModule.forRoot(),
    CookiesModule,
    AppRoutingModule,
    StoreDevtoolsModule.instrument({maxAge:4})
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [TranslateService, CoreConfigService, LOCALE_ID],
      multi: true
    },
    {
      provide: LOCALE_ID,
      useFactory: localeIdFactory,
      deps: [TranslateService]
    },
    {
      provide: ENVIRONMENT,
      useValue: environment
    },
    {
      provide: 'googleTagManagerId',
      useValue: 'GTM-NQ6VK6V'
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
