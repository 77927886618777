import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { first, map, switchMap, take } from 'rxjs/operators';
import * as fromAuth from './../reducers';
@Injectable({ providedIn: 'root' })

export class AuthGuard implements CanActivate {
  constructor(protected store: Store, protected router: Router) { }

  canActivate(): Observable<boolean | UrlTree> | boolean {
    return this.store.pipe(
      select(fromAuth.isLoginPending),
      first(pending => !pending),
      switchMap(() => this.store.pipe(select(fromAuth.isLoggedIn))),
      map((isLoggedIn) => isLoggedIn || this.router.parseUrl('/')),
      take(1)
    );
  }

}
