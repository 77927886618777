import { Injectable } from '@angular/core';
import data from './spain.json';

@Injectable({
  providedIn: 'root'
})
export class PostalzipService {
  constructor() {}

  getPopulationByZip(zipCode: string) {
    return data.find(t => t.postal_code == zipCode);
  }

  getProvinceByZip(zipCode: string) {
    const provinces = [
      { name: 'Araba/Álava', init: 1000, end: 1520 },
      { name: 'Albacete', init: 2000, end: 2696 },
      { name: 'Alicante', init: 3000, end: 3860 },
      { name: 'Almería', init: 4000, end: 4897 },
      { name: 'Ávila', init: 5000, end: 5697 },
      { name: 'Badajoz', init: 6000, end: 6980 },
      { name: 'Baleares', init: 7000, end: 7860 },
      { name: 'Barcelona', init: 8000, end: 8980 },
      { name: 'Burgos', init: 9000, end: 9693 },
      { name: 'Cáceres', init: 10000, end: 10991 },
      { name: 'Cádiz', init: 11000, end: 11693 },
      { name: 'Castellon', init: 12000, end: 12609 },
      { name: 'Ciudad Real', init: 13000, end: 13779 },
      { name: 'Córdoba', init: 14000, end: 14970 },
      { name: 'A Coruña', init: 15000, end: 15981 },
      { name: 'Cuenca', init: 16000, end: 16891 },
      { name: 'Gerona', init: 17000, end: 17869 },
      { name: 'Granada', init: 18000, end: 18890 },
      { name: 'Guadalajara', init: 19000, end: 19495 },
      { name: 'Guipuzcoa', init: 20000, end: 20870 },
      { name: 'Huelva', init: 21000, end: 21891 },
      { name: 'Huesca', init: 22000, end: 22880 },
      { name: 'Jaen', init: 23000, end: 23790 },
      { name: 'León', init: 24000, end: 24996 },
      { name: 'Lleida', init: 25000, end: 25796 },
      { name: 'La Rioja', init: 26000, end: 26589 },
      { name: 'Jaen', init: 27000, end: 27891 },
      { name: 'Madrid', init: 28000, end: 28991 },
      { name: 'Málaga', init: 29000, end: 29792 },
      { name: 'Murcia', init: 30000, end: 30892 },
      { name: 'Navarra', init: 31000, end: 31890 },
      { name: 'Ourense', init: 32000, end: 32930 },
      { name: 'Asturias', init: 33000, end: 33993 },
      { name: 'Palencia', init: 34000, end: 34889 },
      { name: 'Las Palmas', init: 35000, end: 35640 },
      { name: 'Pontevedra', init: 36000, end: 36980 },
      { name: 'Salamanca', init: 37000, end: 37900 },
      { name: 'Santa Cruz de Tenerife', init: 38000, end: 38911 },
      { name: 'Cantabria', init: 39000, end: 39880 },
      { name: 'Segovia', init: 40000, end: 40593 },
      { name: 'Sevilla', init: 41000, end: 41980 },
      { name: 'Soria', init: 42000, end: 42368 },
      { name: 'Tarragona', init: 43000, end: 43896 },
      { name: 'Teruel', init: 44000, end: 44793 },
      { name: 'Toledo', init: 45000, end: 45960 },
      { name: 'Valencia', init: 46000, end: 46980 },
      { name: 'Valladolid', init: 47000, end: 47883 },
      { name: 'Bizkaia', init: 48000, end: 48992 },
      { name: 'Zamora', init: 49000, end: 49882 },
      { name: 'Zaragoza', init: 50000, end: 50840 },
      { name: 'Ceuta', init: 51000, end: 51001 },
      { name: 'Melilla', init: 52000, end: 52001 }
    ];
    return provinces.find(p => parseInt(zipCode) >= p.init && parseInt(zipCode) <= p.end);
  }
}
