import { Params } from '@angular/router';
import { createAction, props, union } from '@ngrx/store';


export const navigateTo = createAction('[Router] Navigate to', 
props<{ path: string, params?: Params }>());

export const navigateToOffLine = createAction('[Router] Navigate to offline');

const all = union({
  navigateTo,
  navigateToOffLine
});

export type RouterActionsType = typeof all;