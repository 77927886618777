import { createAction, props, union } from '@ngrx/store';
import { WarehouseProduct } from '@tgw-clients/shared';
import { AccountInfo, BillingAddress, BillingInfo, Credentials, Order, RegisterData, ShippingAddress, User, RegisterShort } from '../models/auth.model';

/* Login */
export const login = createAction('[Auth] Login', props<{ credentials: Credentials; redirect?: boolean; }>());
export const loginSuccess = createAction('[Auth] Login success', props<{ user: User; redirect?: boolean; }>());
export const loginFailure = createAction('[Auth] Login failure', props<{ error: string }>());
export const resetLogin = createAction('[Auth] Reset');

export const autologin = createAction('[Auth] Autologin');
export const autologinSuccess = createAction('[Auth] Autologin success', props<{ user: User }>());
export const autologinFailure = createAction('[Auth] Autologin failure', props<{ error: string }>());

export const logout = createAction('[Auth] Logout');
export const forceLogout = createAction('[Auth] Force logout');
/* Restore password */
export const restorePassword = createAction('[Auth] Restore password', props<{ userOrEmail: string }>());
export const restorePasswordFailure = createAction('[Auth] Restore password failure', props<{ error: string }>());
export const restorePasswordSuccess = createAction('[Auth] Restore password success');

/* New password */
export const setNewPassword = createAction('[Auth] Set new password', props<{ password: string, token: string }>());
export const setNewPasswordFailure = createAction('[Auth] Set new password failure', props<{ error: string }>());
export const setNewPasswordSuccess = createAction('[Auth] Set new password success');

/* Register */
export const register = createAction('[Auth] Register', props<{ registerData: RegisterData }>());
export const registerFailure = createAction('[Auth] Register failure', props<{ error: string }>());
export const registerSuccess = createAction('[Auth] Register success');

export const directRegister = createAction('[Auth] Direct register', props<{ registerData: RegisterData }>());
export const directRegisterFailure = createAction('[Auth] Direct register failure', props<{ error: string; }>());
export const directRegisterSuccess = createAction('[Auth] Direct register success', props<{ credentials: Credentials; }>());

export const showRegisterShortModal = createAction('[Auth] Show register short modal');
export const registerShort = createAction('[Auth] Register short', props<{ registerShort: RegisterShort }>());
export const registerShortFailure = createAction('[Auth] Register failure short', props<{ error: string }>());
export const registerShortSuccess = createAction('[Auth] Register success short');

/* Shipping address */
export const updateShippingAddress = createAction('[Auth] Update shipping address', props<{ shippingAddress: any, isCopy?:boolean }>());
export const deleteShippingAddress = createAction('[Auth] Delete shipping address', props<{ id: string }>());
export const updateShippingAddressFailure = createAction('[Auth] Update shipping address failure', props<{ error: string }>());
export const updateShippingAddressSuccess = createAction('[Auth] Update shipping address success', props<{ shippingAddresses: ShippingAddress[], isCopy?:boolean }>());

/* Billing address */
export const updateBillingAddress = createAction('[Auth] Update billing address', props<{ billingInfo: BillingInfo }>());
export const updateBillingAddressFailure = createAction('[Auth] Update billing address failure', props<{ error: string }>());
export const updateBillingAddressSuccess = createAction('[Auth] Update billing address success', props<{ billingAddress: BillingAddress, id_document_number: string }>());

/* Account */
export const updateAccountInfo = createAction('[Auth] Update account info', props<{ accountInfo: AccountInfo }>());
export const updateAccountInfoFailure = createAction('[Auth] Update account info failure', props<{ error: string }>());
export const updateAccountInfoSuccess = createAction('[Auth] Update account info success', props<{ user: User }>());

/* Orders */
export const loadUserOrders = createAction('[Auth] Load user orders');
export const loadUserOrdersSuccess = createAction('[Auth] Load user orders success', props<{ orders: Order[], }>());
export const loadUserOrdersFailure = createAction('[Auth] Load user orders failure', props<{ error: string }>());

/* Inventory */
export const initializeWarehouse = createAction('[Auth] Initialize warehouse');
export const loadWarehouseProducts = createAction('[Auth] Load warehouse products', props<{ addressId: number }>());
export const loadWarehouseProductsFailure = createAction('[Auth] Load warehouse products failure', props<{ addressId: number; error: string; }>());
export const saveWarehouseProducts = createAction('[Auth] Save warehouse products', props<{ addressId: number, products: WarehouseProduct[] }>());
export const deleteWarehouseProduct = createAction('[Auth] Delete warehouse product', props<{ productId: number, addressId: number }>());
export const downloadWineList = createAction('[Auth] Download wine list', props<{ addressId: number; }>());


export const saveMethodsPerAddress = createAction('[Auth] Save methods per address', props<{ methodsPerAddress: any }>());

/* App-TGW */
export const loginPush = createAction('[Auth] Login Push', props<{ credentials: Credentials; redirect?: boolean; }>());
export const loginPushSuccess = createAction('[Auth] Login Push success', props<{ user: User; redirect?: boolean; }>());


const all = union({
  login,
  loginFailure,
  loginSuccess,
  autologin,
  autologinSuccess,
  autologinFailure,
  restorePassword,
  restorePasswordFailure,
  restorePasswordSuccess,
  setNewPassword,
  setNewPasswordFailure,
  setNewPasswordSuccess,
  register,
  registerFailure,
  registerSuccess,
  directRegister,
  showRegisterShortModal,
  registerShort,
  registerShortFailure,
  registerShortSuccess,
  updateShippingAddress,
  deleteShippingAddress,
  updateShippingAddressFailure,
  updateShippingAddressSuccess,
  updateBillingAddress,
  updateBillingAddressFailure,
  updateBillingAddressSuccess,
  logout,
  forceLogout,
  loadUserOrders,
  loadUserOrdersSuccess,
  loadUserOrdersFailure,
  updateAccountInfo,
  updateAccountInfoFailure,
  updateAccountInfoSuccess,
  initializeWarehouse,
  loadWarehouseProducts,
  saveWarehouseProducts,
  deleteWarehouseProduct,
  loadWarehouseProductsFailure,
  saveMethodsPerAddress,
  downloadWineList,
  loginPush
});

export type AuthActionsUnion = typeof all;
