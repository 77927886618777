import { WPImage } from '../../utils';

export enum HeaderType {
  Landing = 'landing',
  Winery = 'winery',
  Professionals = 'professionals',
  Individuals = 'individuals'
};

export enum Carousels {
  Login = 'tgw-carousel-login',
  Professionals = 'tgw-carousel-home',
  HomeGrid = 'tgw-carousel-homegrid',
  InstagramGrid = 'tgw-carousel-instagram',
  Winery = 'tgw-carousel-winery',
  WineryHome = 'tgw-carousel-winery-slides',
  Club = 'tgw-carousel-club',
  Offers = 'tgw-carousel-offers',
  Experiences = 'tgw-carousel-experiences',
  Packs = 'tgw-carousel-weekly-pack',
  NewCustomers = 'tgw-carousel-new-customers',
  Collection = 'tgw-carousel-collection',
  WineryIndex = 'tgw-carousel-winery-index',
  Magazine = 'tgw-carousel-magazine',
  Winerys = 'tgw-carousel-winery'
}

export enum Menu {
  Winery = 'bodegas',
  FooterSocial = 'footer-social',
  FooterMain = 'footer-main',
  FooterBottom = 'footer-bottom',
  Header = 'header'
}

export interface MenuItem {
  ID: number;
  attr_title: string;
  classes: string[];
  tgw_id: string;
  comment_count: string;
  comment_status: string;
  db_id: number;
  description: string;
  filter: string;
  guid: string;
  menu_item_parent: string;
  menu_order: number;
  object: string;
  object_id: string;
  ping_status: string;
  pinged: string;
  post_author: string;
  post_content: string;
  post_content_filtered: string;
  post_date: string;
  post_date_gmt: string;
  post_excerpt: string;
  post_mime_type: string;
  post_modified: string;
  post_modified_gmt: string;
  post_name: string;
  post_parent: number;
  post_password: string;
  post_status: string;
  post_title: string;
  post_type: string;
  target: string;
  title: string;
  to_ping: string;
  type: string;
  type_label: string;
  url: string;
  xfn: string;
  children?: MenuItem[];
  tgw_slug: string;
  tgw_icon: string;
  show: boolean;
}

export interface MenuSubItem {
  label: string;
  terms: any[];
}

export interface Footer {
  social: MenuItem[];
  main: MenuItem[];
  bottom: MenuItem[];
  misc?: MenuItem[];
}

export interface CarouselRepeater {
  image: WPImage;
  image_mobile: WPImage;
  link: string;
  text: string;
}
export interface CarouselAPI {
  label: string;
  type: ('image' | 'text' | 'repeater')[];
  [key: string]: string | string[] | WPImage | CarouselRepeater[];
}
export interface Carousel {
  label?: string;
  images: WPImage[];
  imagesMobile: WPImage[];
  mobileText?: string;

}

