import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import {
  CartItem,
  CoreConfigService,
  Product,
  addToDataLayer,
  CartActions,
  ShippingZoneMethod,
  WoocommerceService,
  fromAuth
} from '@tgw-clients/shared';
import { map, switchMap } from 'rxjs/operators';

@Component({
  selector: 'tgw-cart',
  template: `
    <div
      class="top-host"
      [class.scrolled-window]="scrolled != undefined && scrolled == true"
    >
      <button
        type="button"
        class="back-btn"
        (click)="closeCart.next()"
        inlineSVG="assets/icons/chevron-left.svg"
      ></button>
      <div
        class="cart-item-wrapper"
        [ngClass]="{
          'extra-padding': getSubtotal(cart, clubUser) < shippingCost
        }"
      >
        <div
          *ngFor="let item of cart; trackBy: trackByFn; let i = index"
          [ngClass]="{ 'no-border': i === cart.length - 1 }"
          class="cart-item"
        >
          <div class="img-wrapper">
            <img
              [src]="
                item.product?.ACF?.logo
                  ? item.product?.ACF?.logo
                  : item.product.images[0]?.src
              "
            />
          </div>
          <div class="desc-wrapper">
            <p class="product-title">
              <span
                style="font-family: 'Futura PT Book'; font-style: 'normal' "
                [innerHTML]="item.product.name"
              ></span>
            </p>
            <div
              class="qty-container"
              [ngClass]="{
                'tgw-qty-container': ['b2c'].includes(this.businnesType)
              }"
            >
              <div
                *ngIf="
                  (!item.date || item.quantity <= item.maxSeats) &&
                    !cart[0].subscription;
                  else notAvailable
                "
                class="qty"
              >
                <button type="button" (click)="updateQuantity(item, -1)">
                  <mat-icon>-</mat-icon>
                </button>
                <input [value]="item.quantity" readonly min="1" />
                <button type="button" (click)="updateQuantity(item, 1)">
                  <mat-icon>+</mat-icon>
                </button>
              </div>
              <div
                *ngIf="
                  (item.product.sale_price != '' &&
                    item.product.sale_price !== item.product.regular_price) ||
                    (clubUser && getRaoPrice(item.product) != undefined);
                  else withoutDiscount
                "
              >
                <div style="margin-left: 10px;">
                  <div style="text-align: start; margin-bottom: 5px">
                    <span class="price">{{
                      getDiscountedPrice(item.product, clubUser)
                        | currency: 'EUR':'code':'1.2-2'
                    }}</span>
                  </div>
                  <div style="text-align: start">
                    <span class="discount-price">{{
                      item.product.regular_price
                        | currency: 'EUR':'code':'1.2-2'
                    }}</span>
                  </div>
                </div>
              </div>
              <p *ngIf="item?.date as date"><span [innerHTML]="date"></span></p>
              <ng-template #withoutDiscount>
                <div style="margin-left: 10px;">
                  <span class="normal-price" *ngIf="!cart[0].subscription">
                    {{
                      +item.product.sale_price || +item.product.regular_price
                        | currency: 'EUR':'code':'1.2-2'
                    }}
                  </span>
                  <span class="normal-price" *ngIf="cart[0].subscription">{{
                    cart[0].subscription.price
                      | currency
                        : 'EUR / ' +
                            cart[0].subscription.frequency.toUpperCase()
                        : 'code'
                        : '1.2-2'
                  }}</span>
                </div>
              </ng-template>
            </div>
            <div class="tooltip">
              <div
                [ngClass]="{
                  'extra-price': !(
                    item.product.sale_price != '' &&
                    item.product.sale_price !== item.product.regular_price
                  )
                }"
                class="delete-container"
              >
                <span
                  class="delete-button"
                  (click)="
                    removeProductFromCart(
                      item.product,
                      item.quantity,
                      item?.date
                    )
                  "
                  >{{ 'Eliminar' | translate }}</span
                >
              </div>
              <div class="tiptext">
                <span
                  class="info-delete-icon"
                  inlineSVG="assets/icons/info.svg"
                ></span>
                <span>{{
                  'Se eliminarán todas las unidades de este artículo'
                    | translate
                }}</span>
              </div>
            </div>
            <ng-template #notAvailable>
              <span *ngIf="!cart[0].subscription">{{
                'tgw-checkout-not-available' | translate
              }}</span>
            </ng-template>
          </div>
        </div>
      </div>
      <div class="tgw-cart-footer">
        <div class="subtotal-wrapper">
          <div style="display: flex; margin-top: 0px;">
            <p style="margin-left: 10px; margin-top: 0px; margin-bottom: 0px;">
              {{ 'tgw-checkout-delivery-date-label' | translate }}:
            </p>
            <p style="margin-left: 10px; margin-top: 0px; margin-bottom: 0px;">
              {{ 'tgw-commons-days' | translate }}
            </p>
          </div>
          <div style="border-bottom: 1px solid black; padding-bottom: 10px;">
            <div style="display: flex; padding-bottom: 5px">
              <p
                style="margin-left: 10px; margin-top: 0px; margin-bottom: 0px;"
              >
                {{ 'tgw-checkout-shipping-cost' | translate }}:
              </p>
              <p
                style="margin-left: 10px; margin-top: 0px; margin-bottom: 5px;"
              >
                {{
                  getShippingCost() === 0
                    ? ('tgw-checkout-free-shipping' | translate)
                    : getShippingCost() === null
                    ? ('tgw-checkout-free-shipping' | translate)
                    : getShippingCost() === undefined
                    ? ('tgw-checkout-shipping-not-included' | translate)
                    : (getShippingCost() | currency: 'EUR':'symbol':'1.2-2')
                }}
              </p>
            </div>
            <div
              *ngIf="getSubtotal(cart, clubUser) < shippingCost"
              class="free-shipping-advise"
            >
              <span inlineSVG="assets/icons/info.svg"></span
              ><span>{{ 'tgw-cart-free-shipping' | translate }}</span>
            </div>
          </div>
          <p class="subtotal-title" style="margin-top: 10px;">
            {{ 'tgw-commons-subtotal' | translate }}
          </p>
          <p>
            {{ getSubtotal(cart, clubUser) | currency: 'EUR':'symbol':'1.2-2' }}
            {{ 'tgw-cart-vat-included' | translate }}
          </p>
        </div>
        <button
          type="button"
          class="btn btn-primary hidden-md-up"
          (click)="redirectTo()"
        >
          {{ 'tgw-commons-show-cart' | translate }}
        </button>
        <button
          type="button"
          class="btn btn-primary hidden-sm-down"
          (click)="redirectTo()"
        >
          {{ 'tgw-commons-show-cart' | translate }}
        </button>
      </div>
    </div>
  `,
  styleUrls: ['./cart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CartComponent {
  @Input() cart: CartItem[];
  @Input() scrolled: boolean = false;
  @Input() isLoggedIn: boolean;
  @Input() clubUser: boolean;
  @Output() delete = new EventEmitter();
  @Output() closeCart = new EventEmitter<void>();

  businnesType = this._coreConfig.businessType;
  isLoggedIn$ = this.store.pipe(select(fromAuth.isLoggedIn));
  methods: ShippingZoneMethod[];
  shippingCost: number;
  shippingMethods$;

  constructor(
    private store: Store,
    private router: Router,
    private _coreConfig: CoreConfigService,
    private _woocommerce: WoocommerceService
  ) {
    this.isLoggedIn$.subscribe(isLoggedIn => {
      if (isLoggedIn && !this.methods) {
        this.shippingMethods$ = this._woocommerce
          .getShippingZones()
          .pipe(map(zones => zones.find(zone => zone.name === 'España')))
          .pipe(
            switchMap(zone => {
              return this._woocommerce.getShippingZoneMethods(zone.id);
            })
          )
          .subscribe(shippingMethods => {
            this.methods = shippingMethods;
          });
      }
    });
  }

  updateQuantity(
    { product, quantity: current, date, maxSeats }: CartItem,
    quantity: number
  ): void {
    const newQuantity = current + quantity;
    if (current + quantity < 1 || (maxSeats && maxSeats < newQuantity)) {
      return;
    }
    this.store.dispatch(
      CartActions.updateItemQuantity({
        product,
        quantity: current + quantity,
        date
      })
    );
  }

  getSubtotal(cart: CartItem[], clubUser: boolean): number {
    let aux = cart.reduce((acc, { product, quantity }) => {
      const price = ['b2c'].includes(this._coreConfig.businessType)
        ? product.regular_price
        : this.findOriginalPrice(product);
      if (clubUser) {
        const raoPrice = this.getRaoPrice(product);
        if (raoPrice != undefined)
          return acc + (+raoPrice || +price) * quantity;
      }
      const salePrice = ['b2c'].includes(this._coreConfig.businessType)
        ? cart[0]?.subscription
          ? cart[0]?.subscription?.price
          : product.sale_price
        : +product.sale_price / 1.21;
      return acc + (+salePrice || +price) * quantity;
    }, 0);
    return aux;
  }

  getRaoPrice(product: Product) {
    let aux = product.meta_data.find(p => p.key === 'rao_club_price');
    if (aux && aux.value != '') return parseFloat(aux.value.replace(',', '.'));
    else return undefined;
  }

  getDiscountedPrice(product: Product, isRao: boolean) {
    if (isRao) {
      const raoPrice = this.getRaoPrice(product);
      if (raoPrice != undefined) return raoPrice;
      else return product.sale_price;
    } else {
      return product.sale_price;
    }
  }

  getShippingCost(): number {
    if (this.cart && this.methods) {
      const flatPrice = this.methods.find(m => m.method_id === 'flat_rate');
      const freeShipping = this.methods.find(
        m => m.method_id === 'free_shipping'
      );
      if (flatPrice && freeShipping) {
        if (
          this.getSubtotal(this.cart, this.clubUser) >=
          parseFloat(freeShipping.settings.min_amount.value)
        ) {
          this.shippingCost = 0;
        } else {
          this.shippingCost = parseFloat(flatPrice.settings.cost.value);
        }
      }
    } else {
      this.shippingCost = undefined;
    }
    return this.shippingCost;
  }

  redirectTo(): void {
    this.router.navigate(['/compra/realizar-pedido']);
    this.closeCart.next();
  }

  trackByFn(_: number, cartItem: CartItem): number {
    return cartItem.product?.id;
  }

  findOriginalPrice(product: Product): string {
    return (
      product.meta_data
        .find(({ key }) => key === 'original_price')
        ?.value.replace(',', '.') || ''
    );
  }

  removeProductFromCart(product: Product, quantity: number, date: any): void {
    addToDataLayer(
      'addToCart',
      product,
      'quitarUnProductoDelCarrito',
      this.businnesType,
      quantity
    );
    this.delete.next({ product, date });
  }
}
