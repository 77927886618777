import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { NotificationDialogComponent } from '@tgw-clients/shared-web/error-handler-web/components/notification-dialog.component';
import { forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CoreConfigService, EndpointType, WPImage } from '../../utils';
import { Carousel, CarouselAPI, CarouselRepeater, Footer, Menu, MenuItem } from '../models';

@Injectable({ providedIn: 'root' })
export class LayoutService {

  constructor(private _coreConfig: CoreConfigService, private http: HttpClient, private translate: TranslateService, public dialog: MatDialog) { }

  getMenu(menu: string): Observable<MenuItem[]> {
    const params = new HttpParams()
      .append('wpml_language', this.translate.currentLang);
    return this.http.get<MenuItem[]>(`${this._coreConfig.getEndpoint(EndpointType.Custom)}menu/${menu}`, { params });
  }

  getFooter(): Observable<Footer> {
    return forkJoin([
      this.getMenu(Menu.FooterSocial),
      this.getMenu(Menu.FooterMain),
      this.getMenu(Menu.FooterBottom)
    ]).pipe(
      map(([social, main, bottom]: MenuItem[][]) => ({
        social,
        main,
        bottom
      }))
    );
  }

  getHeaderMenu(): Observable<MenuItem[]> {
    return this.getMenu(Menu.Header).pipe(
      map((menu: MenuItem[]) => menu.reduce((acc, item, _, orig: MenuItem[]) => {
        const { ID, menu_item_parent: parentId } = item;
        if (+parentId) {
          return acc;
        }
        item.children = orig.filter(({ menu_item_parent }) => +menu_item_parent === ID);
        return [...acc, item];
      }, []))
    );
  }

  getCarousel(name: string): Observable<Carousel> {
    let options = {};
    if (this.translate.currentLang !== this.translate.defaultLang) {
      options = {
        params: new HttpParams().append('lang', this.translate.currentLang)}
    }
    return this.http.get(`${this._coreConfig.getEndpoint(EndpointType.Custom)}carousel/${name}/`, options).pipe(
      map(({ type, ...data }: CarouselAPI) => {
        if (type.find((typ) => typ === 'repeater')) {
          return (data[`${name}-items`] as CarouselRepeater[] || []).reduce((acc, { image, link, image_mobile }) => {
            acc.images.push({ ...image, link });
            if (image_mobile) {
              acc.imagesMobile.push(image_mobile);
            } else {
              acc.imagesMobile.push(image);
            }
            return acc;
          }, <Carousel>{ images: [], imagesMobile: [], link: '' })
        }
        const images: string[] = type.filter((typ) => typ === 'image');
        if (1 < images.length) {
          return <Carousel>{
            label: data[`${name}-text`] || '',
            images: images.map((_, idx: number) => (<WPImage>data[`${name}-image-${idx + 1}`])),
            mobileText:data[`${name}-mobile`] || ''
          };
        }
        return <Carousel>{
          label: data[`${name}-text`] || '',
          images: [(<WPImage>data[`${name}-image`])],
          mobileText:data[`${name}-mobile`] || ''
        };
      })
    );
  }

  getRawCarousel(name: string): Observable<CarouselAPI> {
    let options = {};
    if (this.translate.currentLang !== this.translate.defaultLang) {
      options = {
        params: new HttpParams().append('lang', this.translate.currentLang)}
    }
    return this.http.get<CarouselAPI>(`${this._coreConfig.getEndpoint(EndpointType.Custom)}carousel/${name}/`, options);
  }

  getSubscriptionTags(userId: number): Observable<any> {
    return this.http.get(`${this._coreConfig.getEndpoint(EndpointType.Custom)}newsletter/tags/${userId}`);
  }

  updateNewsletterSubscription(email: string, selection: any[], status: string): Observable<any> {
    const body = { email, selection, status };
    return this.http.post(`${this._coreConfig.getEndpoint(EndpointType.Custom)}updatesubscription`, body);
  }

  subscribeNewsletter(email: string, selection: any[], status: string = 'subscribed'): Observable<any> {
    const body = { email, selection, status };
    return this.http.post(`${this._coreConfig.getEndpoint(EndpointType.Custom)}subscribe`, body);
  }

  subscribeB2c( email: string, nameRest: string,province: string,name: string,telefon: string): Observable<any> {
    const body = { email, nameRest,province,name,telefon};
    return this.http.post(`${this._coreConfig.getEndpoint(EndpointType.Custom)}landing-form`, body);
  }

  openModal(title: string, message: string){

    return this.dialog.open(NotificationDialogComponent, { data: { message, title } }).afterClosed();
  }
}
