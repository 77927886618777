import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromCart from './cart.reducer';

export const cartKey = 'cart';

export interface State {
  [cartKey]: fromCart.State
};

export const reducer = fromCart.reducer;

export const getCartState = createFeatureSelector<State, fromCart.State>(cartKey);

export const getCart = createSelector(
  getCartState,
  fromCart.getCart
);

export const getPending = createSelector(
  getCartState,
  fromCart.getPending
);

