import { Injectable } from '@angular/core';
import { createEffect } from '@ngrx/effects';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { NgcCookieConsentService, NgcStatusChangeEvent } from 'ngx-cookieconsent';
import { takeUntil, takeWhile, tap } from 'rxjs/operators';
import { CoreConfigService } from '../../utils';
import { cookieConfig } from '../models/cookies.models';

@Injectable()
export class CookiesEffects {
  constructor(
    private cookieService: NgcCookieConsentService,
    private _coreConfig: CoreConfigService,
    private translate: TranslateService
  ) { }

  changeCookieDialogLang$ = createEffect(() =>
    this.translate.onLangChange.pipe(
      takeWhile(() => !this.cookieService.hasAnswered()),
      takeUntil(this.cookieService.popupClose$),
      tap(({ translations }: LangChangeEvent) => {
        // Override default messages with the translated ones
        cookieConfig.content.message = translations['tgw-cookie-message'];
        cookieConfig.content.secondMessage = translations['tgw-cookie-second-message'];
        cookieConfig.content.cookiePolicyLink = translations['tgw-cookie-cookie-policy-link'];
        cookieConfig.content.cookiePolicyHref = `${this._coreConfig.getEnv().apiBaseEndpoint.replace(/\/wp-json\/|hosteleria\.|particular\./g,'')}/${translations['tgw-cookie-cookie-policy-href']}`;
        cookieConfig.content.privacyPolicyLink = translations['tgw-cookie-privacy-policy-link'];
        cookieConfig.content.privacyPolicyHref = `${this._coreConfig.getEnv().apiBaseEndpoint.replace(/\/wp-json\/|hosteleria\.|particular\./g,'')}/${translations['tgw-cookie-privacy-policy-href']}`;
        cookieConfig.content.allow = translations['tgw-cookie-allow-cookies'];
        cookieConfig.content.deny = translations['tgw-cookie-decline'];
        cookieConfig.autoOpen = true;
        this.cookieService.destroy(); // remove previous cookie bar (with default messages)
        this.cookieService.init(cookieConfig); // update config with translated messages
      })
    ), { dispatch: false }
  );

  statusChanged$ = createEffect(() =>
    this.cookieService.statusChange$.pipe(
      takeWhile(() => !this.cookieService.hasAnswered()),
      takeUntil(this.cookieService.popupClose$),
      tap((event: NgcStatusChangeEvent) => {
        if (event.status === 'allow') {
          //aqui guardariamos las cookies necesarias
          // let nombre = 'tgw-cookie';
          // let valor = 'tgw-cookie example cookie'
          // let d = new Date();
          // d.setTime(d.getTime() + 24 * 60 * 60 * 1000);
          // let expiracion = "expires=" + d.toUTCString();
          // document.cookie = nombre + "=" + valor + ";" + expiracion + ";path=/";

        } else if (event.status === 'deny') {
          //aqui no dejariamos que se guardarian o las borramos
          // let d = new Date();
          // d.setTime(d.getTime() + 0 * 24 * 60 * 60 * 1000);
          // let expiracion = "expires=" + d.toUTCString();
          // document.cookie = 'tgw-cookie' + "=" + '' + ";" + expiracion + ";path=/";
          // document.cookie = 'cookieconsent_status' + "=" + '' + ";" + expiracion + ";path=/";
        }
      })
    ), { dispatch: false }
  );


}
