import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { ErrorHandlerActions, LayoutActions } from '@tgw-clients/shared';
import { exhaustMap, filter, map, mapTo, startWith } from 'rxjs/operators';
import { RedirectDialogComponent } from '../components/redirect-dialog.component';

@Injectable()
export class LayoutWebEffects {

  initLayout$ = createEffect(() =>
    this.translate.onLangChange.pipe(
      startWith(this.translate.currentLang),
      filter(Boolean),
      mapTo(LayoutActions.loadLayout())
    )
  );

  notifySubcribeSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LayoutActions.subscribeNewsletterSuccess),
      mapTo(ErrorHandlerActions.notifyUser({ message: 'tgw-newsletter-subcribe-success' }))
    )
  );

  notifySubcribeError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LayoutActions.subscribeNewsletterFailure),
      map(() => ErrorHandlerActions.notifyUser({ message: 'tgw-commons-service-failure' }))
    )
  );

  notifySubscribeB2cSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LayoutActions.notifySubscribeB2cSuccess),
      mapTo(ErrorHandlerActions.notifyUser({ message: 'tgw-form-subcribe-success' }))
    )
  );

  notifySubscribeB2cError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LayoutActions.notifySubscribeB2cError),
      map(() => ErrorHandlerActions.notifyUser({ message: 'tgw-form-service-failure' }))
    )
  );

  openRedirectDialog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LayoutActions.openRedirectDialog),
      exhaustMap(({ path: data }) => this.dialog.open(RedirectDialogComponent, { data }).afterClosed())
    ), { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private translate: TranslateService,
    private dialog: MatDialog
  ) { }
}
