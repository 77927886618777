import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import {
  CartItem,
  CoreConfigService,
  fromAuth,
  HeaderType,
  Logo,
  menuAnimation,
  MenuItem,
  Product,
  RouterActions
} from '@tgw-clients/shared';
import { Observable } from 'rxjs';

@Component({
  selector: 'tgw-header-customer',
  template: `
    <ng-container *ngTemplateOutlet="header"></ng-container>
    <ng-container *ngIf="scrolled">
      <ng-container
        *ngTemplateOutlet="header; context: { $implicit: true }"
      ></ng-container>
    </ng-container>
    <ng-template #header let-scrolled>
      <header
        [ngClass]="type"
        [class.scrolled]="scrolled"
        [class.clubrao]="memberRao"
        [class.b2c-header]="isB2cBusinessType"
      >
        <div class="container">
          <mat-toolbar>
            <mat-toolbar-row class="header-intro" *ngIf="scrolled">
              <a [routerLink]="['/' | localize: lang]" class="scrolled-logo">
                <img
                  [src]="logo"
                  alt="The Grand Wines"
                />
              </a>
            </mat-toolbar-row>
            <mat-toolbar-row #mainRow class="header-actions" [class.b2c-lang-row]="isB2cBusinessType">
            <ng-container *ngIf="['b2c'].includes(businnesType)">
              <ul 
              *ngIf="languages.length"
              [disabled]="languages.length === 0"
              #langSelector
              (change)="changeLang.next(langSelector.value)"
              class="lang-selector"
            >
              <li
                *ngFor="let language of languages; trackBy: trackByFn"
                [selected]="lang === language"
                [value]= "language"
              >
              {{ language | uppercase }}
              </li>
            </ul>
            </ng-container>
              <ng-template [ngIf]="type === 'professionals'">
                <button
                  class="mobile-menu-button"
                  mat-icon-button
                  (click)="openMenu()"
                >
                  <span
                    class="icon"
                    inlineSVG="assets/icons/menu_icon.svg"
                  ></span>
                </button>
                <tgw-mobile-menu
                  class="mobile-menu"
                  *ngIf="showMenu"
                  (hideMenu)="hideMenu()"
                  [languages]="languages"
                  [lang]="lang"
                  [type]="type"
                  [user]="username"
                  [menu]="auxMenu"
                  (hide)="hideMenu()"
                  (changeLang)="changeLang.next($event)"
                  (logout)="logout.next()"
                  (search)="searchSubmit($event)"
                  @menu
                ></tgw-mobile-menu>
              </ng-template>
              <!-- <div *ngIf="!scrolled" class="fill-container"></div> -->
              <div
                class="professionals-logo"
                *ngIf="type === 'professionals' && !scrolled"
              >
                <a [routerLink]="['/' | localize: lang]">
                  <img
                    [src]="logo"
                    alt="The Grand Wines"
                  />
                </a>
              </div>
              <div class="header-top-nav" [class.b2c-header-top-nav]="isB2cBusinessType">
                <form
                  *isBusinessType="'!winery'"
                  class="search-wrapper hidden-sm-down"
                  [formGroup]="searchForm"
                  (ngSubmit)="searchSubmit(searchForm.value.search)"
                >
                  <fieldset>
                    <input formControlName="search" type="text" />
                    <button type="button" type="submit">
                      <span
                        class="icon"
                        inlineSVG="assets/icons/search.svg"
                      ></span>
                    </button>
                  </fieldset>
                </form>
                <div class="user-section" >
                  <div [ngClass]="{'logueado' : logueado}">
                    <a
                      [routerLink]="['/mi-cuenta' | localize: lang]"
                      *ngIf="!logueado"
                    >
                      <span
                        class="icon"
                        inlineSVG="assets/icons/user_icon.svg"
                      ></span>
                    </a>
                    <a
                      [routerLink]="['/mi-cuenta/datos' | localize: lang]"
                      *ngIf="logueado"
                    >
                      <span
                        class="icon"
                        inlineSVG="assets/icons/user_icon.svg"
                      ></span>
                    </a>
                  </div>
                </div>
                <ng-template [ngIf]="type === 'professionals'">
                  <div
                    class="icon cart-icon hidden-sm-down"
                    [class.cart-reduce]="scrolled"
                    [class.b2c-cart-icon]="isB2cBusinessType"
                    (mouseenter)="showCart(cart)"
                  >
                    {{ 'tgw-cart-your-cart' | translate }} ({{ getCartCount(cart) }})
                  </div>
                  <div
                    class="cart-icon cart-reduce mobile-cart hidden-md-up"
                    (click)="toggleCart(cart)"
                  >
                    <span
                      class="icon"
                      inlineSVG="assets/icons/bolsa.svg"
                    ></span>
                  </div>
                  <tgw-cart
                    [scrolled]="scrolled"
                    *ngIf="isCartVisible"
                    [cart]="cart"
                    (delete)="removeCartProduct.next($event)"
                    (mouseleave)="hideCart()"
                    (closeCart)="hideCart()"
                    [isLoggedIn]="isLoggedIn$ | async"
                    [clubUser]="memberRao"
                  ></tgw-cart>
                </ng-template>
              </div>
            </mat-toolbar-row>
            <mat-toolbar-row *ngIf="!scrolled" class="header-intro">
              <div *ngIf="type === 'landing'">
                <h2>{{ 'tgw-winery-header-title' | translate | uppercase }}</h2>
                <h3>{{ 'tgw-winery-header-subtitle' | translate }}</h3>
              </div>
              <ng-container *isBrand="'tgw'">
                <img
                  *ngIf="type === 'landing'"
                  class="img-prize"
                  [src]="prizeLogo"
                />
              </ng-container>
            </mat-toolbar-row>
            <mat-toolbar-row
              *ngIf="type === 'professionals' && auxMenu?.length"
              class="menu-row"
            >
              <div class="menu-wrapper" *ngIf="auxMenu">
                <ng-container
                  *ngFor="
                    let item of auxMenu;
                    let i = index;
                    trackBy: trackById
                  "
                >
                <span [class.b2c-separator]="isB2cBusinessType">|</span>
                  <div>
                    <ng-template
                      [ngIf]="item.children?.length"
                      [ngIfElse]="linkItem"
                    >
                    <!-- (mouseleave)="hideSubmenu($event)"  linea 224 -->
                      <a *ngIf="item.tgw_slug == 'tienda'"
                        (mouseenter)="
                          scrolled
                            ? (showSubmenuScrolled = true)
                            : (showSubmenu = true)
                        "
                        (click)="
                          scrolled
                            ? (showSubmenuScrolled = false)
                            : (showSubmenu = false)
                        "
                        [routerLink]="item.tgw_slug"
                        routerLinkActive="current"
                        >{{ item.title }}</a
                      >
                      <a *ngIf="item.tgw_slug == 'colecciones'"
                        (mouseenter)=" 
                          scrolled
                            ? (showCollectionsScrolled = true)
                            : (showCollectionsMenu = true)
                        "
                        (click)="
                          scrolled
                            ? (showCollectionsScrolled = false)
                            : (showCollectionsMenu = false)
                        "
                        [routerLink]="item.tgw_slug"
                        routerLinkActive="current"
                        >{{ item.title }}</a
                      >
                      
                      <ng-container *ngIf="!scrolled; else scrolledSubmenu">
                        <div *ngIf="item.tgw_slug == 'tienda'"
                          #submenu
                          (mouseleave)="showSubmenu = false"
                          [ngClass]="'submenu-item-'+ item.tgw_slug"
                          [class.displayed]="showSubmenu"
                          [class.extramargin]="memberRao"
                        >
                          <!-- [ngClass]="[item.children?.length ? 'submenu-item-'+ item.tgw_slug: '']" -->
                          <div
                            class="submenu-section"
                            *ngFor="let subItem of item?.children"
                          >
                            <p>{{ subItem.title }}</p>
                            <p>
                              <tgw-header-submenu
                                (hideSubmenuOnClick)="showSubmenu = false"
                                [show]="show"
                                [menuId]="subItem?.tgw_id"
                                [slug]="subItem?.title"
                              ></tgw-header-submenu>
                            </p>
                            
                          </div>
                        </div>
                        
                        <div *ngIf="item.tgw_slug == 'colecciones'"
                          #collectionsMenuElement
                          (mouseleave)="showCollectionsMenu = false"
                          [ngClass]="'submenu-item-'+ item.tgw_slug"
                          [class.displayed]="showCollectionsMenu"
                          [class.extramargin]="memberRao"
                        >
                          <!-- <ng-template class="collection-submenu"> -->
                            <div class="submenu-section">
                              <collections-submenu [items]="item.children" (hideMenu)="hideCollectionsMenu($event)"></collections-submenu>
                            </div>
                          <!-- </ng-template> -->
                        </div>
                      </ng-container>
                      <ng-template #scrolledSubmenu>
                        <div *ngIf="item.tgw_slug == 'tienda'"
                          #submenuScrolled
                          (mouseleave)="showSubmenuScrolled = false"
                          [class.displayed]="showSubmenuScrolled"
                          [class.reposition]="showSubmenuScrolled"
                          [ngClass]="'submenu-item-'+ item.tgw_slug"
                          [class.scrolledextramargin]="memberRao"
                        >
                          <div
                            class="submenu-section"
                            *ngFor="let subItem of item?.children"
                          >
                            <p>{{ subItem.title }}</p>
                            <p>
                              <tgw-header-submenu
                                (hideSubmenuOnClick)="showSubmenuScrolled = false"
                                [show]="show"
                                [menuId]="subItem?.tgw_id"
                                [slug]="subItem?.title"
                              ></tgw-header-submenu>
                            </p>
                          </div>
                        </div>
                        <div *ngIf="item.tgw_slug == 'colecciones'"
                          #collectionsScrolledElement
                          (mouseleave)="showCollectionsScrolled = false"
                          [class.displayed]="showCollectionsScrolled"
                          [class.reposition]="showCollectionsScrolled"
                          [ngClass]="'submenu-item-'+ item.tgw_slug"
                          [class.scrolledextramargin]="memberRao"
                        >
                            
                          <div class="submenu-section">
                            <collections-submenu [items]="item.children" (hideMenu)="hideCollectionsMenu($event)"></collections-submenu>
                          </div>

                        </div>
                      </ng-template>
                    </ng-template>
                    <ng-template #linkItem>
                      <ng-template
                        [ngIf]="item.tgw_slug"
                        let-slug
                        [ngIfElse]="disabled"
                      >
                        <a
                          *ngIf="item.type_label !== 'Enlace externo'"
                          (mouseenter)="
                            scrolled
                              ? (showSubmenuScrolled = false)
                              : (showSubmenu = false)
                          "
                          [routerLink]="slug"
                          routerLinkActive="current"
                          >
                          <ng-container *ngIf="item.title !=='Club RAO'">
                            {{ item.title }}
                          </ng-container>
                          <ng-container *ngIf="item.title ==='Club RAO'">
                            <span
                            [class.b2c-logo-RAO]="isB2cBusinessType"
                            class="icon"
                            inlineSVG="assets/icons/clubRao.svg"
                            ></span>
                          </ng-container>
                          </a
                        >
                        <a
                          *ngIf="item.type_label === 'Enlace externo'"
                          (mouseenter)="
                            scrolled
                              ? (showSubmenuScrolled = false)
                              : (showSubmenu = false)
                          "
                          [href]="slug"
                          routerLinkActive="current"
                          >{{ item.title }}</a
                        >

                      </ng-template>
                      <ng-template #disabled>
                        <a class="disabled">{{ item.title }}</a>
                      </ng-template>
                    </ng-template>
                  </div>
                </ng-container>
              </div>
            </mat-toolbar-row>
          </mat-toolbar>
        </div>
        <img
          *ngIf="!scrolled"
          [class.b2c-line-separator]="isB2cBusinessType"
          class="gold-row"
          src="assets/icons/degradado-09.jpg"
        />
      </header>
    </ng-template>
  `,
  styleUrls: ['./header-customer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [menuAnimation]
})
export class HeaderCustomerComponent implements OnChanges {
  @ViewChild('mainRow') mainRow: ElementRef;
  @ViewChild('submenu') submenu: ElementRef;
  @ViewChild('submenuScrolled') submenuScrolled: ElementRef;
  @ViewChild('collectionsMenuElement') collectionsMenuElement: ElementRef;
  @ViewChild('collectionsScrolledElement') collectionsScrolledElement: ElementRef;
  @Input() lang: string;
  @Input() menu: MenuItem[];
  @Input() cart: CartItem[] = [];
  @Input() type: HeaderType;
  @Input() username: string;

  @Output() removeCartProduct = new EventEmitter<CartItem>();
  @Output() logout = new EventEmitter<void>();
  @Output() changeLang = new EventEmitter<string>();

  isLoggedIn$: Observable<boolean> = this.store.pipe(
    select(fromAuth.isLoggedIn)
  );
  user$ = this.store.pipe(select(fromAuth.getUser));
  show = false;
  showMenu = false;
  showCollectionsMenu = false;
  showSubmenu = false;
  showCollectionsScrolled = false;
  showSubmenuScrolled = false;
  isCartVisible = false;
  memberRao: boolean = false;
  logo = this._coreConfig.getLogo(Logo.Header);
  prizeLogo = this._coreConfig.getLogo(Logo.Prize);
  languages = this.translate.langs;
  scrolled = false;
  brand = this._coreConfig.brand;
  auxMenu: MenuItem[] = [];
  businnesType = this._coreConfig.businessType;
  logueado: boolean = false;
  isB2cBusinessType: boolean = this._coreConfig.businessType === "b2c";

  searchForm = new FormGroup({
    search: new FormControl('')
  });

  constructor(
    private _coreConfig: CoreConfigService,
    private translate: TranslateService,
    private store: Store,
    private router: Router,
    private elRef: ElementRef
  ) {
    this.isLoggedIn$.subscribe(loggedIn => (this.logueado = loggedIn));
    this.user$.subscribe(
      info =>
        this.memberRao = (info && info.rao_club && info.rao_club === '1' && ['b2c'].includes(this.businnesType))
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.auxMenu = [];
    if (this.menu && this.menu.length > 0) {
      this.auxMenu = this.menu;
    }
    if (
      changes.cart &&
      !changes.cart.currentValue?.length &&
      this.isCartVisible
    ) {
      this.isCartVisible = false;
    }
  }

  toggleCart(cart: CartItem[]): void {
    if (!this.isCartVisible && this.getCartCount(cart) < 1) {
      return;
    }
    this.isCartVisible = !this.isCartVisible;
  }

  showCart(cart: CartItem[]): void {
    if (!this.isCartVisible && 0 < this.getCartCount(cart)) {
      this.isCartVisible = true;
    }
  }

  hideMenu(): void {
    this.showMenu = false;
  }

  openMenu(): void {
    this.showMenu = true;
  }

  hideCart(): void {
    this.isCartVisible = false;
  }

  getCartCount(cart: { product: Product; quantity: number }[]): number {
    return (cart || []).reduce((acc, { quantity }) => acc + quantity, 0);
  }

  trackById(_: number, item: MenuItem): number {
    if (item.ID) return item.ID;
  }

  trackByFn(_: number, lang: string): string {
    return lang;
  }

  goTo(path: string, cartCount: number): void {
    if (cartCount > 0) {
      this.store.dispatch(RouterActions.navigateTo({ path }));
    }
  }

  hideCollectionsMenu(ev: boolean){
    this.showCollectionsScrolled = false;
    this.showCollectionsMenu = false;
  }

  hideSubmenu(event: MouseEvent): void {
    if (this.scrolled) {
      if (
        !(<Element>event.relatedTarget)?.isSameNode(
          this.submenuScrolled.nativeElement
        )
      ) {
        this.showSubmenuScrolled = false;
      } else if(!(<Element>event.relatedTarget)?.isSameNode(this.collectionsScrolledElement.nativeElement)){
        this.showCollectionsScrolled = false;
      }
    } else {
      if (
        !(<Element>event.relatedTarget)?.isSameNode(this.submenu.nativeElement)
      ) {
        this.showSubmenu = false;
      } else if(!(<Element>event.relatedTarget)?.isSameNode(this.collectionsMenuElement.nativeElement)){
        this.showCollectionsMenu = false;
      }
    }
  }

  searchSubmit(searchText: string): void {
    if (searchText) {
      this.router.navigate(['/tienda/1'], { queryParams: { s: searchText } });
      this.showMenu = false;
    }
  }

  @HostListener('window:scroll', ['$event.target'])
  onScroll(target: Document): void {
    if (
      ((this._coreConfig.businessType !== 'winery' &&
        this.elRef.nativeElement.offsetHeight) ||
        this.mainRow.nativeElement.offsetHeight) <
      target.scrollingElement.scrollTop
    ) {
      this.scrolled = true;
    } else {
      this.scrolled = false;
    }
  }
}
