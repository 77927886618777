import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'tgw-not-found-page',
  template: `
    <div *isBrand="'rao'"  class="container">
      <h1>{{'tgw-404-not-found' | translate}}<span>404</span></h1>
      <a class="btn btn-primary" [routerLink]="['/tienda' | localize]">{{'tgw-commons-back' | translate}}</a>
    </div>

    <div *isBrand="'tgw'" class="container container-tgw-b2c">
      <h1 class="tgw-b2c">{{'tgw-404-not-found' | translate}}<span>404</span></h1>
      <a class="btn btn-primary tgw-b2c-btn" [routerLink]="['/tienda' | localize]">{{'tgw-commons-back' | translate}}</a>
    </div>
  `,
  styleUrls: ['./not-found-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotFoundPageComponent {

  constructor() { }

}
