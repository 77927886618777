import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '../shared';
import { CarouselComponent } from './components/carousel.component';
import { NotFoundPageComponent } from './components/not-found-page.component';
import { ProductCardComponent } from './components/product-card.component';
import { ProductCardTgwComponent } from './components/product-card-tgw.component';
import { PaginatorComponent } from './components/paginator.component';
import { ProductsSwiperComponent } from './components/products-swiper/products-swiper.component';
import { SwiperModule, SwiperConfigInterface, SWIPER_CONFIG } from 'ngx-swiper-wrapper';

const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  direction: 'horizontal',
  slidesPerView: 'auto'
};

export { NotFoundPageComponent };
@NgModule({
  declarations: [
    NotFoundPageComponent,
    ProductCardComponent,
    CarouselComponent,
    PaginatorComponent,
    ProductCardTgwComponent,
    ProductsSwiperComponent
  ],
  imports: [
    SharedModule,
    TranslateModule,
    RouterModule,
    SwiperModule
  ],
  exports: [
    ProductCardComponent,
    ProductCardTgwComponent,
    CarouselComponent,
    PaginatorComponent,
    ProductsSwiperComponent,
    NotFoundPageComponent
  ],
  providers: [
    {
      provide: SWIPER_CONFIG,
      useValue: DEFAULT_SWIPER_CONFIG
    }
  ]
})
export class UiComponentsModule { }
