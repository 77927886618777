import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { combineLatest, concat, EMPTY, merge, of } from 'rxjs';
import { catchError, concatMap, exhaustMap, map, mapTo, reduce, switchMap, takeUntil, tap, withLatestFrom } from 'rxjs/operators';
import { CartActions } from '../../cart';
import { ErrorHandlerService } from '../../error-handler';
import { CoreConfigService, WoocommerceService } from '../../utils';
import { AuthActions } from '../actions';
import { User } from '../models/auth.model';
import { AuthService } from '../services/auth.service';
import { AlertService } from '@tgw-app/lazy/shared-app/services/alert.service';
import { PurchaseActions } from '@tgw-clients/rao-client/purchase-profesionales/actions';
import { select, Store } from '@ngrx/store';
import { fromAuth } from '@tgw-clients/shared';


@Injectable()
export class AuthEffects {

  autologin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.autologin),
      switchMap(() =>
        this._auth.autologin().pipe(
          map((user) => AuthActions.autologinSuccess({ user })),
          catchError(error => {
            return [AuthActions.autologinFailure({ error: error.message })]
          })
        )
      )
    )
  );

  login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.login),
      switchMap(({ credentials: { username, password }, redirect = true }) =>
        this._auth.login(username, password).pipe(
          map((user: User) => AuthActions.loginSuccess({ user, redirect })),
          catchError(({ code }) => {
            let error = 'tgw-login-failure';
            switch (code) {
              case 'invalid_email':
              case 'invalid_username':
              case 'incorrect_password':
                error = 'tgw-login-invalid-credentials';
                break;
              case 'user_not_approved':
                error = 'tgw-login-user-not-approved';
                break;
            }
            return [AuthActions.loginFailure({ error })];
          })
        )
      )
    )
  );

  logout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.logout, AuthActions.forceLogout),
      tap(() => this._auth.logout()),
      mapTo(CartActions.clearCart())
    )
  );

  register$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.register),
      exhaustMap(({ registerData }) =>
        this._auth.register(registerData).pipe(
          // switchMap(() => this._errorHandler.notify({ message: 'tgw-register-success', title: 'Gracias por registrarte en Rioja Alavesa Original' })),
          map(() => {
            if (this.coreConfig.businessType === 'winery') {
              this.alertService.showMissingRegisterAlert();
              return AuthActions.loginPush({ credentials:{username:registerData.email}})
            }
            return AuthActions.registerSuccess()
          }),
          catchError((error) => {
            if(error.error.code == "registration-error-email-exists" && this.coreConfig.businessType === 'winery'){
              this.alertService.showMissingRegisterExistsAlert();
            }
              return [AuthActions.registerFailure({ error: error.status === 400 ? `tgw-${error.error.code}` : 'tgw-register-failure' })];
          })
        )
      )
    )
  );

  directRegister$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.directRegister),
      exhaustMap(({ registerData }) =>
        this._auth.register(registerData).pipe(
          mapTo(AuthActions.directRegisterSuccess({ credentials: { username: registerData.email, password: registerData.password } })),
          catchError((error) => {
            return [AuthActions.directRegisterFailure({ error: error.status === 400 ? `tgw-${error.error.code}` : 'tgw-register-failure' })];
          })
        )
      )
    )
  );

  loginOnDirectRegisterSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.directRegisterSuccess),
      map(({ credentials }) => AuthActions.login({ credentials, redirect: false }))
    )
  );

  registerShort$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.registerShort),
      exhaustMap(({ registerShort }) =>
        this._auth.registerShort(registerShort).pipe(
          mapTo(AuthActions.registerShortSuccess()),
          catchError((error) => [AuthActions.registerShortFailure({ error: error.status === 204 ? `tgw-registration-error-email-exists` : 'tgw-register-failure' })])
        )
      )
    )
  );

  restorePassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.restorePassword),
      exhaustMap(({ userOrEmail }) =>
        this._auth.restorePassword(userOrEmail).pipe(
          map(() => AuthActions.restorePasswordSuccess()),
          catchError(() => [AuthActions.restorePasswordFailure({ error: 'tgw-restore-password-failure' })])
        )
      )
    )
  );

  setNewPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.setNewPassword),
      exhaustMap(({ password, token }) =>
        this._auth.setNewPassword(token, password).pipe(
          map(() => AuthActions.setNewPasswordSuccess()),
          catchError(() => [AuthActions.setNewPasswordFailure({ error: 'tgw-set-new-password-failure' })])
        )
      )
    )
  );

  shippingAddress$ = createEffect(() =>
  merge(
    this.actions$.pipe(
      ofType(AuthActions.updateShippingAddress),
      exhaustMap(({ shippingAddress: { id, ...shipping }, isCopy }) =>
        ((!!id) ? this._auth.updateUserShippingAddress({ id, ...shipping }) : this._auth.createUserShippingAddress(shipping)).pipe(
          switchMap(() => this._auth.getUserShippingAddresses()),
          map((shippingAddresses) => AuthActions.updateShippingAddressSuccess({ shippingAddresses, isCopy })),
          catchError(() => {
            const error = id ? 'tgw-shipping-address-update-failure' : 'tgw-shipping-address-create-failure';
            return [AuthActions.updateShippingAddressFailure({ error })];
          })
        )
      )
    ),
    this.actions$.pipe(
      ofType(AuthActions.deleteShippingAddress),
      exhaustMap(({ id }) =>
        (this._auth.deleteUserShippingAddress({ id }).pipe(
          switchMap(() => this._auth.getUserShippingAddresses()),
          map((shippingAddresses) => AuthActions.updateShippingAddressSuccess({ shippingAddresses, isCopy: false })),
          catchError(() => {
            const error = 'Se ha producido un error al eliminar la dirección de envío.';
            return [AuthActions.updateShippingAddressFailure({ error })];
          })
        )
        )
    )
    )
    )
  );

  billingAddress$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.updateBillingAddress),
      exhaustMap(({ billingInfo }) => {
        return this._auth.updateUserBillingAddress(billingInfo)
          .pipe(
            map(({ billingAddress, id_document_number }: User) => AuthActions.updateBillingAddressSuccess({ billingAddress, id_document_number })),
            catchError(error => of(AuthActions.updateBillingAddressFailure({ error })))
          )
      }),
    )
  );

  updateAccountInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.updateAccountInfo),
      exhaustMap(({ accountInfo }) => {
        return this._auth.updateUserAccountInfo(accountInfo)
          .pipe(
            map((user: User) => AuthActions.updateAccountInfoSuccess({ user })),
            catchError(error => [AuthActions.updateAccountInfoFailure({ error })])
          )
      })
    )
  );

  loadUserOrders = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.loadUserOrders),
      switchMap(() =>
        this._auth.getOrdersPlace()
          .pipe(
            takeUntil(this.actions$.pipe(ofType(AuthActions.logout, AuthActions.forceLogout))),
            map(orders => AuthActions.loadUserOrdersSuccess({ orders })),
            catchError((error) => [AuthActions.loadUserOrdersFailure({ error })])
          )
      )
    )
  );

  loadInventoryProducts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.loadWarehouseProducts),
      switchMap(({ addressId }) =>
        this._auth.getProductsInventoryByIdAddress(addressId).pipe(
          takeUntil(this.actions$.pipe(ofType(AuthActions.logout, AuthActions.forceLogout))),
          map((response) => AuthActions.saveWarehouseProducts({ addressId, products: response })),
          catchError(() => [AuthActions.loadWarehouseProductsFailure({ error: 'tgw-warehouse-load-error', addressId })])
        )
      )
    )
  );

  deleteInventoryProducts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.deleteWarehouseProduct),
      switchMap(({ productId, addressId }) =>
        this._auth.deleteWarehouseProduct(productId, addressId).pipe(
          map(() => AuthActions.loadWarehouseProducts({ addressId }))
        )
      )
    )
  );


  loadShippingZoneMethods$ = createEffect(() =>
    merge(
      this.actions$.pipe(ofType(AuthActions.loginSuccess, AuthActions.autologinSuccess)),
      this.actions$.pipe(ofType(AuthActions.updateShippingAddressSuccess), map(({ shippingAddresses }) => ({ user: { shippingAddresses } })))
    ).pipe(
      exhaustMap(({ user: { shippingAddresses } }) =>
        combineLatest([
          this._woocommerce.getShippingZones(),
          this._woocommerce.getCountries()
        ]).pipe(
          switchMap(([zones, countries]) =>
            concat(shippingAddresses).pipe(
              concatMap(({ id, country }) => {
                const zoneId = zones.find(({ name }) => name === countries.find(({ code }) => code === country)?.name)?.id;
                return this._woocommerce.getShippingZoneMethods(zoneId || 0).pipe(
                  catchError(() => EMPTY),
                  map((method) => ({ [id]: method }))
                );
              })
            )
          ),
          reduce((acc, method) => ({
            ...acc,
            ...method
          })),
          takeUntil(this.actions$.pipe(ofType(AuthActions.logout, AuthActions.forceLogout))),
          map((methodsPerAddress) => AuthActions.saveMethodsPerAddress({ methodsPerAddress }))
        )
      )
    )
  );

  selectedAdressDefault$ = createEffect(() =>
  this.actions$.pipe(
    ofType(AuthActions.saveMethodsPerAddress),
    withLatestFrom(this.store.pipe(select(fromAuth.getShippingAddresses))
    ),
    map(([, addresses]) => {
      console.log('¿¿¿',addresses)
     return PurchaseActions.selectAddresses({ addressesIds: [addresses[0].id] })

    })
  )
);


  messageSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.registerSuccess),
      switchMap(() =>this._errorHandler.notify({ message: 'tgw-register-success', title: 'tgw-register-success-title' })),
    ), { dispatch: false }
  );

  downloadWineList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.downloadWineList),
      switchMap(({ addressId }) =>
        this._auth.downloadWineList(addressId).pipe(
          tap((blob) => {
            const url = URL.createObjectURL(blob);
            const downloadLink = document.createElement('a');
            downloadLink.href = url;
            downloadLink.download = `Carta de vinos.pdf`;
            downloadLink.click();
            URL.revokeObjectURL(url);
            downloadLink.remove();
          }),
          catchError((error) => {
            console.error(error);
            return EMPTY;
          })
        )
      )
    ), { dispatch: false }
  );


  loginPush$ = createEffect(() =>
  this.actions$.pipe(
    ofType(AuthActions.loginPush),
    switchMap(({ credentials: { username }, redirect = true }) =>
      this._auth.loginPushDevice(username).pipe(
        map((user: User) => AuthActions.loginPushSuccess({ user, redirect }))
      )
    )
  )
);


  constructor(
    private actions$: Actions,
    private _auth: AuthService,
    private _errorHandler: ErrorHandlerService,
    private _woocommerce: WoocommerceService,
    private alertService: AlertService,
    private coreConfig: CoreConfigService,
    private store: Store,
  ) { }
}

// CouponService
