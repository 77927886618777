import { createAction, props, union } from '@ngrx/store';
import { Product } from '@tgw-clients/shared';

export const loadOfferProducts = createAction('[Products] Load offer products', props<{ page: string }>());
export const saveOfferProducts = createAction('[Products] Save offer products', props<{ page: number; products: Product[], totalPages: number, found: number, maxPrice: number, minPrice: number }>());

const all = union({
  loadOfferProducts,
  saveOfferProducts
});

export type OffersActionsUnion = typeof all;
