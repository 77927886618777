import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { CartActions, StorageCartItem } from '../../cart';
import { ProductsActions } from '../../products';

@Injectable({
  providedIn: 'root'
})
export class ShopGuard implements CanActivate {


  constructor(private store: Store) { }

  canActivate(
    route: ActivatedRouteSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const { productPage } = route.params;
    // Swap hyphens for spaces so that the search engine works
    let auxParams = {};
    for (const param in route.queryParams) {
      if (route.queryParams[param] === 'all') {
        auxParams[param.replace(/-/g, " ")] = route.queryParams[param].replace('all', "");
      } else {
        auxParams[param.replace(/-/g, " ")] = route.queryParams[param].replace(/-/g, " ");
      }
    }
    route.queryParams = auxParams;
    const { s, ...queryParamsData } = route.queryParams;
    this.store.dispatch(ProductsActions.loadProductsPage({ page: '1', queryParamsData, queryParamsSearch: s }));
    return true;
  }

}
