import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { _createRouterConfig } from '@ngrx/router-store/src/router_store_module';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { AuthActions, CartActions, CartItem, collectRouteData, CoreConfigService, ErrorHandlerActions, Footer, fromAuth, fromCart, fromLayout, fromRouter, LayoutActions, MenuItem } from '@tgw-clients/shared';
import { CouponsActions, fromCoupons } from '@tgw-clients/shared/coupons';
import { combineLatest, EMPTY, fromEvent, Observable } from 'rxjs';
import { filter, map, shareReplay, startWith, switchMap, tap } from 'rxjs/operators';
import { NEW_CUSTOMER_COUPON_MODAL_SHOWN_KEY } from '../models/layout-web.models';
@Component({
  selector: 'tgw-layout',
  template: `
    <ng-container *ngIf="(headerType$ | async) as type">
      <ng-container *ngIf="(headerMenu$ | async) as menu">
        <rao-header *ngIf="!['b2c'].includes(businnesType)" [lang]="lang$ | async"></rao-header>
        <!-- <tgw-header *ngIf="!['b2c'].includes(businnesType)" [lang]="lang$ | async"  [menu]="menu" [cart]="cart$ | async" [username]="user$ | async" [type]="type" (removeCartProduct)="askRemoveConfirm($event)" (logout)="logout()" (changeLang)="changeLanguage($event)"></tgw-header> -->
        <tgw-header-customer *ngIf="['b2c'].includes(businnesType) && (notRoot$ | async) && 'tgw' != brand"  [lang]="lang$ | async"  [menu]="menu" [cart]="cart$ | async" [username]="user$ | async" [type]="type" (removeCartProduct)="askRemoveConfirm($event)" (logout)="logout()" (changeLang)="changeLanguage($event)"></tgw-header-customer>
        <header-tgw *ngIf="['b2c'].includes(businnesType) && 'tgw'== brand" [lang]="lang$ | async"  [menu]="menu" [cart]="cart$ | async" [username]="user$ | async" [type]="type" (removeCartProduct)="askRemoveConfirm($event)" (logout)="logout()" (changeLang)="changeLanguage($event)"></header-tgw>
      </ng-container>
      <main class="main-wrapper" [class.padding-bottom]="'!isBrandRao'" 
      [class.professionals]="type === 'professionals'"  [class.b2c-wrapper]="!['b2c'].includes(businnesType)"
      [class.bg-white]="'compra' == router.url.split('/')[1]">
        <ng-content></ng-content>
        <ng-container *isBusinessType="'!winery'">
          <tgw-banner-to-register *ngIf="showBanner$ | async"></tgw-banner-to-register>
        </ng-container>
      </main>
    </ng-container>
    <tgw-footer *ngIf="(footer$ | async) as footer" [lang]="lang$ | async" [menus]="footer.menus" [newsletterPending]="footer.newsletterPending" (subscribe)="subscribeNewsletter($event)" (notifyError)="notify($event)"></tgw-footer>
  `,
  styleUrls: ['./layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LayoutComponent {
  headerType$: Observable<string> = this.router.events.pipe(
    filter((event) => event instanceof NavigationEnd),
    map(() => collectRouteData(this.route.snapshot).header)
  );
  headerMenu$ = this.store.pipe(select(fromLayout.getHeader));
  cart$ = this.store.pipe(select(fromCart.getCart));
  businnesType = this._coreConfig.businessType;
  brand =  this._coreConfig.brand;
  user$: Observable<string> = this.store.pipe(select(fromAuth.getUsername));
  header$: Observable<{ menu: MenuItem[], cart: CartItem[] }>;
  footer$: Observable<{ menus: Footer, newsletterPending: boolean }> = combineLatest([
    this.store.pipe(select(fromLayout.getFooter)),
    this.store.pipe(select(fromLayout.getNewsletterPending))
  ]).pipe(
    map(([menus, newsletterPending]) => ({ menus, newsletterPending })),
  );

  isBrandRao: boolean = this._coreConfig.brand === "rao"; 

  showBanner$: Observable<boolean>;

  lang$ = this.translate.onLangChange.pipe(
    map(({ lang }) => lang),
    startWith(this.translate.currentLang),
    filter(Boolean),
    shareReplay(1)
  );
  scrolled = false;
  notRoot$ = this.store.pipe(
    select(fromRouter.selectUrl),
    map(url => url !== '/')
  );

  constructor(
    private _coreConfig: CoreConfigService,
    private translate: TranslateService,
    private store: Store,
    public router: Router,
    private route: ActivatedRoute
  ) {
  
    if (!localStorage.getItem(NEW_CUSTOMER_COUPON_MODAL_SHOWN_KEY)) {
      this.store.dispatch(CouponsActions.loadFeaturedCoupon());
      this.showBanner$ =
        this.store.pipe(
          select(fromCoupons.getFeaturedCoupon),
          switchMap((coupon) => coupon ? combineLatest([
            this.store.pipe(select(fromAuth.isLoggedIn)),
            this.store.pipe(select(fromRouter.selectUrl)),
            fromEvent(window, 'storage').pipe(
              filter(({ key }: StorageEvent) => key === NEW_CUSTOMER_COUPON_MODAL_SHOWN_KEY),
              map(({ newValue }) => newValue),
              startWith(localStorage.getItem(NEW_CUSTOMER_COUPON_MODAL_SHOWN_KEY)),
            )
          ]).pipe(
            map(([isLoggedIn, url, shown]) => !isLoggedIn && !/mi-cuenta|compra/.test(url) && !shown)
          ) : EMPTY
          )
        );
    }
  }

  changeLanguage(lang: string): void {
    if (lang !== this.translate.defaultLang) {
      this.router.navigate([`/${lang}${this.router.url.replace(`/${this.translate.currentLang}`, '')}`]);
    } else {
      this.router.navigate([this.router.url.replace(`/${this.translate.currentLang}`, '')]);
    }
  }

  subscribeNewsletter(email: string, selection: string): void {
    this.store.dispatch(LayoutActions.subscribeNewsletter({ email, selection:[selection] }));
  }

  notify(message: string): void {
    this.store.dispatch(ErrorHandlerActions.notifyUser({ message }));
  }

  askRemoveConfirm(item: CartItem): void {
    this.store.dispatch(CartActions.askRemoveConfirm({ item }));
  }

  logout(): void {
    this.store.dispatch(AuthActions.logout());
  }

}
