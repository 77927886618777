import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'tgw-maintenance-page',
  template: `
    <header>
      <div class="container-fluid">
        <mat-toolbar>         
          <mat-toolbar-row class="header-intro">
            <div>
              <a>
                <span inlineSVG="assets/icons/maintenance-logo.svg" alt="The Grand Wines"></span>
              </a>
            </div>
          </mat-toolbar-row>
        </mat-toolbar>
      </div>
    </header>
    <div class="maintenance-wrapper">
      <h1>{{title}}</h1>
      <h2>{{message}}</h2>
    </div>
  `,
  styleUrls: ['./maintenance-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MaintenancePageComponent {
  public title = '';
  public message = '';

  constructor(translate: TranslateService) {
    const { title, message } = {
      es: {
        title: 'Página en mantenimiento',
        message: 'Estamos actualizando nuestra web. Vuelva a intentarlo en unos minutos. Disculpe las molestias.'
      },
      en: {
        title: 'Page in maintenance.',
        message: 'We are updating our website. Try again in a few minutes. Sorry for the inconvenience.'
      },
      eu: {
        title: 'Página en mantenimiento',
        message: 'Estamos actualizando nuestra web. Vuelva a intentarlo en unos minutos. Disculpe las molestias.'
      }
    }[translate.currentLang || translate.defaultLang];

    this.title = title;
    this.message = message;
  }

}
