import { createReducer, on } from '@ngrx/store';
import { ExperiencesActions } from '../actions';
import { Experience, ProductCategory } from '../models/index';

export const experiencesKey = 'experiences';

export interface State {
  experiencesPages?: {
    [id: number]: {
      [page: number]: Experience[],
      [post: string]: Experience[]
    }
  };
  currentPage?: number;
  totalPages?: number;
  pending?: boolean;
  error?: boolean;
  categories?: ProductCategory[];
  experiencesPagesSearch?: { [search: string]: Experience[] };

}

const initialState: State = {};

const experiencesReducer = createReducer(
  initialState,
  on(ExperiencesActions.loadExperiences, (state) => ({ ...state, pending: true, error: false })),
  on(ExperiencesActions.loadExperiencesFailure, (state) => ({ ...state, pending: false, error: true })),
  on(ExperiencesActions.saveExperiences, (state, { experiences, totalPages, page, id, postId }) =>
  ({
    ...state, experiencesPages: {
      ...(state.experiencesPages || {}),
      [id]: {
        ...(state.experiencesPages?.[id] || { experiences }),
        [page]: experiences,
        [postId]: experiences
      }
    },
    totalPages,
    currentPage: page,
    pending: false,
    error: false
  })),
  on(ExperiencesActions.loadExperiencesCategories, (state) => ({ ...state })),
  on(ExperiencesActions.saveExperiencesCategories, (state, { categories }) => ({ ...state, categories: categories })),
  on(ExperiencesActions.loadExperiencesSearch, (state) => ({ ...state, pending: true, error: false })),
  on(ExperiencesActions.saveExperiencesSearch, (state, { experiences, search }) => ({ ...state, experiencesPagesSearch: { ...(state.experiencesPagesSearch || {}), [search]: experiences }, pending: false, error: false })),
);

export function reducer(state: State | undefined, action: ExperiencesActions.ExperiencesActionsUnion) {
  return experiencesReducer(state, action);
}

export const getPending = (state: State) => state?.pending;

export const getExperiencesPending = (state: State) => state?.pending;

export const getExperiences = (state: State) => state?.experiencesPages;

export const getTotalPages = (state: State) => state?.totalPages;

export const getCurrentPage = (state: State) => state?.currentPage;

export const hasError = (state: State) => state?.error;

export const getExperienceSearch = (state: State) => state?.experiencesPagesSearch;

export const getExperienceCategories = (state: State) => state?.categories;

