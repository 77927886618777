import { isArray } from "util";
import { Product } from "./models";

export function getProductMetadata(product: Partial<Product>, metaKey: string): string | string[] | number {
  // No product, product without meta_data or non array meta_data
  if (!isArray(product?.meta_data)) {
    return null;
  }
  const data = product?.meta_data.find(({ key }) => key === metaKey)?.value;
  if (['original_price', 'public_price'].includes(metaKey)) {
    return data.replace(',', '.');
  }

  return data;
}