import { createAction, props, union } from '@ngrx/store';
import { Experience, ProductCategory } from '../models/index';

export const loadExperiences = createAction('[Experiences] Load experiences', props<{ page: string, id?: number, postId?:string }>());
export const saveExperiences = createAction('[Experiences] Save experiences', props<{ page: number, experiences: Experience[], totalPages: number, id?: number, postId?:string }>());
export const loadExperiencesCategories = createAction('[Experiences] Load experiences Categories ', props<{}>());
export const saveExperiencesCategories = createAction('[Experiences] Save experiences Categories', props<{ categories: ProductCategory[]}>());
export const loadExperiencesSearch = createAction('[Experiences] Load experiences', props<{ search: string}>());
export const saveExperiencesSearch = createAction('[Experiences] Save experiences', props<{ search: string, experiences: Experience[]}>());

export const loadExperiencesFailure = createAction('[Experiences] Load experiences failure');


const all = union({
  loadExperiences,
  saveExperiences,
  loadExperiencesCategories,
  saveExperiencesCategories
});

export type ExperiencesActionsUnion = typeof all;
