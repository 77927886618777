import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { CoreConfigService, fromWinery, ProductsService, WineryActions } from '@tgw-clients/shared';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Component({
  selector: 'tgw-header-submenu',
  template: `
    <ng-container *ngIf="(subItems$ | async) as data">
      <div *ngIf="data?.products as subItems">

        <div *ngIf="isB2cBusinessType">
          <ng-container *ngFor="let subItem of subItems| sort: 'description';let i = index ">
            <p class="sub-submenu" *ngIf="i <= 6 && subItem?.count > 0 || (subItem?.description && subItem?.slug != 'todos')" style="cursor: pointer; font-weight: normal" [routerLink]="['tienda/1']" (click)="sendQueryParams((urlFilter?urlFilter:slug) ,subItem?.name ,subItem?.description)"
              [class.active]="isActive(slug, subItem?.name)" [innerHTML]="subItem?.name" ></p>
              <button class="btn-edit" *ngIf="subItem?.slug == 'todos'" [disabled]="subItem?.slug != 'todos'" [routerLink]="['tienda/1' ]" (click)="sendQueryParamsButton(slug)" >
          {{subItem?.name}} <img src="assets/icons/arrow_menu.svg"></button>
            </ng-container>
        </div>

        <div *ngIf="!isB2cBusinessType">
          <ng-container *ngFor="let subItem of subItems">
            <p class="sub-submenu" *ngIf="subItem?.count > 0 || subItem?.description" style="cursor: pointer; font-weight: normal" [routerLink]="['tienda/1']" (click)="sendQueryParams(slug ,subItem?.name,subItem?.descriptio )"
              [class.active]="isActive(slug, subItem?.name)" [innerHTML]="subItem?.name" ></p>
          </ng-container>
        </div>

        <!-- <button class="btn-edit" *ngIf="data?.totalPages !== 1" [disabled]="data?.totalPages === 1" [routerLink]="['tienda/1' ]" (click)="sendQueryParamsButton(slug)" >
          {{data[data.length-1]?.name}} <img src="assets/icons/arrow_menu.svg"></button> -->
      </div>

      <!-- <div class="" *ngIf=" data?.subItems as subItems" >
        <div *ngIf="isB2cBusinessType">
          <p class="sub-submenu" style="cursor: pointer; font-weight: normal" *ngFor="let subItem of subItems.slice(0, 6)" [routerLink]="['tienda/1']" (click)="sendQueryParams('winery' ,subItem?.id )" [class.active]="isActive('winery', subItem?.name)" [innerHTML]="subItem?.title?.rendered" ></p>
          <button class="btn-edit" *ngIf="subItems?.length <= 15 " [routerLink]="['tienda/1']" (click)="sendQueryParamsButton('winery')">{{'tgw-commons-view-more' | translate}} <img src="assets/icons/arrow_menu.svg"></button>
        </div>
          <div *ngIf="!isB2cBusinessType">
          <p class="sub-submenu" style="cursor: pointer; font-weight: normal" *ngFor="let subItem of subItems" [routerLink]="['tienda/1']" (click)="sendQueryParams('winery' ,subItem?.id )" [class.active]="isActive('winery', subItem?.name)" [innerHTML]="subItem?.title?.rendered" ></p>
        <button class="btn-edit" *ngIf="subItems?.length <= 15 " [routerLink]="['tienda/1']" (click)="sendQueryParamsButton('winery')">{{'tgw-commons-view-more' | translate}}</button>
        </div>
      </div> -->
    </ng-container>
  `,
  styleUrls: ['./header-submenu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderSubmenuComponent implements OnInit {
  @Input() show: boolean;
  @Input() menuId: string;
  @Input() slug: string
  @Input() urlFilter: string
  @Output() hideSubmenuOnClick = new EventEmitter();
  totalSubItem = 100;
  quantityitems: number = this.totalSubItem;
  subItems$: Observable<any>;
  id: string;
  idSubItem: string
  active = 0;
  isB2cBusinessType: boolean = this._coreConfig.businessType === 'b2c';
  businessType = this._coreConfig.businessType;

  constructor(private store: Store, private _products: ProductsService, private router: Router, private route: ActivatedRoute, private _coreConfig: CoreConfigService) {

  }

  ngOnInit(): void {
    this.id = this.menuId;
    if (this.id) {
      this.subItems$ = this._products.getMenuSubItems(parseInt(this.id, 10), this.quantityitems);
    } else {
      this.id = 'winery';
      this.store.dispatch(WineryActions.loadWineries({ newPage: 1, perPage: this.totalSubItem }));
      this.subItems$ = this.store.pipe(select(fromWinery.getWineryPages)).pipe(map((data) => ({ subItems: data?.[1]?.slice(0, 15) })))
    }
  }

  isActive(name: string, value): boolean {  //class activ
    return this.route.snapshot.queryParams[name]?.includes(value)
  }

  sendQueryParams(slug: string, idSubItem: string, description:string): void {
    // Swap spaces for hyphens for SEO
    if (typeof slug === "string") slug = slug.replace(/ /g, "-");
    if (typeof idSubItem === "string") idSubItem = idSubItem.replace(/ /g, "-");
    this.hideSubmenuOnClick.next();
    const params =  description.split(";");
    if(description && params?.length > 1){
      let paramQuery;
      params[1].split(',').forEach(parm =>{
        paramQuery = Object.assign({}, paramQuery, { [parm.split('=')[0]]: parm.split('=')[1] });
      })
      this.router.navigate(['/tienda/1'], { queryParams: paramQuery })
    }else{
      this.router.navigate(['/tienda/1'], { queryParams: { [slug]: idSubItem } })
    }
  }

  sendQueryParamsButton(slug: string): void {
    this.hideSubmenuOnClick.next();
    if (typeof slug === "string") slug = slug.replace(/ /g, "-");
    this.router.navigate(['/tienda/1'], { queryParams: { [slug]: 'all' } })
  }
}
