import { NgModule } from '@angular/core';
import { RemoteStatusModule } from '@tgw-clients/shared';
import { SharedModule } from '../shared';
import { MaintenancePageComponent } from './components/maintenance-page.component';


@NgModule({
  declarations: [MaintenancePageComponent],
  imports: [
    SharedModule,
    RemoteStatusModule.forRoot()
  ],
  exports: [MaintenancePageComponent],
})
export class RemoteStatusWebModule { }
