import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { MenuItem } from '@tgw-clients/shared';

@Component({
  selector: 'collections-submenu',
  templateUrl: './collections-submenu.component.html',
  styleUrls: ['./collections-submenu.component.scss']
})
export class CollectionsSubmenuComponent implements OnInit {

  @Input() items: MenuItem[];
  @Input() mobile: boolean = false;
  @Output() hideMenu = new EventEmitter<boolean>(); 

  constructor(private router: Router) {
  }

  ngOnInit(): void {
  }

  goTo(url: string){
    this.router.navigateByUrl(url)
    this.hideMenu.next(true)
  }
  
  goToTest(){
    window.open('https://n2yy9yfc508.typeform.com/to/AONsK9H1', "_blank");
  }

}
