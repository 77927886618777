import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY, forkJoin } from 'rxjs';
import { catchError, map, mapTo, mergeMap, switchMap } from 'rxjs/operators';
import { LayoutActions } from '../actions';
import { LayoutService } from './../services/layout.service';

@Injectable()
export class LayoutEffects {

  loadLayout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LayoutActions.loadLayout),
      switchMap(() =>
        forkJoin([
          this._layout.getHeaderMenu(),
          this._layout.getFooter()
        ]).pipe(
          map(([header, footer]) => LayoutActions.loadLayoutSuccess({ header, footer }))
        )
      )
    )
  );

  loadMenu$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LayoutActions.loadMenu),
      switchMap(({ name }) =>
        this._layout.getMenu(name).pipe(
          map((menu) => LayoutActions.loadMenuSuccess({ name, menu }))
        )
      )
    )
  );

  subscribeNewsletter$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LayoutActions.subscribeNewsletter),
      switchMap(({ email, selection, status }) =>
        this._layout.subscribeNewsletter(email, selection, status).pipe(
          mapTo(LayoutActions.subscribeNewsletterSuccess()),
          catchError((error) => {
            return [LayoutActions.subscribeNewsletterFailure({ error })];
          }),
        )
      ),
    )
  );

  subscribeB2c$ = createEffect(() =>
  this.actions$.pipe(
    ofType(LayoutActions.subscribeB2c),
    switchMap(({ email, nameRest,province,name,telefon }) =>
      this._layout.subscribeB2c( email, nameRest,province,name,telefon).pipe(
        mapTo(LayoutActions.notifySubscribeB2cSuccess()),
        catchError((error) => {
          return [LayoutActions.notifySubscribeB2cError({ error })];
        }),
      )
    ),
  )
);

  loadCarousel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LayoutActions.loadCarousel),
      mergeMap(({ name }) =>
        this._layout.getCarousel(name).pipe(
          map((carousel) => LayoutActions.saveCarousel({ name, carousel })),
          catchError((error) => {
            console.error(error);
            return EMPTY;
          })
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private _layout: LayoutService
  ) { }
}
