import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { CouponsActions } from '../actions';
import { CouponsService } from '../services/coupons.service';


@Injectable()
export class CouponsEffects {

  loadFeaturedCoupon$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CouponsActions.loadFeaturedCoupon),
      switchMap(() =>
        this._coupons.getFeaturedCoupons().pipe(
          map((coupon) => CouponsActions.loadFeaturedCouponSuccess({ coupon })),
          catchError((error) => [CouponsActions.loadFeaturedCouponFailure({ error })])
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private _coupons: CouponsService
  ) { }
}
