import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router} from '@angular/router';
import { AuthService, Environment, ENVIRONMENT } from "@tgw-clients/shared";
import { sha256 } from 'js-sha256';
import { concatMap, flatMap, switchMap } from 'rxjs/operators';

interface Data {
  service: 'facebook';
  payload: any;
}
@Injectable({ providedIn: 'root' })
export class TrackerService {

  constructor (private http: HttpClient, private router: Router, private authService: AuthService, @Inject(ENVIRONMENT) public env: Environment) { }

  sendFacebookPixel(payload, ip) {
    const time = Math.round(new Date().getTime()/1000);
    const eventId = "661211371262042";
    const eventSourceUrl = window.location.origin+this.router.url;
    const userAgent = window.navigator.userAgent;
    const email = sha256(payload.email.toLowerCase());
    const firstName = sha256(payload.firstName.toLowerCase()).toString();
    const lastName = sha256(payload.lastName.toLowerCase()).toString();

    const access_token = this.env.access_token_facebook;

    const headers = {};
    const body = {
        "data": [
          {
            "event_id": eventId,
            "event_name": payload.eventName,
            "event_time": time,
            "event_source_url": eventSourceUrl,
            "action_source": "website",
            "user_data": {
              "em": email,
              "fn": firstName,
              "ln": lastName,
              "client_ip_address": ip,
              "client_user_agent": userAgent
            },
            "custom_data": {
              "content_ids": payload.contentId,
              "content_name": payload.contentName,
              "value": payload.price,
              "currency": "EUR",
              "content_type": payload.contentType
            },
            "contents": {
              "id": payload.productId,
              "quantity": payload.quantity,
            },
          }
        ]
      }
    return this.http.post(`https://graph.facebook.com/v11.0/661211371262042/events?access_token=${access_token}`, body, { headers });
  }

  switchInfo({service, payload, ip}):any {
    switch (service) {
      case 'facebook':
        return this.sendFacebookPixel(payload, ip)
      default:
        return Error('Not identify tracker');
    }
  }

  sendData({service, payload}:Data):any {
    return this.getIPAddress().pipe(
      concatMap((data:any) => this.switchInfo({ service, payload, ip: data.ip }))
    )
  }

  getIPAddress() {
    return this.http.get("https://api.ipify.org/?format=json");
  }
  
}
