import { createAction, props, union } from '@ngrx/store';
import { Order } from '@tgw-clients/shared';
import { CartItem, StorageCartItem } from '../models/cart.model';

export const loadCartItems = createAction('[Cart] Load cart', props<{ items: StorageCartItem[] }>());
export const addToCart = createAction('[Cart] Add to cart', props<CartItem>());

export const addToCartByUrl = createAction('[Cart] Add to cart by url', props<{ items: StorageCartItem[] }>());
export const addToCartByUrlFailure = createAction('[Cart] Add to cart by url failue', props<{ message: string }>());

export const addToCartMultiple = createAction('[Cart] Load cart multiple', props<{ items: CartItem[] }>());
export const addToCartrepeatOrder = createAction('[Cart] Load cart multiple repeat order', props<{ items: CartItem[], order:number }>());
export const showModalWithoutProducts = createAction('[Cart] Show modal without product', props<{ cartItems: any, order: Order }>());
export const askRemoveConfirm = createAction('[Cart] Ask remove confirm', props<{ item: CartItem }>());
export const removeProductFromCart = createAction('[Cart] Remove product from cart', props<{ item: CartItem }>());
export const updateItemQuantity = createAction('[Cart] Update item quantity', props<CartItem>());
export const clearCart = createAction('[Cart] Clear cart');

const all = union({
  loadCartItems,
  addToCart,
  addToCartByUrl,
  addToCartByUrlFailure,
  addToCartMultiple,
  addToCartrepeatOrder,
  showModalWithoutProducts,
  askRemoveConfirm,
  removeProductFromCart,
  updateItemQuantity,
  clearCart
});

export type CartActionsUnion = typeof all;
