import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromWinery from './winery.reducer';

export const wineryKey = 'winery';

export interface State {
  [wineryKey]: fromWinery.State
};

export const reducer = fromWinery.reducer;

export const getWineryState = createFeatureSelector<State, fromWinery.State>(wineryKey);

export const getWineryById = (id: string) => createSelector(
  getWineryState,
  (wineries) => wineries && wineries[+id]
);

export const getWineries = createSelector(
  getWineryState,
  fromWinery.getWineries
);

export const getWineryPages = createSelector(
  getWineryState,
  fromWinery.getWineryPages
);

export const getWineryBySlug = (winerySlug: string) => createSelector(
  getWineries,
  getWineryPages,
  (wineries, wineryPages) => {
    const allWineries = Object.keys(wineries || {}).reduce((acc, id) => [...acc, wineries[id]], [])
      .concat(Object.keys(wineryPages || {}).reduce((acc, page) => [...acc, ...wineryPages[page]], []));    
    return allWineries.find(({ slug }) => slug === winerySlug);
  }
);

export const getTotalPages = createSelector(
  getWineryState,
  fromWinery.getTotalPages
);

export const getTotalWineries = createSelector(
  getWineryState,
  fromWinery.getTotalWineries
);


export const getProducts = createSelector(
  getWineryState,
  fromWinery.getProducts
);
