import { createAction, props, union } from '@ngrx/store';
import { MenuItems, Product } from '@tgw-clients/shared';
import { Order } from '../../auth';
import { Term } from '../models';


export const loadProductsPage = createAction('[Products] Load products page', props<{ page: string; queryParamsData?: any; queryParamsSearch?: string }>());
export const reloadCurrentProductsPage = createAction('[Products] Reload current products page');
export const saveProductsPage = createAction('[Products] Save products page', props<{ page: number, products: Product[], totalPages: number, attributeCounts: Term[], found: number, max_price: number, min_price: number, products_ids: number[]; error?: boolean}>());
export const updateCurrentPage = createAction('[Products] Update current page', props<{ page: number }>());

export const loadBestSellerProducts = createAction('[Products] Load featured products');
export const saveFeaturedProducts = createAction('[Products] Save featured products', props<{ products: Product[] }>());

export const clearProductPages = createAction('[Products] Clear product pages');

export const loadMenuItems = createAction('[Shop] Load Menu Items');
export const saveMenuItems = createAction('[Shop] Save Menu Items', props<{ item: MenuItems[] }>());

export const loadProductsImage = createAction('[Shop] Load products image', props<{ productsId: string[] }>());
export const saveProductsImage = createAction('[Shop] Save products image', props<{ products: Product[] }>());


export const loadMonthProducts = createAction('[Products] Load month products');
export const saveMonthProducts = createAction('[Products] Save month products', props<{ products: Product[] }>());

export const loadProductsByOrder = createAction('[Products] Load products by order', props<{ ids: number[], order: Order }>());

const all = union({
  loadProductsPage,
  saveProductsPage,
  updateCurrentPage,
  loadBestSellerProducts,
  saveFeaturedProducts,
  clearProductPages,
  reloadCurrentProductsPage,
  loadMonthProducts,
  saveMonthProducts
});

export type ProductsActionsUnion = typeof all;

