import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { TranslateModule } from '@ngx-translate/core';
import { NgcCookieConsentModule } from 'ngx-cookieconsent';
import { CookiesEffects } from './effects/cookies.effects';
import { cookieConfig } from './models/cookies.models';

@NgModule({
  declarations: [],
  imports: [
    NgcCookieConsentModule.forRoot(cookieConfig),
    EffectsModule.forFeature([CookiesEffects]),
    TranslateModule.forChild()
  ]
})
export class CookiesModule {
  constructor() { }
}
