
export const brandProdUrl = {
  'tgw': 'https://wp-hosteleria.thegrandwines.com/wp-json/',
  'rao': 'https://hosteleria.raoriginal.eus/wp-json/'
};

export const brandPreUrl = {
  'tgw': 'https://wp-hosteleria.tgw-test.com/wp-json/',
  'rao': 'https://hosteleria.rioja-alavesa.tgw-test.com/wp-json/'
};

export const brandDevUrl = {
  'tgw': 'https://wp-hosteleria.thegrandwines.com/wp-json/',
  'rao': 'https://wp-hosteleria.tgw.staging.nazaries.cloud/wp-json/'
};

export const brandStageUrl = {
  'tgw': 'https://wp-hosteleria.tgw.staging.nazaries.cloud/wp-json/',
  'rao': 'https://wp-hosteleria.tgw.staging.nazaries.cloud/wp-json/'
};

export const getBrandProdUrl = (name: string) => {
  return brandProdUrl[name];

}

export const getBrandPreUrl = (name: string) => {
  return brandPreUrl[name];

}

export const getBrandDevUrl = (name: string) => {
  return brandDevUrl[name];

}

export const getBrandStageUrl = (name: string) => {
  return brandStageUrl[name];

}

export const parameters = {
  baseProd: {
    tgw: 'https://wp-hosteleria.thegrandwines.com/wp-json/',
    rao: 'https://hosteleria.raoriginal.eus/wp-json/'
  },
  basePre: {
    tgw: 'https://wp-hosteleria.tgw-test.com/wp-json/',
    rao: 'https://hosteleria.rioja-alavesa.tgw-test.com/wp-json/'
  },
  baseDev: {
    tgw: 'https://wp-hosteleria.tgw.staging.nazaries.cloud/wp-json/',
    rao: 'https://wp-hosteleria.tgw.staging.nazaries.cloud/wp-json/'
  }
  ,
  baseStage: {
    tgw: 'https://wp-hosteleria.tgw.staging.nazaries.cloud//wp-json/',
    rao: 'https://wp-hosteleria.tgw.staging.nazaries.cloud/wp-json/'
  }
};

