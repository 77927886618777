import { createReducer, on } from '@ngrx/store';
import { RemoteStatusActions } from '../actions';

export interface State {
  underMaintenance: boolean;
}

const initialState: State = {
  underMaintenance: false
};

const remoteStatusReducer = createReducer(
  initialState,
  on(RemoteStatusActions.setUnderMaintenance, (state, { underMaintenance }) => state.underMaintenance !== underMaintenance ? { ...state, underMaintenance } : state),

);

export function reducer(state: State | undefined, action: RemoteStatusActions.RemoteStatusActionsUnion) {
  return remoteStatusReducer(state, action);
}

export const isUnderMaintenance = (state: State) => state?.underMaintenance;

