import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Product, CoreConfigService } from '@tgw-clients/shared';

@Component({
  selector: 'tgw-product-added-dialog',
  template: `
  <div class="container">
    <div class="row">
      <div class="col-3 img-column">
        <img [class]="suscription ? 'collection-img-added' : 'wine-img-added' " [src]="image"/>
      </div>
      <div class="col-8 text-column">
        <span class="cart-title" [ngClass]="'tgw'== brand ? 'tgw' : ''">{{'Producto'+add+'añadido al carrito' | translate}}</span>
        <span [innerHTML]="name + (date ? (' - ' + date) : '')"></span>
      </div>
      <div class="col-1">
        <span mat-dialog-close class="icon-close" inlineSVG="assets/icons/close.svg"></span>
      </div>
    </div>
  </div>
  `,
  styleUrls: ['./product-added-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductAddedDialogComponent {
  image: string;
  suscription : boolean;
  name: string;
  showMessageProvinces: boolean;
  showMessagePostalCode: boolean;
  public add: string
  brand =  this._coreConfig.brand;
  date: string;

  constructor(@Inject(MAT_DIALOG_DATA) data: { product: Product; showMessageProvinces: boolean, showMessagePostalCode?:boolean,  date?: string, add?: string }, private _coreConfig: CoreConfigService) {
    this.image = data.product?.ACF?.logo ? data.product?.ACF?.logo : data.product.images[0]?.src;
    this.name = data.product.name;
    this.showMessageProvinces = data.showMessageProvinces;
    this.showMessagePostalCode = data.showMessagePostalCode;
    this.date = data.date;
    this.add = data.add;
    this.suscription = data.product?.ACF?.logo != undefined;
  }

}
