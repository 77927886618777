import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { TranslateService } from '@ngx-translate/core';
import { CouponAPI } from '@tgw-clients/shared/coupons';
import { BehaviorSubject, Observable, of, Subject, throwError } from 'rxjs';
import { catchError, exhaustMap, filter, map, mapTo, retry, switchMap, tap } from 'rxjs/operators';
import { PaymentMethod } from '../../auth';
import { CartItem } from '../../cart';
import { ShippingZoneMethod } from '../../utils';
import { CoreConfigService, EndpointType } from '../../utils/services/core-config.service';
import {
  AccountInfo, BillingInfo, Credentials, CustomerApiResponse, Delivery, DetailPlanPaypal, LoginApiResponse, Order, OrderAPI, OrdersResponse, Payment, RegisterData, RegisterPaypal, RegisterShort, ShippingAddress, User, WarehouseProduct
} from '../models/auth.model';

@Injectable({ providedIn: 'root' })
export class AuthService {
  public loginUrl = `${this._coreConfig.getEndpoint(EndpointType.Auth)}login`;
  public refresUrl = `${this._coreConfig.getEndpoint(EndpointType.Auth)}refresh`;
  public paypalUrl = `${this._coreConfig.getPaypalLink()}`;
  private _token: string;
  private _refreshToken: string;
  private readonly TOKEN_LABEL = 'API_TOKEN';
  private readonly REFRESH_TOKEN_LABEL = 'API_REFRESH_TOKEN';

  constructor(private _coreConfig: CoreConfigService, private http: HttpClient, private translate: TranslateService,
    ) { }

  simpleLogin(username: string, password: string): Observable<LoginApiResponse> {
    return this.http.post<LoginApiResponse>(this.loginUrl, { username, password });
  }

  login(username: string, password: string): Observable<User> {
    this.loginUrl = `${this._coreConfig.getEndpoint(EndpointType.Auth)}login`; //Is it necessary?
    return this.simpleLogin(username, password).pipe(
      tap(({ access_token, refresh_token }: LoginApiResponse) => {
        if (access_token && refresh_token) {
          localStorage.setItem(this.TOKEN_LABEL, this._token = access_token);
          localStorage.setItem(this.REFRESH_TOKEN_LABEL, this._refreshToken = refresh_token);
        }

      }),
      switchMap(() => this.getUserInfo())
    )
  }

  loginPushDevice(username: string): Observable<User> {
    const body = {
      email: username,
    }
    console.log("[LOGIN] ", "body: " + JSON.stringify(body));
    return this.http.post(`${this._coreConfig.getEndpoint(EndpointType.Custom)}save-token-device`, body)
  }

  refresh(): Observable<User> {
    this.refresUrl = `${this._coreConfig.getEndpoint(EndpointType.Auth)}refresh`;
    const refreshToken = this._refreshToken || localStorage.getItem(this.REFRESH_TOKEN_LABEL);
    if (!refreshToken) {
      return throwError({ message: 'Refresh token not found' });
    }
    return this.http.post(this.refresUrl, { token: refreshToken }).pipe(
      tap(({ access_token }: LoginApiResponse) => {
        if (access_token) {
          localStorage.setItem(this.TOKEN_LABEL, this._token = access_token);
        }
      }),
      catchError((error) => {
        console.error(error);
        return throwError({ message: 'Invalid refresh token' });
      }),
      switchMap(() => this.getUserInfo()),
    );
  }

  logout(): void {
    localStorage.removeItem(this.TOKEN_LABEL);
    localStorage.removeItem(this.REFRESH_TOKEN_LABEL);
    localStorage.removeItem('customer');
    this._token = this._refreshToken = null;
  }

  register(data: RegisterData): Observable<any> {
    const { email, first_name, last_name, password, company, address_1, city, country, state, postcode, phone, id_document_number, iban, subscribed_to_newsletter, phone2, birthDate, gender, business_name } = data;
    const body = {
      email,
      first_name,
      last_name,
      password,
      billing: { first_name, last_name, company, address_1, city, country, state: state || '', postcode, phone, email },
      meta_data: [
        { key: 'id_document_type', value: 1 },
        { key: 'id_document_number', value: id_document_number },
        { key: 'iban', value: iban },
        { key: 'subscribed_to_newsletter', value: subscribed_to_newsletter },
        { key: 'phone2', value: phone2 },
        { key: 'gender', value: gender },
        { key: 'dob', value: birthDate },
        { key: 'business_name', value: business_name }
      ]
    };
    return this.http.post(`${this._coreConfig.getEndpoint(EndpointType.Woocommerce)}customers/`, body);
  }

  autologin(): Observable<User> {
    this._token = localStorage.getItem(this.TOKEN_LABEL);
    if (!this._token) {
      return throwError({ message: 'Token not found' });
    }
    return this.getUserInfo();
  }

  restorePassword(userOrEmail: string): Observable<any> {
   let body = new HttpParams()
      .append('email', userOrEmail)

    if (this.translate.currentLang !== this.translate.defaultLang) {
      body = body.append('lang', this.translate.currentLang);
    }

    return this.http.post(`${this._coreConfig.getEndpoint(EndpointType.Custom)}restore-password/`, body);
  }

  setNewPassword(hash: string, password: string): Observable<any> {
    const params = new HttpParams()
      .append('hash', hash)
      .append('password', password);
    return this.http.post(`${this._coreConfig.getEndpoint(EndpointType.Custom)}restore-password-hash/`, params);
  }

  getUserInfo(): Observable<User> {
    let localCustomer = JSON.parse(localStorage.getItem('customer'))
    if(localCustomer && Capacitor.getPlatform() !== 'web'){
      return new Observable(temp => {
        setTimeout(() => {
          temp.next(localCustomer)
        }, 0);
      })
    }

    return this.http.get<CustomerApiResponse>(`${this._coreConfig.getEndpoint(EndpointType.Custom)}customer/`, { headers: this.getHeaders() }).pipe(
      retry(1),
      // exhaustMap(({ billing, shipping, meta_data, ...user }) =>
      //   this.getUserRappel(user.id).pipe(

      map(({ billing, shipping, meta_data, ...user }) => {
        const fields = ['id_document_number', 'iban', 'phone2', 'gender', 'dob', 'rao_club', 'substatus', 'business_name'];
        const metas = meta_data.reduce((acc, meta) => {
          if (fields.includes(meta.key)) {
            acc[meta.key] = meta.value;
          }
          return acc;
        }, <{ iban: string, id_document_number: string, rao_club: string, [key: string]: string }>{});
        let res = { billingAddress: billing, shippingAddresses: shipping, rao_club: metas.rao_club, ...user, ...metas, rappel: 0, substatus: metas.substatus}
        localStorage.setItem('customer', JSON.stringify(res))
        return res;
      })
      /* )
    ) */
    );
  }


  deleteUserShippingAddress({ id }): Observable<any> {
    return this.http.delete(`${this._coreConfig.getEndpoint(EndpointType.Custom)}address/${id}/`, { headers: this.getHeaders() });
  }

  updateUserShippingAddress({ id, ...shippingAddress }: ShippingAddress): Observable<any> {
    return this.http.put(`${this._coreConfig.getEndpoint(EndpointType.Custom)}address/${id}/`, shippingAddress, { headers: this.getHeaders() });
  }

  createUserShippingAddress(shippingAddress: ShippingAddress): Observable<any> {
    return this.http.post(`${this._coreConfig.getEndpoint(EndpointType.Custom)}address/`, { ...shippingAddress, state_slug: shippingAddress.state.toLowerCase() }, { headers: this.getHeaders() });
  }

  getUserShippingAddresses(): Observable<ShippingAddress[]> {
    return this.http.get<ShippingAddress[]>(`${this._coreConfig.getEndpoint(EndpointType.Custom)}address/`, { headers: this.getHeaders() });
  }

  updateUserBillingAddress({ id, nid, ...billing }: BillingInfo): Observable<Partial<User>> {
    const body = {
      billing,
      meta_data: [
        {
          key: "id_document_number",
          value: nid
        }
      ]
    };
    return this.http.put<CustomerApiResponse>(`${this._coreConfig.getEndpoint(EndpointType.Woocommerce)}customers/${id}/`, body, { headers: this.getHeaders() }).pipe(
      map(({ billing: billingAddress, meta_data }) => {
        const id_document_number = meta_data.find(({ key }) => key === 'id_document_number')?.value;
        return { id_document_number, billingAddress };
      })
    );
  }

  updateUserAccountInfo({ id, first_name: firstName, last_name: lastName, email: login, currentPassword, newPassword, iban, phone2, gender, dob }: AccountInfo): Observable<Partial<User>> {
    let preValidation = of(true);
    if (currentPassword && newPassword) {
      preValidation = this.simpleLogin(login, currentPassword).pipe(
        mapTo(true),
        catchError(() => throwError('tgw-account-invalid-password'))
      );
    }

    const body = {
      first_name: firstName,
      last_name: lastName,
      password: (currentPassword && newPassword) || undefined,
      meta_data: [
        { key: 'iban', value: iban },
        { key: 'phone2', value: phone2 },
        { key: 'gender', value: gender },
        { key: 'dob', value: dob }
      ]
    };
    return preValidation.pipe(
      filter(Boolean),
      exhaustMap(() =>
        this.http.put<CustomerApiResponse>(`${this._coreConfig.getEndpoint(EndpointType.Woocommerce)}customers/${id}/`, body, { headers: this.getHeaders() }).pipe(
          map(({ first_name, last_name, email, meta_data }) => {
            const fields = ['iban', 'phone2', 'gender', 'dob'];
            const metas = meta_data.reduce((acc, meta) => {
              if (fields.includes(meta.key)) {
                acc[meta.key] = meta.value;
              }
              return acc;
            }, <{ iban: string, [key: string]: string }>{});
            return { first_name, last_name, email, ...metas };
          }),
          catchError(() => throwError('tgw-account-update-error'))
        )
      )
    );
  }

  createOrder(user: Partial<User>, payment: Payment, addressesIds: number[], cart: CartItem[], deliveryInfo: Delivery, shippingMethods: { [id: number]: ShippingZoneMethod }, coupons: CouponAPI[]): Observable<OrderAPI[]> {
    // Destructure params
    const { id, billingAddress, email } = user;
    const { method, useRappel } = payment;
    const { observations, date: deliveryDate } = deliveryInfo;

    // Compose order params
    const billing = { ...billingAddress, email };
    const paymentExtraInfo = PaymentMethod.CommercialTemplate === method ? { status: 'later-payment' } : {};
    const lineItems = cart.map(({ product: { id }, quantity, date, subscription }) => ({
      product_id: subscription ? subscription.frequency == 'mes'? subscription.idCollectionMonth : cart[0]?.product?.ACF.collection_yearly : id,
      quantity,
      ...date ? { meta_data: [{ key: 'Fecha', value: date }] } : {},
      subscription
    }));
    const metadata = [
      { key: 'delivery_date', value: deliveryDate },
      ...useRappel ? [{ key: 'tgw_rappel_use', value: true }] : [],
      { key: 'subscription', value: cart[0]?.subscription }
    ];
    const couponLines = coupons?.map(({ code }) => ({ code })) || [];

    // Build order object
    const order = {
      customer_id: id,
      billing,
      payment_method: method,
      customer_note: observations,
      ...paymentExtraInfo,
      line_items: lineItems,
      meta_data: metadata,
      coupon_lines: couponLines
    };

    // Build address_ids
    const addressIds = addressesIds.reduce((acc, id) => {
      if (!shippingMethods[id] || cart[0]?.subscription) {
        return { ...acc, [id]: null };
      }
      const { method_id, method_title, settings } = shippingMethods[id];
      return { ...acc, [id]: { method_id, method_title, total: (settings?.cost?.value || "0") } };
    }, {})

    // Create body
    const body = {
      order,
      address_ids: addressIds
    };

    return this.http.post<OrdersResponse>(`${this._coreConfig.getEndpoint(EndpointType.Custom)}orders/`, body, { headers: this.getHeaders() }).pipe(
      map(({ orders }) => {
        if (orders.some(({ id }) => id == null)) {
          throw orders.reduce((acc, { id }, idx) => [...acc, ...id == null ? [addressesIds[idx]] : []], []);
        }
        return orders;
      })
    );
  }

  payment(orderIds: number[]): Observable<any> {
    const body = {
      order_ids: orderIds
    };
    return this.http.post(`${this._coreConfig.getEndpoint(EndpointType.Custom)}payment/request/`, body, { headers: this.getHeaders() });
  }

  paypalPayment(orderIds: number[], paypalId: string): Observable<any> {
    const body = {
      order_ids: orderIds,
      paypal_id: paypalId
    };
    return this.http.post(`${this._coreConfig.getEndpoint(EndpointType.Custom)}payment/paypal-request/`, body, { headers: this.getHeaders() });
  }

  approvePayment(amount: string, paypalId: string, token: string, link: string, frequency: string, id_subscription: string, oid: string): Observable<any> {
    const body = {
      amount,
      order_id: paypalId,
      facilitatorAccessToken: token,
      link,
      frequency,
      id_subscription,
      oid
    };
    return this.http.post(`${this._coreConfig.getEndpoint(EndpointType.Custom)}payment/paypal-response/`, body, { headers: this.getHeaders() });
  }

  getOrdersPlace(): Observable<Order[]> {
    return this.http.get<Order[]>(`${this._coreConfig.getEndpoint(EndpointType.Woocommerce)}orders/`, { headers: this.getHeaders() });
  }

  getOrdersPage(page: number, perPage: number): Observable<Order[]> {
    return this.http.get<Order[]>(`${this._coreConfig.getEndpoint(EndpointType.Woocommerce)}orders?page=${page}&per_page=${perPage}`, { headers: this.getHeaders() });
  }

  getOrderById(id: number): Observable<Order> {
    return this.http.get<Order>(`${this._coreConfig.getEndpoint(EndpointType.Woocommerce)}orders/${id}`, { headers: this.getHeaders() });
  }

  getProductsInventoryByIdAddress(id: number): Observable<WarehouseProduct[]> {
    let params = new HttpParams()
      .append('address_id', id.toString());
    if (this.translate.currentLang !== this.translate.defaultLang) {
      params = params.append('lang', this.translate.currentLang);
    }

    return this.http.get<WarehouseProduct[]>(`${this._coreConfig.getEndpoint(EndpointType.Custom)}inventory`, { params, headers: this.getHeaders() });
  }

  deleteWarehouseProduct(productId: number, addressId: number): Observable<void> {
    return this.http.delete<void>(`${this._coreConfig.getEndpoint(EndpointType.Custom)}inventory/${productId}/${addressId}`, { headers: this.getHeaders() }).pipe(
      catchError(() => [<any>{}])
    );
  }

  getUserRappel(id: number): Observable<any> {
    const params = new HttpParams().append('user_id', `${id}`);
    const headers = this.getHeaders();
    return this.http.get<any>(`${this._coreConfig.getEndpoint(EndpointType.Custom)}rappel`, { headers, params });
  }

  downloadWineList(addressId: number): Observable<any> {
    return this.http.get(`${this._coreConfig.getEndpoint(EndpointType.Custom)}inventory/winelist/${addressId}`, {
      headers: this.getHeaders().append('Accept', 'application/pdf'),
      responseType: 'blob'
    });
  }

  registerShort(registerShort: RegisterShort): Observable<any> {
    return this.http.post<HttpResponse<any>>(`${this._coreConfig.getEndpoint(EndpointType.Custom)}new-customer`, registerShort, { observe: 'response' }).pipe(
      map((res) => {
        if (res.status === 204) {
          throw res;
        }
        return res.body;
      })
    );
  }

  simpleLoginPaypal(): Observable<RegisterPaypal> {
    const body = {
      grant_type: 'client_credentials'
    };
    const params = new HttpParams().append('grant_type', 'client_credentials');
    const headers = new HttpHeaders({
      'Accept': 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': 'Basic ' + btoa(this._coreConfig.getPaypalClientId()+':'+this._coreConfig.getPaypalClientIdPass())
    });
    return this.http.post<RegisterPaypal>(this.paypalUrl+EndpointType.paypal, body, { headers, params });
  }

  detailPlanPaypal(tokenPaypal: string, idPlan: string): Observable<DetailPlanPaypal> {
    const params = new HttpParams();
    const headers = new HttpHeaders({
      'Accept': 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': 'Bearer ' + tokenPaypal
    });
    return this.http.get<DetailPlanPaypal>(this.paypalUrl+'/v1/billing/plans/'+idPlan, { headers, params });
  }

  hasToken(): boolean {
    return !!localStorage.getItem(this.TOKEN_LABEL);
  }

  getHeaders(): HttpHeaders {
    if (!this._token) {
      throw 'No token found';
    }
    return new HttpHeaders({
      Authorization: `Bearer ${this._token}`
    });
  }


}
