import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HeaderType, LangGuard } from '@tgw-clients/shared';
import * as appConfig from './../app-config';
const routes: Routes = [
  ...appConfig.Languages.reduce((acc, lang) => {
    if (lang !== appConfig.DefaultLang) {
      return [
        ...acc,
        {
          path: lang,
          canLoad: [LangGuard],
          canActivate: [LangGuard],
          children: [
            {
              path: '',
              loadChildren: () => import('@tgw-clients/tgw-b2c/tgw-b2c.module').then(({ TgwB2cModule }) => TgwB2cModule),
              data: { header: HeaderType.Professionals }
            },
          ],
          data: { lang }
        }
      ]
    }
    return acc;
  }, []),
  {
    path: '',
    canLoad: [LangGuard],
    canActivate: [LangGuard],
    loadChildren: () => import('@tgw-clients/tgw-b2c/tgw-b2c.module').then(({ TgwB2cModule }) => TgwB2cModule),
    data: { header: HeaderType.Professionals }
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { initialNavigation: 'enabled', paramsInheritanceStrategy: 'always', scrollPositionRestoration: 'enabled' })
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
