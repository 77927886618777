import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  Output
} from '@angular/core';
import { Router } from '@angular/router';
import { Environment, ENVIRONMENT } from '@tgw-clients/shared';

@Component({
  selector: 'tgw-search',
  template: `
    <div class="search-container">
      <div *isBrand="'rao'" class="container">
        <img
          *ngIf="displayMenuIcon"
          src="assets/icons/menu_Left.png"
          class="menu-icon"
          (click)="openMenu()"
        />

        <div class="search-wrapper">
          <span
            *ngIf="displayBackIcon"
            inlineSVG="assets/icons/arrow-left2.svg"
            class="arrow-left"
            (click)="discardSearch()"
          ></span>
          <input
            [value]="search"
            type="text"
            class="search-input"
            placeholder="Busca aqui"
            (input)="changeInput($event)"
            (keyup.enter)="performSearch()"
          />
          <span
            (click)="performSearch()"
            class="search-icon"
            inlineSVG="assets/icons/search_icon2.svg"
          ></span>
        </div>
      </div>

      <div *isBrand="'tgw'" class="container-tgw">
        <img
          *ngIf="displayMenuIcon"
          src="assets/icons/menu_left.svg"
          class="menu-icon"
          (click)="openMenu()"
        />

        <div class="search-wrapper">
          <span
            *ngIf="displayBackIcon"
            inlineSVG="assets/icons/arrow-left2.svg"
            class="arrow-left"
            (click)="discardSearch()"
          ></span>
          <input
            [value]="search"
            type="text"
            class="search-input"
            placeholder="Busca aqui"
            (input)="changeInput($event)"
            (keyup.enter)="performSearch()"
          />
          <span
            (click)="performSearch()"
            class="search-icon"
            inlineSVG="assets/icons/search_icon2.svg"
          ></span>
        </div>
      </div>

      <div *ngIf="openSuggestedRoutes" class="container-suggestions">
        <h3 class="title">Sugerencias</h3>
        <div class="list-suggested">
          <a
            class="item-suggested highlight"
            href="/tienda/1?Tipo-de-vino=Tinto"
          >
            Vinos Tintos
          </a>
          <a
            class="item-suggested highlight"
            href="/tienda/1?Tipo-de-vino=Blanco"
          >
            Vinos Blancos
          </a>

          <a
            *ngif="env.brand === 'rao'"
            class="item-suggested highlight"
            href="/club"
          >
            Ventajas Club RAO
          </a>
          <a class="item-suggested" href=""> Novedades </a>
          <a class="item-suggested" href=""> Los más vendidos </a>
          <a class="item-suggested" href="/ofertas/1"> Ofertas </a>
          <a class="item-suggested" href="/ofertas/1"> Nuestra Selección </a>
          <a class="item-suggested" href="/experiencias/1"> Experiencias </a>
          <a class="item-suggested" href="/magazine/1"> Magazine </a>
        </div>
      </div>
    </div>
  `,
  styleUrls: ['./search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchComponent {
  @Output() openSearchMenu = new EventEmitter();
  @Output() changeOpenSuggested = new EventEmitter();
  search = '';

  displayMenuIcon = true;
  displayBackIcon = false;
  openSuggestedRoutes = false;

  constructor(
    private router: Router,
    @Inject(ENVIRONMENT) public env: Environment
  ) {}

  changeDisplayIcon() {
    if (this.search) {
      this.displayBackIcon = true;
      this.displayMenuIcon = false;
      if (!this.openSuggestedRoutes) {
        this.changeOpenSuggested.next();
      }
      this.openSuggestedRoutes = true;
    } else {
      this.displayBackIcon = false;
      this.displayMenuIcon = true;

      if (this.openSuggestedRoutes) {
        this.changeOpenSuggested.next();
      }
      this.openSuggestedRoutes = false;
    }
  }

  discardSearch() {
    this.search = '';
    this.changeDisplayIcon();
  }

  changeInput(event) {
    this.search = event.target.value;
    this.changeDisplayIcon();
  }

  openMenu() {
    this.openSearchMenu.next();
  }

  performSearch() {
    this.router.navigate(['/tienda/1'], { queryParams: { s: this.search } });
    this.openSuggestedRoutes = false;
  }
}
