import { createAction, props, union } from '@ngrx/store';
import { Product } from '@tgw-clients/shared';
import { CartItem } from '../../cart';

export const loadPacks = createAction('[Packs] Load packs', props<{ page: string; }>());
export const savePacks = createAction('[Packs] Save packs', props<{ page: number; packs: Product[]; totalPages: number; }>());

export const loadPack = createAction('[Packs] Load pack', props<{ slug: string; }>());

export const loadPackProducts = createAction('[Packs] Load pack products', props<{ pack: Product; }>());
export const savePackProducts = createAction('[Packs] Save pack products', props<{ packId: number; productsInPack: CartItem[]; }>());


const all = union({
  loadPacks,
  savePacks,
  loadPack,
  loadPackProducts,
  savePackProducts
});

export type ActionsUnion = typeof all;
