import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot, UrlTree } from "@angular/router";
import { AlertService } from "@tgw-app/lazy/shared-app/services/alert.service";
import { Observable } from "rxjs";

export interface unsavedChangesComponent {
    canDeactivate:  () => boolean | Observable<boolean>;
  }

@Injectable({
    providedIn: 'root'
})
export class isSavedGuard implements CanDeactivate<unsavedChangesComponent> {

    constructor(private alertService: AlertService) {}

    canDeactivate(
        component: unsavedChangesComponent,
        currentRoute: ActivatedRouteSnapshot,
        currentState: RouterStateSnapshot, nextState?:
        RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        return component.canDeactivate() ? true : this.alertService.showUnsavedChangesAlert();  
    }
}