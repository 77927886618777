import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromCoupons from './coupons.reducer';

export const couponsKey = 'coupons';

export interface State {
  [couponsKey]: fromCoupons.State
};

export const reducer = fromCoupons.reducer;

export const getCouponsState = createFeatureSelector<State, fromCoupons.State>(couponsKey);

export const getFeaturedCoupon = createSelector(
  getCouponsState,
  fromCoupons.getFeaturedCoupon
);

export const getFeaturedCouponCode = createSelector(
  getFeaturedCoupon,
  (coupon) => coupon?.code
);

