import { registerLocaleData } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { map, startWith } from 'rxjs/operators';

export class DynamicLocaleId extends String {
  private _localeLoaded;
  constructor(protected translate: TranslateService, _langs: string[]) {
    super('');
    const langs = _langs;

    this._localeLoaded = _langs.reduce((acc, lang) => ({ ...acc, [lang]: false }), {});

    this.translate.onLangChange.pipe(
      map(({ lang }) => lang),
      startWith(this.translate.currentLang)
    ).subscribe(async (lang: string) => {
      if (langs.includes(lang)) {
        langs.splice(langs.indexOf(lang), 1);
        const angularLocale = await this.loadLocale(lang);
        registerLocaleData(angularLocale.default);
        this._localeLoaded[lang] = true;
      }
    });

  }

  private loadLocale(lang: string): Promise<any> {
    switch (lang) {
      case 'es':
        return import(`@angular/common/locales/es`);
      case 'en':
        return import(`@angular/common/locales/en`);
      case 'eu':
        return import(`@angular/common/locales/eu`);
      default:
        return Promise.reject('Locale not supported');
    }
  }

  isLocaleLoaded(lang: string): boolean {
    return this._localeLoaded[lang];
  }

  toString() {
    return this.translate.currentLang;
  }
}