import { ActionReducerMap, createFeatureSelector, createSelector } from '@ngrx/store';
import { Product } from '../models';
import * as fromExperiences from './experiences.reducer';
import * as fromCollection from './collection.reducer';
import * as fromOffers from './offers.reducer';
import * as fromPacks from './packs.reducer';
import * as fromClubs from './clubs.reducer';
import * as fromProducts from './products.reducer';


export const goodsKey = 'goods';

export interface ProductsState {
  products: fromProducts.State;
  [fromOffers.offersKey]: fromOffers.State;
  [fromPacks.packsKey]: fromPacks.State;
  [fromClubs.clubsKey]: fromClubs.State;
  [fromExperiences.experiencesKey]: fromExperiences.State;
  [fromCollection.collectionKey]: fromCollection.State;
};


export interface State {
  [goodsKey]: ProductsState
};

export const reducers: ActionReducerMap<ProductsState> = {
  products: fromProducts.reducer,
  [fromOffers.offersKey]: fromOffers.reducer,
  [fromPacks.packsKey]: fromPacks.reducer,
  [fromClubs.clubsKey]: fromClubs.reducer,
  [fromExperiences.experiencesKey]: fromExperiences.reducer,
  [fromCollection.collectionKey]: fromCollection.reducer
};


export const getGoodsState = createFeatureSelector<State, ProductsState>(goodsKey);

export const getProductsState = createSelector(
  getGoodsState,
  (state) => state.products
);

export const getOffersState = createSelector(
  getGoodsState,
  (state) => state[fromOffers.offersKey]
);

export const getPacksState = createSelector(
  getGoodsState,
  (state) => state[fromPacks.packsKey]
);

export const getExperienceState = createSelector(
  getGoodsState,
  (state) => state[fromExperiences.experiencesKey]
);

export const getCollectionState = createSelector(
  getGoodsState,
  (state) => state[fromCollection.collectionKey]
);





export const getClubsState = createSelector(
  getGoodsState,
  (state) => state[fromClubs.clubsKey]
);



//******* **************************/

export const getProductPages = createSelector(
  getProductsState,
  fromProducts.getProductPages
);

export const getCurrentPage = createSelector(
  getProductsState,
  fromProducts.getCurrentPage
);

export const getTotalPages = createSelector(
  getProductsState,
  fromProducts.getTotalPages
);

export const getFound = createSelector(
  getProductsState,
  fromProducts.getTotalfound
);

export const getMaxPrice = createSelector(
  getProductsState,
  fromProducts.getMaxPrice
);

export const getMinPrice = createSelector(
  getProductsState,
  fromProducts.getMinPrice
);

export const getProductsIds = createSelector(
  getProductsState,
  fromProducts.getProductsIds
);

export const getProductPending = createSelector(
  getProductsState,
  fromProducts.getProductPending
);

export const areProductsOnError = createSelector(
  getProductsState,
  fromProducts.hasError
);

export const getProductsByPage = (page: string) => createSelector(
  getProductPages,
  (productPages) => productPages[+page]
);

export const getCurrentPageProducts = createSelector(
  getCurrentPage,
  getProductPages,
  (page, productPages) => productPages[page]
);


export const getFeaturedProducts = createSelector(
  getProductsState,
  fromProducts.getFeaturedProducts
);

export const getOffersTotalPages = createSelector(
  getOffersState,
  fromOffers.getTotalPages
);

export const getOffersTotalFound = createSelector(
  getOffersState,
  fromProducts.getTotalfound
);

export const getAttributeCounts = createSelector(
  getProductsState,
  fromProducts.getAttributeCounts
);

export const getOfferProducts = createSelector(
  getOffersState,
  fromOffers.getOfferProducts
);

export const getProductsPerPack = createSelector(
  getPacksState,
  fromPacks.getProductsPerPack
);

export const getProductsPerPackById = (id: number) => createSelector(
  getProductsPerPack,
  (productsPerPack) => productsPerPack[id]
);

export const getPacks = createSelector(
  getPacksState,
  fromPacks.getPacks
);

export const getPacksByPage = (page: string) => createSelector(
  getPacks,
  (packsPerPage) => packsPerPage[+page]
);

export const getPackBySlug = (packSlug: string) => createSelector(
  getPacks,
  (packsPerPage) => {
    const packs: Product[] = Object.keys(packsPerPage || {}).reduce((acc, page) => [...acc, ...packsPerPage[page]], []);
    return packs.find(({ slug }) => slug === packSlug);
  }
);

export const getPacksTotalPages = createSelector(
  getPacksState,
  fromPacks.getTotalPages
);

export const getOfferProductsPage = (page: string) => createSelector(
  getOfferProducts,
  (offerProducts) => offerProducts[+page]
);


export const getProductBySlug = (productSlug: string) => createSelector(
  getProductPages,
  getFeaturedProducts,
  getOfferProducts,
  getPacks,
  (productPages, featured, offers, packs) => {
    const products = (featured || [])
      .concat(Object.keys(productPages || {}).reduce((acc, page) => [...acc, ...productPages[page]], []))
      .concat(Object.keys(offers || {}).reduce((acc, page) => [...acc, ...offers[page]], []))
      .concat(Object.keys(packs || {}).reduce((acc, page) => [...acc, ...packs[page]], []));

    return products.find(({ slug }) => slug === productSlug);
  }
);

//***CLUBS */
export const getClubsProducts = createSelector(
  getClubsState,
  fromClubs.getClubsProducts
);

export const getProductClubPending = createSelector(
  getClubsState,
  fromClubs.getPending
);

export const getClubsTotalPages = createSelector(
  getClubsState,
  fromClubs.getTotalPages
);

export const getProductClubPage = (page: string) => createSelector(
  getClubsProducts,
  (productsClub) => productsClub[page]
);
//***CLUBS */
export const getMonthProducts = createSelector(
  getProductsState,
  fromProducts.getMonthProducts
);

export const getExperiencesPending = createSelector(
  getExperienceState,
  fromExperiences.getExperiencesPending
);

export const hasExperienceError = createSelector(
  getExperienceState,
  fromExperiences.hasError
);

export const getExperiencesTotalPages = createSelector(
  getExperienceState,
  fromExperiences.getTotalPages
);

export const getExperiences = createSelector(
  getExperienceState,
  fromExperiences.getExperiences
);

export const getExperiencesPage = (page: string, id?: number, posts?:string) => createSelector(
  getExperiences,
  (experiencesPages) =>{
    if(posts){
      return experiencesPages?.[id]?.[posts]
    }else{
      return experiencesPages?.[id]?.[page]
    }
  
  } 
    
);

export const getExperienceCategories =  createSelector(
  getExperiences,
  fromExperiences.getExperienceCategories
);

export const getExperienceSearch = (search: string) => createSelector(
  getExperiences,
  fromExperiences.getExperienceSearch?.[search]
);

export const getExperienceById = (experienceSlug: string,category: number) => createSelector(
  getExperiences,
  (experiencesPages) => {
    if (!experiencesPages?.[category]) {
      return null;
    }
    for (const experiences of Object.values(experiencesPages[category])) {
      const experience = experiences.find(({ slug }) => slug === experienceSlug);
      if (experience) {
        return experience
      }
    }
    return null
  }
);


export const getCollectionPending = createSelector(
  getCollectionState,
  fromCollection.getCollectionPending
);

export const hasCollectionError = createSelector(
  getCollectionState,
  fromCollection.hasError
);

export const getCollectionTotalPages = createSelector(
  getCollectionState,
  fromCollection.getTotalPages
);

export const getCollection = createSelector(
  getCollectionState,
  fromCollection.getCollection
);

export const getCollectionPage = (page: string, id?: number, posts?:string) => createSelector(
  getCollection,
  (collectionPages) =>{
    if(posts){
      return collectionPages?.[id]?.[posts]
    }else{
      return collectionPages?.[id]?.[page]
    }
  
  } 
    
);

export const getCollectionCategories =  createSelector(
  getCollection,
  fromCollection.getCollectionCategories
);

export const getCollectionSearch = (search: string) => createSelector(
  getCollection,
  fromCollection.getCollectionSearch?.[search]
);

export const getCollectionById = (CollectionSlug: string,category: number) => createSelector(
  getCollection,
  (collectionPages) => {
    if (!collectionPages?.[category]) {
      return null;
    }
    for (const collections of Object.values(collectionPages[category])) {
      const collection = collections.find(({ slug }) => slug === CollectionSlug);
      if (collection) {
        return collection
      }
    }
    return null
  }
);



