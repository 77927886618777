import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { Store } from '@ngrx/store';
import { CartActions, StorageCartItem } from '../../cart';

@Injectable({
  providedIn: 'root'
})
export class CartGuard implements CanActivate {


  constructor(private store: Store) { }

  canActivate(route: ActivatedRouteSnapshot): boolean {
    const { products } = route.queryParams;
    if (products) {
      const items = products.split(',').reduce((acc: Partial<StorageCartItem>[], id: string) => {
        const productId = parseInt(id);
        const idx = acc.findIndex((item) => item.productId === productId);
        if (-1 < idx) {
          acc[idx].quantity += 1;
          return acc;
        }

        return [...acc, { productId, quantity: 1 }];
      }, []);
      this.store.dispatch(CartActions.loadCartItems({ items }));
    }
    return true;
  }

}

  // http://localhost:4200/compra/realizar-pedido?products=3501,3501,3501,3477,3477,3477
