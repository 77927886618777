import { Injectable } from '@angular/core';
import { AlertController, NavController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Network } from '@capacitor/network';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  constructor(public alertController: AlertController, private translate: TranslateService, private navCtrl: NavController) { }

  async presentDeleteConfirmationAlert(productName: string) {
    let choice;
    const message = 'tgwapp-product-delete-confirmation'
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: this.translate.instant('tgwapp-product-delete'),
      message: '<p>' + this.translate.instant(message, { productName }) + '</p>',
      buttons: [
        {
          text: this.translate.instant('tgwapp-commons-cancel'),
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            alert.dismiss();
          }
        }, {
          text: this.translate.instant('tgwapp-commons-remove'),
          role: 'ok',
          handler: () => {
            alert.dismiss();
          }
        }
      ]
    });

    await alert.present();
    await alert.onDidDismiss().then((data) => {
      choice = data.role
    })
    return choice

  }

  async presentBackConfirmationAlert() {
    let choice;
    const isOnline = (await Network.getStatus()).connected
    if(isOnline){
      const message = `tgwapp-product-exit-confirmation`
      const alert = await this.alertController.create({
        cssClass: 'my-custom-class',
        header: this.translate.instant('tgwapp-product-exit-confirmation-title'),
        message: '<p>' + this.translate.instant(message) + '</p>',
        buttons: [
          {
            text: this.translate.instant('tgwapp-commons-exit'),
            role: 'ok',
            handler: () => {
              alert.dismiss();
            }
          },
          {
            text: this.translate.instant('tgwapp-commons-keep-editing'),
            role: 'cancel',
            cssClass: 'secondary',
            handler: () => {
              alert.dismiss();
            }
          },
        ]
      });
  
      await alert.present();
      await alert.onDidDismiss().then((data) => {
        choice = data.role
      })

    } else { choice = 'ok'}
    return choice

  }


  async showStockInfo() {
    const alertInfo = await this.alertController.create({
      cssClass: 'info-alert',
      header: this.translate.instant('tgwapp-stock-config-title'),
      message: '<p>' + this.translate.instant('tgwapp-stock-info-security') + '</p><br><p>' + this.translate.instant('tgwapp-stock-info-min') + '</p>',
      buttons: [
        {
          text: this.translate.instant('tgwapp-commons-understood'),
          role: 'cancel',
          cssClass: 'secondary'
        }
      ]
    });

    await alertInfo.present();

  }

  async showShopInfo() {
    const alertInfo = await this.alertController.create({
      cssClass: 'info-alert',
      header: this.translate.instant('tgwapp-shop-config-title'),
      message: '<p>' + this.translate.instant('tgwapp-shop-info-security') + '</p><br><p>' + this.translate.instant('tgwapp-shop-info-min') + '</p>',
      buttons: [
        {
          text: this.translate.instant('tgwapp-commons-understood'),
          role: 'cancel',
          cssClass: 'secondary'
        }
      ]
    });

    await alertInfo.present();

  }

  async PurchaseStopInfo() {
    const alertInfo = await this.alertController.create({
      cssClass: 'info-alert',
      header: this.translate.instant('tgwapp-purchase-stop-title'),
      message: '<p>' + this.translate.instant('tgwapp-purchase-stop-message') + '</p>',
      buttons: [
        {
          text: this.translate.instant('ttgwapp-purchase-stop-understood'),
          role: 'cancel',
          cssClass: 'secondary'
        }
      ]
    });

    await alertInfo.present();

  }

  async showMissingConfigAlert() {
    const alertInfo = await this.alertController.create({
      cssClass: 'info-alert',
      header: this.translate.instant('tgwapp-stock-config-title'),
      message: '<p>' + this.translate.instant('tgwapp-stock-missing-config') + '</p>',
      buttons: [
        {
          text: this.translate.instant('tgwapp-commons-understood'),
          role: 'cancel',
          cssClass: 'secondary'
        }, 
        // {
        //   text: this.translate.instant('tgwapp-configure'),
        //   handler: () => {
        //     this.navCtrl.navigateForward('mi-inventario/config');
        //   }
        // }
      ]
    });
    await alertInfo.present();
  }

  async showMissingRegisterAlert() {
    const alertInfo = await this.alertController.create({
      cssClass: 'info-alert',
      header: this.translate.instant('tgwapp-register-config-title'),
      message: '<p>' + this.translate.instant('tgwapp-register-missing-config') + '</p>',
      buttons: [
        {
          text: this.translate.instant('tgwapp-commons-understood'),
          handler: () => {
            this.navCtrl.navigateForward('mi-cuenta');
          }
        }
      ]
    });

    await alertInfo.present();

  }
  async showMissingRegisterExistsAlert() {
    const alertInfo = await this.alertController.create({
      cssClass: 'info-alert',
      header: this.translate.instant('tgwapp-register-exists-config-title'),
      message: '<p>' + this.translate.instant('tgwapp-register-exists-missing-config') + '</p>',
      buttons: [
        {
          text: this.translate.instant('tgwapp-commons-understood'),
          handler: () => {
            this.navCtrl.navigateForward('mi-cuenta');
          }
        }
      ]
    });

    await alertInfo.present();

  }

  async showAddressConfigAlert() {
    const alertInfo = await this.alertController.create({
      cssClass: 'info-alert',
      header: this.translate.instant('tgwapp-address-config-title'),
      message: '<p>' + this.translate.instant('tgwapp-stock-missing-address') + '</p>',
      buttons: [
         {
          text: this.translate.instant('tgwapp-address-button'),
          handler: () => {
            this.navCtrl.navigateForward('/direccion/address-modify/0');
          }
        }
      ]
    });
    await alertInfo.present();
  }

  async showEANInfo() {
    const alertInfo = await this.alertController.create({
      cssClass: 'info-alert',
      header: this.translate.instant('tgwapp-stock-config-title'),
      message: '<p>' + this.translate.instant('tgwapp-manual-ean-info') + '</p>',
      buttons: [
        {
          text: this.translate.instant('tgwapp-commons-understood'),
          role: 'cancel',
          cssClass: 'secondary'
        }
      ]

    });
    await alertInfo.present();
  }

  async showUnsavedChangesAlert(): Promise<boolean> {
    let choice: boolean;
    const isOnline = (await Network.getStatus()).connected
    if(isOnline){
      const message = 'tgwapp-product-exit-confirmation'
      const alert = await this.alertController.create({
        cssClass: 'my-custom-class',
        header: this.translate.instant('tgwapp-product-exit-confirmation-title'),
        message: '<p>' + this.translate.instant(message) + '</p>',
        buttons: [
          {
            text: this.translate.instant('tgwapp-commons-exit'),
            role: 'cancel',
            cssClass: 'secondary',
            handler: () => {
              alert.dismiss();
            }
          }, {
            text: this.translate.instant('tgwapp-commons-keep-editing'),
            role: 'ok',
            handler: () => {
              alert.dismiss();
            }
          }]
      });
      await alert.present();
      await alert.onDidDismiss().then((data) => {
        if(data.role == 'cancel')
          choice = true;
        else
          choice = false; 
      });
    } else { choice = true}
    return choice;
  }

  async presentChangeImageConfirmationAlert() {
    let choice;
    const message = 'tgwapp-product-image-change-confirmation'
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: this.translate.instant('tgwapp-product-image-change'),
      message: '<p>' + this.translate.instant(message) + '</p>',
      buttons: [
        {
          text: this.translate.instant('tgwapp-commons-cancel'),
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            alert.dismiss();
          }
        }, {
          text: this.translate.instant('tgwapp-commons-change'),
          role: 'ok',
          handler: () => {
            alert.dismiss();
          }
        }
      ]
    });
    await alert.present();
    await alert.onDidDismiss().then((data) => {choice = data.role})
    return choice
  }

  async presentRemoveImageConfirmationAlert() {
    let choice;
    const message = 'tgwapp-product-image-change-confirmation'
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Eliminar imagen',
      message: '<p>' + this.translate.instant(message) + '</p>',
      buttons: [
        {
          text: this.translate.instant('tgwapp-commons-cancel'),
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            alert.dismiss();
          }
        }, {
          text: this.translate.instant('tgwapp-commons-remove'),
          role: 'ok',
          handler: () => {
            alert.dismiss();
          }
        }
      ]
    });
    await alert.present();
    await alert.onDidDismiss().then((data) => {choice = data.role;})
    return choice
  }

}
