import { ChangeDetectionStrategy, Component } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { fromRemoteStatus } from '@tgw-clients/shared';

@Component({
  selector: 'tgw-particular-root',
  template: `
    <tgw-layout *ngIf="(underMaintenance$ | async) !== true; else maintenance">
      <router-outlet></router-outlet>
    </tgw-layout>
    <ng-template #maintenance>
      <tgw-maintenance-page></tgw-maintenance-page>
    </ng-template>
  `,
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent {
  underMaintenance$ = this.store.pipe(
    select(fromRemoteStatus.isUnderMaintenance)
  )

  constructor(private store: Store) { }

}
