import { ChangeDetectionStrategy, Component, HostListener, Input } from '@angular/core';
import { CoreConfigService, ProductImage, WPImage } from '@tgw-clients/shared';
import { BehaviorSubject, of, timer } from 'rxjs';
import { expand, map, switchMap } from 'rxjs/operators';
@Component({
  selector: 'tgw-carousel',
  template: `
    <ng-container *ngIf="imgIndex$ | async as imgIndex">
    <!-- [href]="images[imgIndex.index]?.link"> -->
      <a *ngIf="images[imgIndex.index]?.hasOwnProperty('link'); else products" (click)="addToDataLayer(images[imgIndex.index], imgIndex.index)">
        <img (swipeup)="onSwipeUp($event)" [class.hidden-sm-down]="mobileImages?.length" [src]="images[imgIndex.index]?.url"/>
        <img (swipeup)="onSwipeUp($event)" *ngIf="mobileImages?.length" class="hidden-md-up" [src]="mobileImages[imgIndex.index]?.url"/>
      </a>
      <ng-template #products>
        <img [src]="images[imgIndex.index]?.src"/>
      </ng-template>

      <div class="arrows-wrapper" *ngIf="!productPage">
        <button class="prev" type="button" [disabled]="imgIndex.index === 0" (click)="selectedIndex$.next(imgIndex.index - 1)" inlineSVG="assets/icons/chevron-left.svg"></button>
        <button class="next" type="button" [disabled]="imgIndex.index === images.length - 1" (click)="selectedIndex$.next(imgIndex.index + 1)" inlineSVG="assets/icons/chevron-left.svg"></button>
      </div>
      <div class="bullets-wrapper" *ngIf="!productPage">
        <span class="bullet" *ngFor="let img of images; let i = index" (click)="selectedIndex$.next(i)" [class.active]="i === imgIndex.index"></span>
      </div>
    </ng-container>
  `,
  styleUrls: ['./carousel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CarouselComponent {
  @Input() images: WPImage[] | ProductImage[];
  @Input() mobileImages: WPImage[] | ProductImage[];
  @Input() isHome: boolean;
  @Input() productPage: boolean;
  // clickOnPromotion

  selectedIndex$ = new BehaviorSubject(0);
  imgIndex$ = this.selectedIndex$.pipe(
    switchMap((index) =>
      of(index).pipe(
        expand((idx) =>
          timer(3000).pipe(map(() => idx === this.images.length - 1 ? 0 : idx + 1))
        )
      )
    ),
    map((index) => ({ index }))
  );


  businessType = this._coreConfig.businessType;
  constructor(private _coreConfig: CoreConfigService) { }


  // @HostListener('swiperight')
  // onSwipeRight(): void {
  //   if (this.selectedIndex$.value !== 0) {
  //     this.selectedIndex$.next(this.selectedIndex$.value - 1);
  //   }
  // }

  // @HostListener('swipeleft')
  // onSwipeLeft(): void {
  //   if (this.selectedIndex$.value !== this.images.length - 1) {
  //     this.selectedIndex$.next(this.selectedIndex$.value + 1);
  //   }
  // }

  
  onSwipeUp(ev): void{
    console.log('swipeUP')
    console.log(ev)
    window.scrollBy(0, 100);
  }

  addToDataLayer(item: any, index: number): void{
    if(!Array.isArray(window.dataLayer)){
      return;
    }
    if(!this.isHome) return

    const {id, description, url} = item
    let data: any = {id, texto:description, url, posicion:index }

    let arrayData = [];
    let findInDataLayer = window.dataLayer.find(item => !!item?.['clickEnPromocion'])
    if(findInDataLayer) arrayData = findInDataLayer?.['clickEnPromocion']

    window.dataLayer.push({
      event: 'productClick',
      'ecommerce': {
        'click': {
          'actionField': { list: 'clickEnPromocion' },
          'products': [data]
        }
      }
    });
  }


}
