<div class="list-carrousel">
    <swiper class="mySwiper" [config]="configSwiperMobile" [index]="currentIndex">

        <div swiperSlide class="card-container" *ngFor="let product of products">
          <tgw-product-card-tgw
            style="width: 320px; min-height: 500px"
            [hover]="true"
            [dataLayerItemName]="'DestacadosDeLaHome'"
            [divId]="'homeHighlights'"
            [product]="product"
            [showPrice]="true"
            [clubUser]="memberRao"
            (add)="addToCart($event)"
          ></tgw-product-card-tgw>
        </div>
    </swiper>
    
</div>
