
export enum DiscountType {
  FixedCart = 'fixed_cart',
  FixedProduct = 'fixed_product',
  Percent = 'percent'
}

export interface CouponAPI {
  id: number;
  code: string;
  amount: string;
  discount_type: DiscountType;
  date_expires: any;
  usage_count: number;
  usage_limit: number;
  usage_limit_per_user: number;
  used_by: string[];
  product_ids: number[];
  excluded_product_ids: number[];
  excluded_product_categories: number[];
  product_categories: number[];
  minimum_amount: number;
  maximum_amount: number;
  individual_use: boolean;
  exclude_sale_items: boolean;
  free_shipping: boolean;
  meta_data: [
    { id: number; key: 'tgw_featured_coupon'; value: 'yes' | 'no' }
  ]
}

export interface Coupon extends CouponAPI {
  discountedAmount: number;
  description?: string;
  valid: boolean;
}
