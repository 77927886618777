import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { EMPTY } from 'rxjs';
import { catchError, exhaustMap } from 'rxjs/operators';
import { RouterActions } from '../actions';




@Injectable()
export class RouterEffects {

  navigateTo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RouterActions.navigateTo),
      exhaustMap(({ path, params }) => {
        const route = this.translate.currentLang !== this.translate.defaultLang ? `/${this.translate.currentLang}${path}` : path;
        return this.router.navigate([route], params ? { queryParams: params } : {})
      }),
      catchError((error) => {
        console.error(error);
        return EMPTY;
      })
    ), { dispatch: false }
  );


  constructor(
    private actions$: Actions,
    private translate: TranslateService,
    private router: Router
  ) { }
}
