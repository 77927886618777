import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CoreConfigService, Logo } from '@tgw-clients/shared';


@Component({
  selector: 'tgw-redirect-dialog',
  template: `
    <span mat-dialog-close class="icon-close" inlineSVG="assets/icons/close.svg"></span>
    <span class="logo" [inlineSVG]="logo" alt="The Grand Wines"></span>
    <div class="actions-wrapper">
      <a class="btn btn-primary" [href]="professionalsPath">{{'tgw-commons-hostelery' | translate | uppercase}}</a>
      <a class="btn btn-primary" [href]="individualsPath">{{'tgw-commons-individuals' | translate | uppercase}}</a>
    </div>
  `,
  styleUrls: ['./redirect-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RedirectDialogComponent {
  logo = this._coreConfig.getLogo(Logo.Footer);

  professionalsPath = `https://hosteleria.${window.location.hostname.replace(/www\./ig, '')}`;
  individualsPath = `https://particular.${window.location.hostname.replace(/www\./ig, '')}`;

  constructor(@Inject(MAT_DIALOG_DATA) path: string, private _coreConfig: CoreConfigService) {
    this.individualsPath += `${path}`;
    if (path.includes('tienda')) {
      this.professionalsPath += `/mi-cuenta/registro`;
    } else {
      this.professionalsPath += `${path}`;
    }
  }
}
