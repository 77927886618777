import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { Category, Tag } from '../../utils/models/token';
import { CoreConfigService, EndpointType } from '../../utils/services/core-config.service';
import { Collection, Experience, ExperienceDate, MenuItems, Product, ProductCategory, ProductsCustomAPI } from '../models';


@Injectable({ providedIn: 'root' })
export class ProductsService {
  private _productsConfig = this._coreConfig.getProductsConfig();
  private customProductsURL = `${this._coreConfig.getEndpoint(EndpointType.Custom)}products/`;
  private wcProductsURL = `${this._coreConfig.getEndpoint(EndpointType.Woocommerce)}products/`;

  private productsSource = new BehaviorSubject<Product[]>([]);
  currentContacts = this.productsSource.asObservable();


  updateContacts(contacts: Product[]) {
    this.productsSource.next(contacts)
  }

  constructor(private _coreConfig: CoreConfigService, private http: HttpClient, private translate: TranslateService) { }

  getBestSellerProducts(province?: string, count = 4): Observable<Product[]> {
    let params = this._getBaseParams()
      .append('tag', this._coreConfig.getTag(Tag.BestSellers))
      .append('category', this._coreConfig.getCategory(Category.Wine))
      .append('per_page', count.toString());

    if (province) {
      params = params.append('state', province);
    }

    return this.http.get<Product[]>(this.wcProductsURL, { params });
  }

  getCustomerProducts(filter_type?: string, filter_value?: string): Observable<Product[]> {
    let params = this._getBaseParams();

    switch (filter_type) {
      case 'CATEGORIA':
        params = params.append('category', filter_value)
        break;
      case 'TAG':
        params = params.append('tag', filter_value)
        break;

      default:
        break;
    }

    return this.http.get<Product[]>(this.wcProductsURL, { params });
  }

  getProducts(page: string = '1', province?: string, queryParamsData?: any, queryParamsSearch?: string, per_page: string = this._productsConfig.perPage): Observable<{ products: Product[], totalPages: number, page: number, attributeCounts: any, found: number, max_price: number, min_price: number, products_ids: number[] }> {
    console.log("Entra")
    console.log(queryParamsData)
    let params = this._getBaseParams()
      .append('posts_per_page', per_page)
      // .append('category', '15')
      .append('category', this._coreConfig.getCategory(Category.Wine))
      .append('page', page);

    if (province) {
      params = params.append('state', province);
    }

    if (queryParamsData) {
      Object.keys(queryParamsData).forEach(item => {
        params = params.append(`${item}`, queryParamsData[item]);
      })
    }

    if (queryParamsSearch) {
      params = params.append('s', queryParamsSearch);
    }

    return this.http.get<ProductsCustomAPI>(this.customProductsURL, { params }).pipe(
      map((productsAPI) => {
        if (!productsAPI?.products) {
          return { products: [], totalPages: 0, page: +page, attributeCounts: {}, found: 0, max_price: 0, min_price: 0, products_ids: [] };
        }
        const { products, counts: { pages, attributes, page: currentPage, wineries, found, max_price, min_price, products_ids } } = productsAPI;
        return {
          products, totalPages: pages, page: currentPage, attributeCounts: {
            ...attributes,
            Bodegas: { label: '4;Bodegas', show_filter: true, terms: wineries },
            Precio: { label: '3;Precio', show_filter: true, terms: { min: 0, max: 999 } }
          }, found, max_price, min_price, products_ids
        };
      })
    );
  }

  getProductsClub(page: string = '1'): Observable<{ products: Product[], totalPages: number, page: number }> {
    const params = this._getBaseParams()
      .append('per_page', this._productsConfig.perPage)
      .append('page', page)
      .append('category', this._coreConfig.getCategory(Category.Club));

    return this.http.get<ProductsCustomAPI>(this.wcProductsURL, { params }).pipe(
      map(({ products, counts: { pages } }) => ({ products, totalPages: pages, page: +page }))
    );
  }

  getProductsById(ids: number[], not_stock_status?: boolean): Observable<Product[]> {
    let getIds: any;
    if (ids) {
      getIds = JSON.stringify(ids).replace(/\[|\]/g, '')
    }
    let params;
    if (not_stock_status) {
      params = new HttpParams()
        .append('status', 'publish')
        .append('type', 'simple')
        .append('include', getIds);
    } else {
      params = this._getBaseParams()
        .append('include', getIds);
    }

    return this.http.get<Product[]>(this.wcProductsURL, { params });
  }

  getProductsByWinery(wineryId: string, province?: string): Observable<Product[]> {
    let params = this._getBaseParams()
      .append('winery_id', wineryId)
      .append('category', this._coreConfig.getCategory(Category.Wine));

    if (province) {
      params = params.append('state', province);
    }

    return this.http.get<Product[]>(this.wcProductsURL, { params });
  }

  getProduct(slug: string): Observable<Product> {
    const params = this._getBaseParams()
      .append('slug', slug);

    return this.http.get<Product[]>(this.wcProductsURL, { params }).pipe(
      switchMap((products) => {
        if (!products[0]) {
          return throwError('No product');
        }
        return [products[0]];
      })
    );
  }

  getProductBySku(sku: string): Observable<Product> {
    const params = this._getBaseParams();

    return this.http.get<Product>(`${this.wcProductsURL}${sku}`, { params }).pipe(
      switchMap((products) => {
        if (!products) {
          return throwError('No product');
        }
        return [products];
      })
    );
  }

  getMenuItems(): Observable<MenuItems[]> {
    return this.http.get<MenuItems[]>(`${this._coreConfig.getEndpoint(EndpointType.Custom)}attr/`).pipe(
      catchError((error) => {
        console.error(error);
        return [<any>{}];
      }),
    );
  }

  getMenuSubItems(menuItem: number, per_page: number): Observable<any> {
    const params = this._getBaseParams()
      .append('page', '1')
      .append('per_page', per_page.toString())

    return this.http.get<any[]>(`${this.wcProductsURL}attributes/${menuItem}/terms/`, {
      params,
      observe: 'response'
    }).pipe(
      map((response: HttpResponse<any[]>) => ({ products: response.body, totalPages: +response.headers.get('x-wp-totalpages') }))
    );
  }

  getTerm(id: number): Observable<any> {
    const params = this._getBaseParams();

    return this.http.get(`${this.wcProductsURL}attributes/${id}/terms/`, { params });
  }

  getPacks(page: string): Observable<{ products: Product[], totalPages: number, page: number }> {
    const params = this._getBaseParams()
      .append('per_page', this._productsConfig.perPage)
      .append('page', page)
      .append('category', this._coreConfig.getCategory(Category.Pack));

    return this.http.get<ProductsCustomAPI>(this.customProductsURL, { params }).pipe(
      map(({ products, counts: { pages } }) => ({ products, totalPages: pages, page: +page }))
    );
  }

  getOfferProducts(page: string): Observable<any> {
    const params = this._getBaseParams()
      .append('per_page', this._productsConfig.perPage)
      .append('page', page)
      .append('on_sale', 'true')
      .append('category', this._coreConfig.getCategory(Category.Wine));

    return this.http.get<ProductsCustomAPI>(this.customProductsURL, { params }).pipe(
      map(({ products, counts: { pages, found } }) => ({ products, totalPages: pages, page: +page, found }))
    );
  }

  getMonthProducts(): Observable<Product[]> {
    const params = this._getBaseParams()
      .append('featured', 'true');
    return this.http.get<Product[]>(this.wcProductsURL, { params });
  };

  getAllExperiences(page: string = '1', id: number, post?: string): Observable<any> {

    let postId: string = "";
    let params = this._getBaseParams()
      .append('page', page)
      .append('per_page', this._productsConfig.perPageExperience);
    if (id) {
      params = params.append('category', `${id}`);
    } else {
      params = params.append('category', this._coreConfig.getCategory(Category.Experience));
    }
    if (post) {
      postId = post + "/"
    }


    return this.http.get<HttpResponse<Experience[]>>(this.wcProductsURL + postId, { params, observe: 'response' }).pipe(
      map((res) => {
        if ((<any>res.body)?.length === 0) {
          return { experiences: [], totalPages: 0, page: page };
        }
        if (post) {
          return {
            experiences: [res.body],
            page: +page,
            totalPages: res.headers.get('x-wp-totalpages')
          };
        }
        return {
          experiences: res.body,
          page: +page,
          totalPages: res.headers.get('x-wp-totalpages')
        };
      })
    );
  };

  getExperiences(id: string): Observable<any> {
    const params = this._getBaseParams()
      .append('slug', id)
      .append('category', this._coreConfig.getCategory(Category.Experience));

    return this.http.get<ProductsCustomAPI>(this.wcProductsURL, { params }).pipe(
      map((products) => ({ experience: products[0] })),
      catchError(() => [[]])
    );
  };

  getExperienceAvailability(id: string): Observable<ExperienceDate[]> {
    const params = this._getBaseParams()
      .append('id', id);
    return this.http.get<ExperienceDate[]>(`${this._coreConfig.getEndpoint(EndpointType.Custom)}experiencia-dates`, { params }).pipe(
      catchError(() => [[]])
    );
  };

  getExperienceCategories(): Observable<ProductCategory[]> {
    return this.http.get<ProductCategory[]>(`${this.wcProductsURL}\categories?parent=${this._coreConfig.getCategory(Category.Experience)}`).pipe(
      catchError(() => [[]])
    );
  };

  getExperienceParentCategories(): Observable<ProductCategory[]> {
    return this.http.get<ProductCategory[]>(`${this.wcProductsURL}\categories\\${this._coreConfig.getCategory(Category.Experience)}`).pipe(
      catchError(() => [[]])
    );
  };

  getExperienceSearch(s: string): Observable<any> {
    const params = this._getBaseParams()
      .append('category', this._coreConfig.getCategory(Category.Experience))
      .append('search', s);
    return this.http.get<ExperienceDate[]>(this.wcProductsURL, { params }).pipe(
      catchError(() => [[]])
    );
  };

  getLastExperiences(per_page: string = '1', id: number): Observable<any> {
    let params = this._getBaseParams()
      .append('page', "1")
      .append('per_page', per_page);
    if (id) {
      params = params.append('category', `${id}`);
    } else {
      params = params.append('category', this._coreConfig.getCategory(Category.Experience));
    }

    return this.http.get<HttpResponse<Experience[]>>(this.wcProductsURL, { params }).pipe(
      catchError(() => [[]])
    );
  }

  getAllCollection(page: string = '1', id: number, post?: string): Observable<any> {

    let postId: string = "";
    let params = this._getBaseParams()
      .append('page', page)
      .append('per_page', this._productsConfig.perPageExperience)
      .append('orderby', 'id')
      .append('order', 'asc');
    if (id) {
      params = params.append('category', `${id}`);
    } else {
      params = params.append('category', this._coreConfig.getCategory(Category.Collection));
    }
    if (post) {
      postId = ""
    }


    return this.http.get<HttpResponse<Collection[]>>(this.wcProductsURL + postId, { params, observe: 'response' }).pipe(
      map((res) => {
        if ((<any>res.body)?.length === 0) {
          return { collection: [], totalPages: 0, page: page };
        }
        // if(post){
        //   return {
        //     collection: [res.body],
        //     page: +page,
        //     totalPages: res.headers.get('x-wp-totalpages')
        //   };
        // }
        return {
          collection: res.body,
          page: +page,
          totalPages: res.headers.get('x-wp-totalpages')
        };
      })
    );
  };

  private _getBaseParams(): HttpParams {
    const baseParams = new HttpParams()
      .append('status', 'publish')
      .append('stock_status', 'instock')
      .append('type', 'simple');
    if (this.translate.currentLang !== this.translate.defaultLang) {
      return baseParams.append('lang', this.translate.currentLang);
    }
    return baseParams;
  }

  private _extractProducts() {
    return map((res: ProductsCustomAPI) => res?.products || []);
  }
}
