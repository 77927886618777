import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { CoreConfigService, HeaderType, MenuItem } from '@tgw-clients/shared';

@Component({
  selector: 'tgw-mobile-menu',
  template: `
    <ng-container *ngIf="type === 'professionals'">
    </ng-container>
    <form class="search-wrapper" [class.b2c-search-wrapper]="isB2cBusinessType" [formGroup]="searchForm" (ngSubmit)="search.next(searchForm.value.search)">
      <fieldset>
        <input formControlName="search" type="text">
        <button type="button" type="submit"><span class="icon" inlineSVG="assets/icons/search.svg"></span></button>
      </fieldset>
    </form>
    <div class="menu-mobile-wrapper">
      <button class="close-button" mat-icon-button (click)="hide.next()"><span class="icon" inlineSVG="assets/icons/close.svg"></span></button>
      <div class="menu-mobile-inner">
        <div class="h6" *ngFor="let item of menu; let i = index; trackBy: trackById" >
          <ng-container *ngIf="item.tgw_slug != 'club'; else club">
            <a [routerLink]="item.tgw_slug" (click)="hide.next()">{{item.title}} </a>
          </ng-container>
          <ng-template #club>
            <img
              [routerLink]="[item.url]"
              class="club"
              src="assets/icons/club_link.svg"
            />
          </ng-template>
         <span *ngIf="item.tgw_slug === 'colecciones'" (click)="displaySubMenu(item.tgw_slug)" [ngClass]="show ? 'desplegado' : 'sin-desplegar'">
          <svg id="Capa_1" data-name="Capa 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13.38 7.4"><title>arrow</title><polyline points="0.35 0.35 6.69 6.69 13.03 0.35" style="fill:none;stroke:#1d1e1c;stroke-miterlimit:10"/></svg>
         </span>
          <div class="submenu" *ngIf="item.tgw_slug === 'colecciones' && show">
            <!-- <tgw-header-items-submenu *ngFor="let subItem of item?.children" [subItem]="subItem" [show]="show" (hideSubMenuOnCheck)="hideSubMenuOnCheck($event)"></tgw-header-items-submenu> -->
            <collections-submenu [items]="item.children" [mobile]="true" (hideMenu)="hideSubMenuOnCheck()"></collections-submenu>
          </div>
        </div>
      </div>
      <div>
        
      <div class="section-login">
        <div *ngIf="!user">
          <a href="/mi-cuenta">
            <p>{{('tgw-header-access' | translate) }} <span>|</span> {{('tgw-header-register' | translate) }}</p>
          </a>
        </div>
        <div *ngIf="user">
          <a (click)="logout.next()">
            {{('tgw-header-logout' | translate) }}
          </a>
        </div>
      </div>
        <!-- <ng-template [ngIf]="!user" [ngIfElse]="loggedIn">
          <div class="logged-in">
            <a [routerLink]="'/mi-cuenta' | localize:lang" (click)="hide.next()">{{('tgw-header-access' | translate) }}</a>
          </div>
          <div class="logged-in">
            <a [routerLink]="'/mi-cuenta/registro' | localize:lang" (click)="hide.next()">{{('tgw-header-register' | translate) }}</a>
          </div>
        </ng-template>
        <ng-template #loggedIn>
          <div class="logged-in">
            <a class="account" [routerLink]="['/mi-cuenta/datos' | localize:lang]" (click)="hide.next()">{{user}} </a><span> /</span><a (click)="logout.next()">{{('tgw-header-logout' | translate) }}</a>
          </div>
        </ng-template> -->
        
        <div class="footer-modal">
          <a href="/contacto">Atención al cliente</a>
          <ul
            *ngIf="languages.length"
            [disabled]="languages.length === 0"
            #langSelector
            (change)="changeLang.next(langSelector.value)"
            class="lang-selector"
          >
            <li
              *ngFor="let language of languages; trackBy: trackByFn"
              [selected]="lang === language"
              [value]="language"
            >
              {{ language | uppercase }}
            </li>
          </ul>
        </div>
      </div>
    </div>

  `,
  styleUrls: ['./mobile-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MobileMenuComponent {
  @Input() menu: MenuItem[];
  @Input() languages: string[];
  @Input() lang: string;
  @Input() type: HeaderType;
  @Input() user: string;
  @Output() hide = new EventEmitter<void>();
  @Output() logout = new EventEmitter<void>();
  @Output() changeLang = new EventEmitter<string>();
  @Output() hideMenu = new EventEmitter();
  @Output() search = new EventEmitter<string>();

  businessType = this._coreConfig.businessType;
  isB2cBusinessType: boolean = this._coreConfig.businessType === 'b2c';
  
  show = false;
  searchForm = new FormGroup({
    search: new FormControl('')
  });
  
  constructor(private store: Store, private _coreConfig: CoreConfigService) {
  }
  

  trackById(_: number, item: MenuItem): number {
    return item.ID;
  }

  trackByFn(_: number, lang: string): string {
    return lang;
  }

  displaySubMenu(tgw_slug: string): void {
    if (tgw_slug === 'colecciones') {
      this.show = this.show ? false : true;
    }
  }

  hideSubMenuOnCheck(): void {
    this.show = false;
    this.hideMenu.next()
  }


}
