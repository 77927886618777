import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { catchError, exhaustMap, filter, map, mapTo, switchMap, withLatestFrom } from 'rxjs/operators';
import { AuthActions, fromAuth } from '../../auth';
import { ProductsActions } from '../actions';
import { Term } from '../models';
import { ProductsService } from '../services/products.service';
import * as fromProducts from './../reducers';

@Injectable()
export class ProductsEffects {

  reloadFeaturedIfNeeded$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.autologinSuccess),
      withLatestFrom(this.store.pipe(select(fromProducts.getFeaturedProducts))),
      filter(([{ user }, featured]) => user?.shippingAddresses?.length === 1 && !!Object.keys(featured).length),
      mapTo(ProductsActions.loadBestSellerProducts()),
    )
  );

  reloadFeaturedOnLogoutIfNeeded$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.logout, AuthActions.forceLogout),
      withLatestFrom(this.store.pipe(select(fromProducts.getFeaturedProducts))),
      filter(([{ }, featured]) => !!Object.keys(featured).length),
      mapTo(ProductsActions.loadBestSellerProducts()),
    )
  );

  reloadCurrentPageIfNeeded$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.autologinSuccess),
      withLatestFrom(this.store.pipe(select(fromProducts.getCurrentPageProducts))),
      filter(([{ user }, currentPageProducts]) => user?.shippingAddresses?.length === 1 && !!currentPageProducts?.length),
      withLatestFrom(this.store.pipe(select(fromProducts.getCurrentPage))),
      map(([[], page]) => ProductsActions.loadProductsPage({ page: `${page}` })),
    )
  );

  reloadCurrentPageOnLogoutIfNeeded$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.logout, AuthActions.forceLogout),
      withLatestFrom(this.store.pipe(select(fromProducts.getCurrentPageProducts))),
      filter(([{ }, currentPageProducts]) => !!currentPageProducts?.length),
      withLatestFrom(this.store.pipe(select(fromProducts.getCurrentPage))),
      map(([[], page]) => ProductsActions.loadProductsPage({ page: `${page}` })),
    )
  );

  loadBestSellerProducts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductsActions.loadBestSellerProducts),
      withLatestFrom(this.store.pipe(select(fromAuth.getShippingAddresses))),
      exhaustMap(([, addresses]) =>
        this._products.getBestSellerProducts(addresses.length === 1 ? addresses[0].state_slug : undefined).pipe(
          map((products) => ProductsActions.saveFeaturedProducts({ products })),
          catchError(() => [ProductsActions.saveFeaturedProducts({ products: [] })])
        )
      )
    )
  );

  loadProductsPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductsActions.loadProductsPage),
      withLatestFrom(
        this.store.pipe(select(fromAuth.getShippingAddresses))
      ),
      switchMap(([{ page: currentPage, queryParamsData, queryParamsSearch }, addresses]) =>
        this._products.getProducts(currentPage, addresses.length === 1 ? addresses[0].state_slug : undefined, queryParamsData, queryParamsSearch).pipe(
          map(({ products, totalPages, page, attributeCounts, found, max_price, min_price, products_ids }) => {
            const filterAttributeCount:any = Object.values(attributeCounts).filter(({show_filter, label}) => !!show_filter || label === 'Bodegas' ) || [];
            return ProductsActions.saveProductsPage({ products, totalPages,  page, attributeCounts: filterAttributeCount, found, max_price, min_price, products_ids })
          }),
          catchError(error => {
            const payload = { products: [], page: +currentPage, totalPages: 0, attributeCounts: [], found : 0, max_price:999, min_price:0, products_ids: [],  error: error?.message || true};
            if (error?.status === 404) {
              payload.error = undefined;
            }
            return [ProductsActions.saveProductsPage(payload)];
          })
        )
      )
    )
  );

  // loadProductsClub$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(ProductsActions.loadProductsClub),
  //     switchMap( ({ page }) =>
  //       this._products.getProductsClub(page).pipe(
  //         map((productPage) => ProductsActions.saveProductsClub({ ...productPage })),
  //         catchError(() => [ProductsActions.saveProductsPage({ products: [], page: +page, totalPages: 0 , attributeCounts: []})])
  //       )
  //     )
  //   )
  // );


  updateCurrentPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductsActions.saveProductsPage),
      map(({ page }) => ProductsActions.updateCurrentPage({ page: +page }))
    )
  );


  loadMonthProducts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductsActions.loadMonthProducts),
      switchMap(() =>
        this._products.getMonthProducts().pipe(
          map((products) => ProductsActions.saveMonthProducts({ products })),
          catchError(() => [ProductsActions.saveMonthProducts({ products: [] })])
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private store: Store,
    private _products: ProductsService
  ) { }
}
