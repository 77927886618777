import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { AuthModule } from '../auth/auth.module';
import { ClubsEffects } from './effects/clubs.effects';
import { ExperiencesEffects } from './effects/experiences.effects';
import { CollectionEffects } from './effects/collection.effects';
import { OffersEffects } from './effects/offers.effects';
import { PacksEffects } from './effects/packs.effects';
import { ProductsEffects } from './effects/products.effects';
import * as fromProducts from './reducers';

@NgModule({
  imports: [
    AuthModule,
    StoreModule.forFeature(fromProducts.goodsKey, fromProducts.reducers),
    EffectsModule.forFeature([ProductsEffects, OffersEffects, PacksEffects, ExperiencesEffects, ClubsEffects, CollectionEffects])
  ]
})
export class ProductsModule { }
