import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanLoad, Route, Router, UrlTree } from '@angular/router';
import { CoreConfigService } from './core-config.service';

@Injectable({ providedIn: 'root' })
export class BusinessTypeGuard implements CanActivate {
  constructor(private _coreConfig: CoreConfigService, private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot): boolean | UrlTree {
    const businessType = this._coreConfig.businessType;
    const { type } = route.data;
    if (type === businessType) {
      return true;
    }
    return this.router.parseUrl('/');
  }
}
