import { Injectable } from '@angular/core';
import { createEffect } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs/operators';
import { RemoteStatusActions } from '../actions';
@Injectable()
export class RemoteStatusEffects {

  toggleMaintenance$ = createEffect(() =>
    this.translate.onLangChange.pipe(
      map(({ translations }) => {
        const hasTranslations = !!Object.keys(translations).length;
        return RemoteStatusActions.setUnderMaintenance({ underMaintenance: !hasTranslations });
      })
    )
  );

  constructor(
    private translate: TranslateService
  ) { }
}
