import { Environment } from '@tgw-clients/shared';

export const environment: Environment = {
  paypalClientId: 'AUp8W2rahGbVJRonNeUU2qsnGMPDCt82o6IYdT7YpdoFmIchYH7kku5AQJVr8WbW9OeUh9A-cXVaF0W9',
  paypalClientIdPass: 'EKk1o9139Ngs_ZhcXEf47rUoZHFHsux859PR5-Osrge3yb6dcTgakf5Kg7lUgHs-JsyXbDN1dJ38CszQ',
  paypalLink: 'https://api-m.sandbox.paypal.com',
  production: false,
  apiBaseEndpoint: 'https://wp-particular.tgw-test.com/wp-json/',
  literalEndpoint: 'https://wp-particular.tgw-test.com/wp-json/tgw/v1/literal',
  imagesEndpoint: 'https://wp-particular.tgw-test.com/wp-content/uploads/sites/4',
  tpvEndpoint: "https://pay.sandbox.realexpayments.com/pay",
  baseEnv: "pre",
  logos: {
    header: 'https://wp-particular.tgw-test.com/wp-content/uploads/sites/4/2021/04/R.A_Monograma.svg',
    footer: 'https://wp-particular.tgw-test.com/wp-content/uploads/sites/4/2021/09/Monograma_white.png',
    white: 'https://wp-particular.tgw-test.com/wp-content/uploads/sites/4/2021/09/Monograma_white.png',
    trust: 'https://wp-particular.tgw-test.com/wp-content/uploads/2020/06/logo-confianza.svg',
    prize: 'https://wp-particular.tgw-test.com/wp-content/uploads/2020/06/mundus-vini.png'
  },
  tags: {
    bestSellers: 545
  },
  categories:{
    wine: 15,
    weeklyPack: 267,
    experience: 1088,
    club: 802
  },
  forms: {
    contact: 3458,
  },
  attributes: {
    vintage: 6,
    originDenomination: 3,
    specials: 29,
    typeOfWine: 24,
    varietyOfGrape: 25
  },
  pages: {
    news: 415,
  },
};
