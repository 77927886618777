import { style } from '@angular/animations';
import {
  ChangeDetectionStrategy,
  Component,
  HostListener
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'tgw-link-footer-tgw',
  template: `
    <div class="row-container container">
      <div class="row-links">
        <tgw-link-section-col
          *ngFor="let section of links"
          [mobile]="mobile"
          [section]="section"
        ></tgw-link-section-col>
      </div>
      <div class="row-security">
        <div class="first-row">
          <img
            src="assets/icons/logo tgw_Mesa de trabajo 1 2.png"
            alt="logo TGW"
          />
        </div>
        <div class="second-row">
          <img src="assets/icons/visa.png" />
        </div>
        <div class="shiping third-row">
          <p>Envio seguro con:</p>
          <img src="assets/icons/DHL.png" />
        </div>
      </div>
    </div>
  `,
  styleUrls: ['./link-footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LinkFooterTGWComponent {
  mobile = false;
  links = [
    {
      title: 'ayuda y contacto',
      icon: 'assets/icons/footer-link-section-help.svg',
      links: [
        {
          title: '¿Donde está mi pedido?',
          link: '/contacto'
        },
        {
          title: 'Mi pedido está dañado',
          link: '/contacto'
        },
        {
          title: 'Cómo hacer mi pedido',
          link: '/contacto'
        },
        {
          title: 'Contactar con atencion al cliente',
          link: '/contacto'
        }
      ]
    },
    {
      title: 'CLUB The grand wines',
      icon: 'assets/icons/tgw-logo-club.svg',
      links: [
        {
          title: 'Cómo funciona nuestro CLUB',
          link: 'https://www.raoriginal.eus/club'
        },
        {
          title: 'Qué ventajas tiene nuestro CLUB',
          link: 'https://www.raoriginal.eus/club'
        },
        {
          title: 'Cómo ser miembro del CLUB',
          link: 'https://www.raoriginal.eus/club'
        }
      ]
    },
    {
      title: 'únete a nosotros',
      icon: 'assets/icons/footer-link-section-group.svg',
      links: [
        {
          title: 'Acerca de nosotros',
          link: 'https://www.raoriginal.eus/que-es-RAOriginal'
        },
        {
          title: 'Soy una bodega',
          link: '/contacto'
        },
        {
          title: 'Tengo un restaurante',
          link: '/contacto'
        }
      ]
    },
    {
      title: 'envío y transporte',
      icon: 'assets/icons/footer-link-section-shipping.svg',
      links: [
        { title: 'Entrega 24/72 horas' },
        { title: 'Devolución gratuita' },
        { title: this.translate.instant('tgw-landing-free-delivery') }
      ]
    }
  ];
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.mobile = event.target.innerWidth <= 991;
  }
  constructor(private translate: TranslateService) {
    this.mobile = window.screen.width <= 991;
  }
}
