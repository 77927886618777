import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { Brands, CoreConfigService } from '../services/core-config.service';

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '[isBrand]'
})
export class IsBrandDirective {
  private brands = [];

  private brand: Brands;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    coreConfig: CoreConfigService) {
    this.brand = coreConfig.brand;
  }

  @Input()
  set isBrand(val: any) {
    if (!Array.isArray(val)) {
      this.brands = [val];
    } else {
      this.brands = val;
    }
    this.updateView();
  }

  private updateView() {
    if (this.checkPermission()) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }

  private checkPermission() {
    let isBrand = false;
    let allNegative = true;

    if (this.brand) {
      isBrand =
        this.brands.filter(app => {
          const t = app === this.brand || (app.indexOf('!') === 0 && app.substr(1) !== this.brand);
          if (t && allNegative && (app.indexOf('!') === -1)) {
            allNegative = false;
          }
          return t;
        }).length > (allNegative ? this.brands.length - 1 : 0);
    }
    return isBrand;
  }
}
