import { ModuleWithProviders, NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { RemoteStatusEffects } from './effects/remote-status.effects';
import * as fromRemoteStatus from './reducers';


@NgModule({
  imports: [
    TranslateModule.forChild(),
    StoreModule.forFeature(fromRemoteStatus.remoteStatusKey, fromRemoteStatus.reducer),
    EffectsModule.forFeature([RemoteStatusEffects])
  ]
})
export class RemoteStatusModule {
  constructor() { }

  public static forRoot(): ModuleWithProviders<RemoteStatusModule> {

    return {
      ngModule: RemoteStatusModule
    };
  }
}
