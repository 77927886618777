import { createReducer, on } from '@ngrx/store';
import { CouponsActions } from '../actions';
import { CouponAPI } from '../models/coupons.model';


export interface State {
  featuredCoupon: CouponAPI;
  pending: boolean;
  error?: string;
}

const initialState: State = {
  featuredCoupon: null,
  pending: false
}

const couponsReducer = createReducer(
  initialState,
  on(CouponsActions.loadFeaturedCoupon, (state) => ({ ...state, pending: true })),
  on(CouponsActions.loadFeaturedCouponSuccess, (state, { coupon }) => ({ ...state, pending: false, featuredCoupon: coupon })),
  on(CouponsActions.loadFeaturedCouponFailure, (state, { error }) => ({ ...state, pending: false, error }))
);

export function reducer(state: State | undefined, action: CouponsActions.CouponsActionsUnion) {
  return couponsReducer(state, action);
}

export const getFeaturedCoupon = (state: State) => state.featuredCoupon;
export const getPending = (state: State) => state.pending;
export const getError = (state: State) => state.error;