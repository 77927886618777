import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { CoreConfigService, Footer, fromRouter, Logo, MenuItem } from '@tgw-clients/shared';
import { map } from 'rxjs/operators';

@Component({
  selector: 'tgw-section-footer-tgw',
  template: `
      <div class="footer-bottom" *ngIf="businessType === 'b2c'">
        <div class="container">
          <div class="footer-legal">
            <a href="/politica-de-privacidad">Política de Privacidad</a>
            <a href="/politica-de-cookies">Política de Cookies</a>
            <a href="/condiciones-generales">Condiciones Generales</a>
            <a href="/bases-legales-proximos-sorteos-rrss">Bases Sorteo</a>
          </div>
          <div class="row comunidad-row">
            <div class="social-media col-md-6 col-12">
              <span class="feel-territory">Comunidad The Grand Wines</span>
              <a href="https://www.facebook.com/RAOriginal/"><span inlineSVG="assets/icons/facebook.svg"></span></a>
              <a href="https://www.instagram.com/raoriginal_/"><span inlineSVG="assets/icons/instagram-footer.svg"></span></a>
              <a href="https://twitter.com/OriginalRioja/"><span inlineSVG="assets/icons/twitter-footer.svg"></span></a>
              <a href="https://www.pinterest.es/RA_Original/"><span inlineSVG="assets/icons/pinterest.svg"></span></a>
            </div>
            <div class="app col-md-3 col-12">
              <div class="app-mobile">
                <p>APP The Grand Wines</p>
                <div>
                  <a href="https://play.google.com/store/apps/details?id=com.tgw.cartadevinos"><img src="assets/icons/googleplay.png"></a>
                  <a href="https://apps.apple.com/es/app/thegrandwines-carta-de-vinos/id1526305006"><img src="assets/icons/appstore.png"></a>
                </div>
              </div>
            </div>
          </div>
          <div class="copyright">
            <span>Copyright © 2022 The Grand Wines. Todos los derechos reservados </span>
          </div>
        </div>
      </div>
  `,
  styleUrls: ['./section-footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SectionTGWFooter {
  @Input() lang: string;
  @Input() menus: Footer;
  @Input() set newsletterPending(pending: boolean) {
    if (pending && this.newsletterEmail.enabled) {
      this.newsletterEmail.disable();
    } else if (!pending && this.newsletterEmail.disabled) {
      this.newsletterEmail.reset({ value: '', disabled: false });
    }
  }
  @Output() subscribe = new EventEmitter<string>();
  @Output() notifyError = new EventEmitter<string>();

  businessType =  this._coreConfig.businessType;
  newsletterEmail = new FormControl('', [Validators.email, Validators.required]);
  privacyCheck = new FormControl(false, [Validators.requiredTrue]);
  constructor(private _coreConfig: CoreConfigService, private store: Store) { }

  subscribeNewsletter(): void {
    if (this.privacyCheck.invalid) {
      this.notifyError.next('tgw-newsletter-privacy-check-required');
      return;
    }
    if (this.newsletterEmail.invalid) {
      const message = this.newsletterEmail.errors?.required ? 'tgw-newsletter-email-required' : 'tgw-form-errors-email';
      this.notifyError.next(message);
      return;
    }

    this.subscribe.next(this.newsletterEmail.value);
  }
}
