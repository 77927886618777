import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { CartActions, CartItem } from '@tgw-clients/shared';

@Component({
  selector: 'unavailable-products-dialog',
  template: `
    <span mat-dialog-close class="icon-close" inlineSVG="assets/icons/close.svg"></span>
   
    <div class="checkbox-wrapper">
      <p mat-dialog-close class="icon" inlineSVG="assets/icons/alert.svg"></p>
      <span class="cabecera">{{'tgw-cart-unavailable-products' | translate}}</span>
      <span class="info">{{'tgw-cart-unavailable-products-info' | translate}}</span>
      <p class="info-movil">{{'tgw-cart-unavailable-products-info-mobile' | translate}}</p>
    </div>
    <button mat-dialog-close (click)="removeProduct()" routerLink="/mi-cuenta/datos/historial" >{{'VOLVER A MIS PEDIDOS' | translate}}</button>
  `,
  styleUrls: ['./unavailable-products-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UnavailableProductsDialogComponent {

  constructor(@Inject(MAT_DIALOG_DATA) public items: CartItem[], private store: Store) { }

  trackBy(_: number, item: CartItem): any {
    return item.product.id + (item.date || '')
  }

  removeProduct(): void {
    this.items.forEach(item =>{
      this.store.dispatch(CartActions.removeProductFromCart({ item }));
    })
  }
}
