import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AuthService } from '../../auth/services/auth.service';
import { Country, ShippingZone, ShippingZoneMethod } from '../models/woocommerce.model';
import { CoreConfigService, EndpointType } from './core-config.service';

@Injectable({
  providedIn: 'root'
})
export class WoocommerceService {
  private _countries: Country[];
  private _shippingZones: ShippingZone[];
  private _shippingZoneMethods: { [zoneId: number]: ShippingZoneMethod[] } = {};

  constructor(private _coreConfig: CoreConfigService, private http: HttpClient, private _auth: AuthService) { }

  getCountries(): Observable<Country[]> {
    if (this._countries) {
      return of(this._countries);
    }
    return this.http.get<Country[]>(`${this._coreConfig.getEndpoint(EndpointType.Woocommerce)}data/countries`).pipe(
      tap((countries) => this._countries = countries)
    );
  }

  getShippingZones(): Observable<ShippingZone[]> {
    if (this._shippingZones) {
      return of(this._shippingZones);
    }
    return this.http.get<ShippingZone[]>(`${this._coreConfig.getEndpoint(EndpointType.Woocommerce)}shipping/zones`, { headers: this._auth.getHeaders() }).pipe(
      tap((shippingZones) => this._shippingZones = shippingZones)
    );
  }

  getShippingZoneMethods(zoneId: number): Observable<ShippingZoneMethod[]> {
    if (this._shippingZoneMethods[zoneId]) {
      return of(this._shippingZoneMethods[zoneId]);
    }
    return this.http.get<ShippingZoneMethod[]>(`${this._coreConfig.getEndpoint(EndpointType.Woocommerce)}shipping/zones/${zoneId}/methods`, { headers: this._auth.getHeaders() }).pipe(
      tap((shippingZoneMethods) => this._shippingZoneMethods[zoneId] = shippingZoneMethods)
    );
  }

  getStates(countryCode: string): Observable<any> {
    return this.http.get<Country[]>(`${this._coreConfig.getEndpoint(EndpointType.Woocommerce)}data/countries/${countryCode}`)
  }
}
