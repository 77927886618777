import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { EMPTY, race } from 'rxjs';
import { catchError, delay, exhaustMap, filter, map, mergeMap, take, takeUntil, tap } from 'rxjs/operators';
import { AuthActions } from '../../auth';
import { ProductsService } from '../../products';
import { BusinessType, CoreConfigService } from '../../utils';
import { CartActions } from '../actions';
import { CartItem, StorageCartItem } from '../models/cart.model';
import * as fromCart from './../reducers';


@Injectable({ providedIn: 'root' })
export class CartEffects {
  loadCartItems$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CartActions.loadCartItems),
      mergeMap(({ items }) =>
        this._products.getProductsById(items.map(({ productId }) => productId)).pipe(
          takeUntil(this.actions$.pipe(ofType(AuthActions.logout, AuthActions.forceLogout), filter(() => this._coreConfig.businessType === BusinessType.B2B))),
          map((products) => {
            const cartItems = products.map((product) => {
              const { productId, ...item } = items.find((item) => product.id === item.productId);
              return { ...item, product };
            });
            return CartActions.addToCartMultiple({ items: cartItems })
          }),
          catchError(() => EMPTY)
        )
      )
    )
  );

  retrieveSavedCart$ = createEffect(() =>
    race(
      this.actions$.pipe(ofType(AuthActions.autologinSuccess, AuthActions.loginSuccess)),
      this.actions$.pipe(ofType(AuthActions.autologin), filter(() => this._coreConfig.businessType === BusinessType.B2C))
    ).pipe(
      take(1),
      map(() => {
        const storageCart: StorageCartItem[] = JSON.parse(localStorage.getItem(this.CART_KEY)) || [];
        if (!storageCart.length) {
          return CartActions.addToCartMultiple({ items: [] });
        }
        return CartActions.loadCartItems({ items: storageCart });
      })
    )
  );

  updateStorageCart$ = createEffect(() =>
    this.store.pipe(
      select(fromCart.getCart),
      filter<CartItem[]>(Boolean),
      tap((cart) => localStorage.setItem(this.CART_KEY, JSON.stringify(cart.map(({ product: { id }, ...item }) => ({ productId: id, ...item })))))
    ), { dispatch: false }
  );

  clearCart$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CartActions.clearCart),
      tap(() => localStorage.removeItem(this.CART_KEY))
    ), { dispatch: false }
  );

  private CART_KEY = `${this._coreConfig.brand}-${this._coreConfig.businessType}-cart`;

  constructor(
    private actions$: Actions,
    private store: Store,
    private _coreConfig: CoreConfigService,
    private _products: ProductsService
  ) { }

}


