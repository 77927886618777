<ng-container *ngIf="spainStates$ | async as spainStates; else loading">
    <div class="global-container">
        <div class="main-container">

            <h2>SOLICITA UNA CONSULTA, GRATIS Y SIN COMPROMISO</h2>
            <div class="cols">
                <div class="col1">
                    <p>La digitalización es un punto clave <br>
                        para el crecimiento y por ello, es el <br>
                        momento de reforzar áreas de tu <br>
                        negocio y optimizar recursos ayudándote<br>
                        a gestionar tu local de un modo más eficiente.
                        <br><br>
                        Te presentamos <br>
                        “Mi carta de Vinos TGW/RA Original”
                    </p>
                </div>

                <div class="col2">
                    <p class="top-p">Analizamos el potencial que esconde tu bodega.<br>
                        Rellena este formulario y nos pondremos en contacto contigo. </p>
                    <form  (ngSubmit)="subscribeB2c()"  [formGroup]="form">
                        <input type="text" id="fname"   formControlName="nameRest" name="fname" placeholder="Nombre del local" required>
                        <div class="form-list">
                            <mat-form-field class="form-list">
                                <mat-label hidden></mat-label>
                                <mat-select disableOptionCentering panelClass="myPanelClass" placeholder="Provincia"  formControlName="province"
                                    floatlLabel="never">
                                    <mat-option *ngFor="let state of spainStates?.states" [value]="state?.name">
                                        {{state?.name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <input type="text" id="fname"   formControlName="name" name="fname" placeholder="Nombre de la persona de contacto" required>
                        <input type="email" id="fname"   formControlName="email" name="fname" placeholder="Email" required>
                        <input type="tel" id="fname"   formControlName="telefon" name="fname" placeholder="Teléfono" required>
                        <input  value="ENVIAR" type="submit" class="submit-form-button">
                    </form>
                    <p>Al rellenar dicho formulario, aceptas nuestros <a href="">Términos y condiciones</a> , la <a
                            href="">Política de privacidad</a> y la <a href="">Política de revocación.</a></p>
                </div>
            </div>
        </div>
    </div>
</ng-container>
<ng-template #loading>
    <div class="spinner-wrapper">
        <mat-spinner></mat-spinner>
    </div>
</ng-template>