import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NavigationEnd, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { CartItem, CoreConfigService, fromAuth, HeaderType, LayoutActions, Logo, menuAnimation, MenuItem, Product, RouterActions } from '@tgw-clients/shared';
import { Observable } from 'rxjs';

@Component({
  selector: 'tgw-header',
  template: `
    <ng-container *ngTemplateOutlet="header"></ng-container>
    <ng-container *ngIf="scrolled">
      <ng-container *ngTemplateOutlet="header; context:{ $implicit: true }"></ng-container>
    </ng-container>
    <ng-template #header let-scrolled>
      <header [ngClass]="type" [class.scrolled]="scrolled">
        <div class="container-fluid">
          <mat-toolbar>
            <mat-toolbar-row #mainRow class="header-actions">
              <ng-template [ngIf]="type === 'professionals'">
                <button class="mobile-menu-button" mat-icon-button (click)="openMenu()"><span class="icon" inlineSVG="assets/icons/menu.svg"></span></button>
                <tgw-mobile-menu class="mobile-menu" *ngIf="showMenu" (hideMenu)="hideMenu()" [languages]="languages" [lang]="lang" [type]="type" [user]="username" [menu]="menu" (hide)="hideMenu()" (changeLang)="changeLang.next($event)" (logout)="logout.next()" (search)="searchSubmit($event)" @menu></tgw-mobile-menu>
              </ng-template>
              <div class="professionals-logo"  *ngIf="type === 'professionals'">
                <a *ngIf="isPrincipalPage; else redirect" [routerLink]="['/' | localize:lang]">
                  <span [inlineSVG]="logo" alt="The Grand Wines"></span>
                </a>
              </div>
              <div class="header-top-nav">
                <div class="user-section">
                  <ng-template [ngIf]="!username" [ngIfElse]="loggedIn">
                    <a (click)="goTo('/mi-cuenta')"><span>{{('tgw-header-access' | translate) | uppercase}}</span><span class="icon" inlineSVG="assets/icons/login.svg"></span></a><span>/</span>
                    <a (click)="goTo('/mi-cuenta/registro')"><span>{{('tgw-header-register' | translate) | uppercase}}</span><span class="icon" inlineSVG="assets/icons/register.svg"></span></a>
                  </ng-template>
                  <ng-template #loggedIn>
                    <span class="account" [routerLink]="['/mi-cuenta/datos' | localize:lang]"><span>{{username | uppercase}}</span> <span class="icon" inlineSVG="assets/icons/user.svg"></span></span><span> /</span>
                  </ng-template>
                </div>
                <select #langSelector (change)="changeLang.next(langSelector.value)" class="lang-selector">
                    <option *ngFor="let language of languages; trackBy: trackByFn" [selected]="lang === language" [hidden]="lang === language"  [value]="language">{{language | uppercase}}</option>
                </select>
                <form *isBusinessType="'!winery'" class="search-wrapper hidden-sm-down" [formGroup]="searchForm" (ngSubmit)="searchSubmit(searchForm.value.search)">
                  <fieldset>
                    <input formControlName="search" type="text">
                    <button type="button" type="submit"><span class="icon" inlineSVG="assets/icons/search.svg"></span></button>
                  </fieldset>
                </form>
                <ng-template [ngIf]="type === 'professionals'">
                  <div class="icon cart-icon hidden-sm-down" (mouseenter)="showCart(cart)">
                    <span inlineSVG="assets/icons/cesta.svg"></span>
                    <span class="cartCount">{{getCartCount(cart)}}</span>
                  </div>
                  <div class="icon cart-icon mobile-cart hidden-md-up" (click)="toggleCart(cart)">
                    <span inlineSVG="assets/icons/cesta.svg"></span>
                    <span class="cartCount">{{getCartCount(cart)}}</span>
                  </div>
                  <tgw-cart-profesionales *ngIf="isCartVisible && !['b2c'].includes(businnesType)" [cart]="cart" (delete)="removeCartProduct.next($event)" (mouseleave)="hideCart()" (closeCart)="hideCart()" [isLoggedIn]="isLoggedIn$ | async"></tgw-cart-profesionales>
                  <tgw-cart *ngIf="isCartVisible && ['b2c'].includes(businnesType)" [cart]="cart" (delete)="removeCartProduct.next($event)" (mouseleave)="hideCart()" (closeCart)="hideCart()" [isLoggedIn]="isLoggedIn$ | async"></tgw-cart>
                </ng-template>
              </div>
            </mat-toolbar-row>
            <mat-toolbar-row class="header-intro">
              <div>
                <a *ngIf="isPrincipalPage; else redirect" [routerLink]="['/' | localize:lang]">
                  <span [inlineSVG]="logo" alt="The Grand Wines"></span>
                </a>
                <ng-template #redirect>
                  <a *ngIf="!isPrincipalPage" href="https://www.raoriginal.eus/">
                    <span [inlineSVG]="logo" [alt]="brand"></span>
                  </a>
                </ng-template>
              </div>
              <div *ngIf="type === 'landing'">
                <h2>{{('tgw-winery-header-title' | translate) | uppercase }}</h2>
                <h3>{{'tgw-winery-header-subtitle' | translate}}</h3>
              </div>
              <ng-container *isBrand="'tgw'">
                <img *ngIf="type === 'landing'" class="img-prize" [src]="prizeLogo"/>
              </ng-container>
            </mat-toolbar-row>
            <mat-toolbar-row *ngIf="type === 'professionals' && menu?.length" class="menu-row">
              <div class="menu-wrapper">
                <div *ngFor="let item of menu; let i = index; trackBy: trackById">
                  <ng-template [ngIf]="item.children?.length" [ngIfElse]="linkItem">
                    <a (mouseenter)="scrolled ? showSubmenuScrolled = true : showSubmenu = true" (mouseleave)="hideSubmenu($event)" (click)="scrolled ? showSubmenuScrolled = false : showSubmenu = false" [routerLink]="item.tgw_slug" routerLinkActive="current">{{item.title}}</a>
                    <ng-container *ngIf="!scrolled; else scrolledSubmenu">
                      <div #submenu (mouseleave)="showSubmenu = false" [class.displayed]="showSubmenu" class="div-menu-submenus">
                        <div class="submenu-section" *ngFor="let subItem of item?.children" >
                          <p>{{subItem.title}}<p>
                          <tgw-header-submenu (hideSubmenuOnClick)="showSubmenu = false" [show]="show" [menuId]="subItem?.tgw_id" [slug]="subItem?.title" ></tgw-header-submenu>
                        </div>
                      </div>
                    </ng-container>
                    <ng-template #scrolledSubmenu>                   
                      <div #submenuScrolled (mouseleave)="showSubmenuScrolled = false" [class.displayed]="showSubmenuScrolled" class="div-menu-submenus">
                        <div class="submenu-section" *ngFor="let subItem of item?.children" >
                          <p>{{subItem.title}}<p>
                          <tgw-header-submenu (hideSubmenuOnClick)="showSubmenuScrolled = false" [show]="show" [menuId]="subItem?.tgw_id" [slug]="subItem?.title" ></tgw-header-submenu>
                        </div>
                      </div>
                    </ng-template>
                  </ng-template>
                  <ng-template #linkItem>
                    <ng-template [ngIf]="item.tgw_slug" let-slug [ngIfElse]="disabled">
                      <a (mouseenter)="scrolled ? showSubmenuScrolled = false : showSubmenu = false" [routerLink]="slug" routerLinkActive="current">{{item.title}}</a>
                    </ng-template>
                    <ng-template #disabled>
                      <a class="disabled">{{item.title}}</a>
                    </ng-template>
                  </ng-template>
                </div>
              </div>
            </mat-toolbar-row>
          </mat-toolbar>
        </div>
      </header>
    </ng-template>
  `,
  styleUrls: ['./header.component.scss'],
  host: {'[class.clubrao]':'memberRao'},
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [menuAnimation]
})
export class HeaderComponent implements OnChanges {
  @ViewChild('mainRow') mainRow: ElementRef;
  @ViewChild('submenu') submenu: ElementRef;
  @ViewChild('submenuScrolled') submenuScrolled: ElementRef;
  @Input() lang: string;
  @Input() menu: MenuItem[];
  @Input() cart: CartItem[] = [];
  @Input() type: HeaderType;
  @Input() username: string;

  @Output() removeCartProduct = new EventEmitter<CartItem>();
  @Output() logout = new EventEmitter<void>();
  @Output() changeLang = new EventEmitter<string>();

  isLoggedIn$: Observable<boolean> = this.store.pipe(select(fromAuth.isLoggedIn));
  show = false
  showMenu = false;
  showSubmenu = false;
  showSubmenuScrolled = false;
  isCartVisible = false;
  isPrincipalPage = window.location.hostname === 'www.raoriginal.eus';
  logo = this._coreConfig.getLogo(Logo.Header);
  prizeLogo = this._coreConfig.getLogo(Logo.Prize);
  languages = this.translate.langs;
  scrolled = false;
  brand = this._coreConfig.brand;
  businnesType = this._coreConfig.businessType;

  searchForm = new FormGroup({
    search: new FormControl('')
  });

  constructor(
    private _coreConfig: CoreConfigService,
    private translate: TranslateService,
    private store: Store,
    private router: Router,
    private elRef: ElementRef
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.cart && !changes.cart.currentValue?.length && this.isCartVisible) {
      this.isCartVisible = false;
    }
  }

  toggleCart(cart: CartItem[]): void {
    if (!this.isCartVisible && this.getCartCount(cart) < 1) {
      return;
    }
    this.isCartVisible = !this.isCartVisible;
    this.toggleBodyClass(this.isCartVisible ? 'add' : 'remove');
  }

  showCart(cart: CartItem[]): void {
    if (!this.isCartVisible && 0 < this.getCartCount(cart)) {
      this.isCartVisible = true;
      this.toggleBodyClass('add');
    }
  }

  hideMenu(): void {
    this.showMenu = false;
    this.toggleBodyClass('remove');
  }

  openMenu(): void {
    this.showMenu = true;
    this.toggleBodyClass('add');
  }

  hideCart(): void {
    this.isCartVisible = false;
    this.toggleBodyClass('remove');
  }

  toggleBodyClass(opt: 'add' | 'remove'): void {
    document.body.classList[opt]('no-scroll');
  }

  getCartCount(cart: { product: Product; quantity: number }[]): number {
    return (cart || []).reduce((acc, { quantity }) => acc + quantity, 0);
  }

  trackById(_: number, item: MenuItem): number {
    return item.ID;
  }

  trackByFn(_: number, lang: string): string {
    return lang;
  }

  goTo(path: string): void {
    if (this.type === 'professionals') {
      this.store.dispatch(RouterActions.navigateTo({ path }));
    } else {
      this.store.dispatch(LayoutActions.openRedirectDialog({ path }));
    }
  }

  hideSubmenu(event: MouseEvent): void {
    if (this.scrolled) {
      if (!(<Element>event.relatedTarget)?.isSameNode(this.submenuScrolled.nativeElement)) {
        this.showSubmenuScrolled = false;
      }
    } else {
      if (!(<Element>event.relatedTarget)?.isSameNode(this.submenu.nativeElement)) {
        this.showSubmenu = false;
      }
    }
    
  }

  searchSubmit(searchText: string): void {
    if (searchText) {
      this.router.navigate(['/tienda/1'], { queryParams: { s: searchText } });
      this.showMenu = false;
    }
  }

  @HostListener('window:scroll', ['$event.target'])
  onScroll(target: Document): void {
    if (((this._coreConfig.businessType !== 'winery' && this.elRef.nativeElement.offsetHeight) || this.mainRow.nativeElement.offsetHeight) < target.scrollingElement.scrollTop) {
      this.scrolled = true;
    } else {
      this.scrolled = false;
    }
  }
}
