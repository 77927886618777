import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';

@Injectable()
export class ErrorHandlerService {

  constructor() { }

  public notify({ message, title }: { message: string, title?: string }, messageType?: 'error' | 'success' | 'warning'): Observable<any> {

    if (messageType === 'error') {
      console.error(`%c Notifier: ${message}`, 'background: #f00; color: #fff; padding: 4px; font-weight: 600;');
    }

    return throwError('Method not implemented');
  }

}
