import { createAction, props, union } from '@ngrx/store';
import { Collection, ProductCategory } from '../models/index';

export const loadCollection= createAction('[Collection] Load Collection', props<{ page: string, id?: number, postId?:string }>());
export const saveCollection= createAction('[Collection] Save Collection', props<{ page: number, collection: Collection[], totalPages: number, id?: number, postId?:string }>());
export const loadCollectionCategories = createAction('[Collection] Load CollectionCategories ', props<{}>());
export const saveCollectionCategories = createAction('[Collection] Save CollectionCategories', props<{ categories: ProductCategory[]}>());
export const loadCollectionSearch = createAction('[Collection] Load Collection', props<{ search: string}>());
export const saveCollectionSearch = createAction('[Collection] Save Collection', props<{ search: string, collection: Collection[]}>());

export const loadCollectionFailure = createAction('[Collection] Load Collectionfailure');


const all = union({
  loadCollection,
  saveCollection,
  loadCollectionCategories,
  saveCollectionCategories
});

export type CollectionActionsUnion = typeof all;
