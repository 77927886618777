import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NavigationEnd, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { menuAnimation, MenuItem, CartItem, HeaderType, fromAuth, Logo, CoreConfigService, Product, RouterActions } from '@tgw-clients/shared';
import { fromRouter } from '@tgw-clients/shared/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'header-tgw',
  templateUrl: './header-tgw.component.html',
  styleUrls: ['./header-tgw.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [menuAnimation]
})
export class HeaderTgwComponent implements OnChanges {
  @ViewChild('mainRow') mainRow: ElementRef;
  @ViewChild('submenu') submenu: ElementRef;
  @ViewChild('submenuScrolled') submenuScrolled: ElementRef;
  @ViewChild('collectionsMenuElement') collectionsMenuElement: ElementRef;
  @ViewChild('collectionsScrolledElement') collectionsScrolledElement: ElementRef;
  @Input() lang: string;
  @Input() menu: MenuItem[];
  @Input() cart: CartItem[] = [];
  @Input() type: HeaderType;
  @Input() username: string;

  @Output() removeCartProduct = new EventEmitter<CartItem>();
  @Output() logout = new EventEmitter<void>();
  @Output() changeLang = new EventEmitter<string>();

  isLoggedIn$: Observable<boolean> = this.store.pipe(
    select(fromAuth.isLoggedIn)
  );
  user$ = this.store.pipe(select(fromAuth.getUser));
  show = false;
  showMenu = false;
  showCollectionsMenu = false;
  showSubmenu = false;
  showCollectionsScrolled = false;
  showSubmenuScrolled = false;
  isCartVisible = false;
  memberRao: boolean = false;
  logo = this._coreConfig.getLogo(Logo.Header);
  prizeLogo = this._coreConfig.getLogo(Logo.Prize);
  languages = this.translate.langs;
  scrolled = false;
  brand = this._coreConfig.brand;
  auxMenu: MenuItem[] = [];
  businnesType = this._coreConfig.businessType;
  logueado: boolean = false;
  isB2cBusinessType: boolean = this._coreConfig.businessType === "b2c";
  isLanding$ = this.store.pipe(
    select(fromRouter.selectUrl),
    map(url => url == '/')
  );

  searchForm = new FormGroup({
    search: new FormControl('')
  });

  mobile;


  constructor(
    private _coreConfig: CoreConfigService,
    private translate: TranslateService,
    private store: Store,
    public router: Router,
    private elRef: ElementRef,
    private cdr: ChangeDetectorRef
  ) {
    this.isLoggedIn$.subscribe(loggedIn => (this.logueado = loggedIn));
    this.user$.subscribe(
      info =>
      this.memberRao = (info && info.rao_club && info.rao_club === '1' && ['b2c'].includes(this.businnesType))
      );
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          this.cdr.detectChanges();
        }
      });
  }


  ngOnChanges(changes: SimpleChanges): void {
    this.auxMenu = [];
    if (this.menu && this.menu.length > 0) {
      this.auxMenu = this.menu;
    }
    if (
      changes.cart &&
      !changes.cart.currentValue?.length &&
      this.isCartVisible
    ) {
      this.isCartVisible = false;
    }
    this.cdr.detectChanges();
  }

  ngOnInit(): void {
    this.mobile = window.screen.width <= 768;
    this.cdr.detectChanges();
  }

  toggleCart(cart: CartItem[]): void {
    if (!this.isCartVisible && this.getCartCount(cart) < 1) {
      return;
    }
    this.isCartVisible = !this.isCartVisible;
  }

  showCart(cart: CartItem[]): void {
    if (!this.isCartVisible && 0 < this.getCartCount(cart)) {
      this.isCartVisible = true;
    }
  }

  hideMenu(): void {
    this.showMenu = false;
  }

  openMenu(): void {
    this.showMenu = true;
  }



  hideCart(): void {
    this.isCartVisible = false;
  }

  getCartCount(cart: { product: Product; quantity: number }[]): number {
    return (cart || []).reduce((acc, { quantity }) => acc + quantity, 0);
  }

  trackById(_: number, item: MenuItem): number {
    if (item.ID) return item.ID;
  }

  trackByFn(_: number, lang: string): string {
    return lang;
  }

  goTo(path: string, cartCount: number): void {
    if (cartCount > 0) {
      this.store.dispatch(RouterActions.navigateTo({ path }));
    }
  }

  hideCollectionsMenu(ev: boolean){
    this.showCollectionsScrolled = false;
    this.showCollectionsMenu = false;
  }

  hideSubmenu(event: MouseEvent): void {
    if (this.scrolled) {
      if (
        !(<Element>event.relatedTarget)?.isSameNode(
          this.submenuScrolled.nativeElement
        )
      ) {
        this.showSubmenuScrolled = false;
      } else if(!(<Element>event.relatedTarget)?.isSameNode(this.collectionsScrolledElement.nativeElement)){
        this.showCollectionsScrolled = false;
      }
    } else {
      if (
        !(<Element>event.relatedTarget)?.isSameNode(this.submenu.nativeElement)
      ) {
        this.showSubmenu = false;
      } else if(!(<Element>event.relatedTarget)?.isSameNode(this.collectionsMenuElement.nativeElement)){
        this.showCollectionsMenu = false;
      }
    }
  }

  searchSubmit(searchText: string): void {
    if (searchText) {
      this.router.navigate(['/tienda/1'], { queryParams: { s: searchText } });
      this.showMenu = false;
    }
  }

  isOnLandingPage(): boolean {
    return this.router.url === '/';
  }

  @HostListener('window:scroll', ['$event.target'])
  onScroll(target: Document): void {
    if (
      ((this._coreConfig.businessType !== 'winery' &&
        this.elRef.nativeElement.offsetHeight) ||
        this.mainRow.nativeElement.offsetHeight) <
      target.scrollingElement.scrollTop
    ) {
      this.scrolled = true;
    } else {
      this.scrolled = false;
    }
  }
}
