import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AuthService, CartActions, CartItem, CoreConfigService, Product, TrackerService } from '@tgw-clients/shared';
import { SwiperOptions } from 'swiper';

@Component({
  selector: 'products-swiper',
  templateUrl: './products-swiper.component.html',
  styleUrls: ['./products-swiper.component.scss']
})
export class ProductsSwiperComponent implements OnInit {

  @Input() products: Product[];
  memberRao: boolean = false;
  businnesType = this._coreConfig.businessType;

  configSwiperMobile: SwiperOptions = {
    // centeredSlides: true,
    // centeredSlidesBounds: true,
    centerInsufficientSlides: true,
    // freeMode: true,
    scrollbar: false,
    spaceBetween: 20,
    pagination: false,
    slideActiveClass: 'swiper-slide-active',
    
    navigation: true,
    slidesPerView: "auto",
    grabCursor: false,
    
    breakpoints: {
      720:{
        navigation: false,
        slidesPerView: 3,
        grabCursor: true
      },
      1300:{
        navigation: false,
        slidesPerView: 4,
        grabCursor: true
      },
      1800:{
        navigation: false,
        slidesPerView: 5,
        grabCursor: true
      }
    }
  };
  currentIndex = 0;

  constructor(private store: Store, private _coreConfig: CoreConfigService, private authService: AuthService, private trackerService: TrackerService) { }

  ngOnInit(): void {
  }

  setIndex(index: number) {
    this.currentIndex = index;
  }
  
  addToCart({ product, quantity }: CartItem): void {
    this.store.dispatch(CartActions.addToCart({ product, quantity }));
    if (this.businnesType == "b2c") {
      let payload:any = {};
      payload.eventName = "AddToCart";
      payload.contentId = product.id;
      payload.price = product.price;
      if (this.authService.hasToken()) {
        this.authService.getUserInfo().subscribe((data:any)=> {
          payload.firstName = data.first_name;
          payload.lastName = data.last_name;
          payload.email = data.email
          this.trackerService.sendData({
            service: "facebook",
            payload
          }).subscribe();
        });
      } else {
        payload.firstName = "";
        payload.lastName = "";
        payload.email = "";
        this.trackerService.sendData({
          service: "facebook",
          payload
        }).subscribe();
      }
    }
  }

}
