import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { CollectionActions } from '../actions';
import { ProductsService } from '../services/products.service';



@Injectable()
export class CollectionEffects {

  loadCollection$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CollectionActions.loadCollection),
      switchMap(({ page, id, postId }) =>
        this.product.getAllCollection(page, id, postId).pipe(
          map(({ collection, page, totalPages}) => CollectionActions.saveCollection({ collection, page, totalPages,id,postId })),
          catchError(() => [CollectionActions.loadCollectionFailure()])
        )
      )
    )
  );





  constructor(
    private actions$: Actions,
    private product: ProductsService,
  ) { }
}
