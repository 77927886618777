import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { TranslateModule } from '@ngx-translate/core';
import { AuthModule, LayoutModule, ProductsModule, WineriesModule } from '@tgw-clients/shared';
import { CartWebModule } from '../cart-web';
import { SharedModule } from '../shared';
import { BannerToRegisterComponent } from './components/banner-to-register.component';
import { FooterComponent } from './components/footer.component';
import { HeaderItemsSubmenuComponent } from './components/header-items-submenu.component';
import { HeaderSubmenuComponent } from './components/header-submenu.component';
import { HeaderComponent } from './components/header.component';
import { MobileMenuComponent } from './components/mobile-menu.component';
import { RedirectDialogComponent } from './components/redirect-dialog.component';
import { LayoutComponent } from './containers/layout.component';
import { LayoutWebEffects } from './effects/layout-web.effects';
import { CouponsModule } from '@tgw-clients/shared/coupons/coupons.module';
import { HeaderCustomerComponent } from './components/header-customer.component';
import { SectionFooter } from './components/footer/section-footer.component';
import { LinkFooterComponent } from './components/footer/link-footer.component';
import { ClubFooterComponent } from './components/footer/club-footer.component';
import { OldSectionFooterComponent } from './components/footer/old-section-footer.component';
import { LinkSectionColComponent } from './components/footer/link-section-col.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CollectionsSubmenuComponent } from './components/collections-submenu/collections-submenu.component';
import { HeaderTgwComponent } from './components/header-tgw/header-tgw.component';
import { ClubFooterTGWComponent } from './components/footer-tgw/club-footer.component';
import { LinkFooterTGWComponent } from './components/footer-tgw/link-footer.component';
import { OldSectionFooterTGWComponent } from './components/footer-tgw/old-section-footer.component';
import { SectionTGWFooter } from './components/footer-tgw/section-footer.component';
import { LinkSectionColTGWComponent } from './components/footer-tgw/link-section-col.component';
import { RAOLandingFormComponent } from './components/footer/rao-landing-form/rao-landing-form.component';
import { LandingModule } from '@tgw-clients/tgw-b2c/modules/landing/landing.module';
import { SearchComponent } from './components/mobile/search.component';
import {MatSelectModule} from '@angular/material/select';
import { RaoHeaderComponent } from './components/rao-header/rao-header.component'
import { RaoFooterComponent } from './components/footer/rao-footer/rao-footer.component';
import { RaoLinkFooterComponent } from './components/footer/rao-link-footer/rao-link-footer.component';


@NgModule({
  declarations: [
    LayoutComponent,
    HeaderComponent,
    HeaderCustomerComponent,
    HeaderSubmenuComponent,
    FooterComponent,
    ClubFooterComponent,
    LinkFooterComponent,
    OldSectionFooterComponent,
    LinkSectionColComponent,
    SectionFooter,
    MobileMenuComponent,
    RedirectDialogComponent,
    HeaderItemsSubmenuComponent,
    BannerToRegisterComponent,
    CollectionsSubmenuComponent,
    HeaderTgwComponent,
    ClubFooterTGWComponent,
    OldSectionFooterTGWComponent,
    SectionTGWFooter,
    LinkSectionColTGWComponent,
    RAOLandingFormComponent,
    LinkFooterTGWComponent,
    SearchComponent,
    RaoHeaderComponent,
    RaoFooterComponent,
    RaoLinkFooterComponent
  ],
  imports: [
    CouponsModule,
    LayoutModule,
    TranslateModule.forChild(),
    CartWebModule,
    AuthModule,
    RouterModule,
    ProductsModule,
    WineriesModule,
    EffectsModule.forFeature([LayoutWebEffects]),
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    MatSelectModule
  ],
  exports: [LayoutComponent,SearchComponent]
})
export class LayoutWebModule { }
