import { createAction, props, union } from '@ngrx/store';
import { OrderAPI, Payment, PaymentMethod, ShippingAddress } from '@tgw-clients/shared/auth';
import { CartItem } from '@tgw-clients/shared/cart';
import { Coupon, CouponAPI } from '@tgw-clients/shared/coupons';
import { ShippingZoneMethod } from '@tgw-clients/shared/utils';
import { DeliveryInfo, PurchaseSteps } from '../models/purchase.models';

/* Update order info */
export const updateUnavailableExperiences = createAction('[Purchase] Update unavailable experiences', props<{ experiences: { name: string; date: string; }[]; }>());
export const updateDeliveryInfo = createAction('[Purchase] Update delivery info', props<DeliveryInfo>());
export const updatePaymentInfo = createAction('[Purchase] Update payment info', props<Payment>());
export const selectAddresses = createAction('[Purchase] Select address', props<{ addressesIds: number[]; }>());
export const reset = createAction('[Purchase] Reset');

/* Navigate through process */
export const nextStep = createAction('[Purchase] Next step');
export const updateStep = createAction('[Purchase] Update step', props<{ step: number; }>());
export const completeStep = createAction('[Purchase] Complete step', props<{ step: number; }>());
export const goToStep = createAction('[Purchase] Go to step', props<{ step: PurchaseSteps; }>());

/* Shipping methods */
export const getShippingMethods = createAction('[Purchase] Calculate shipping methods', props<{ addresses: number[]; cart: CartItem[]; coupons: CouponAPI[]; }>());
export const updateShippingMethods = createAction('[Purchase] Update shipping methods', props<{ [id: number]: ShippingZoneMethod }>());

/* Order */
export const makeAnOrder = createAction('[Purchase] Make an order');
export const orderPlaced = createAction('[Purchase] Order placed', props<{ paymentMethod: PaymentMethod; orders: OrderAPI[]; }>());
export const orderFailure = createAction('[Purchase] Order failure', props<{ error: string; failedAddresses?: ShippingAddress[]; }>());

/* Coupons */
export const validateCoupon = createAction('[Purchase] Validate coupon', props<{ code: string; }>());
export const addCoupons = createAction('[Purchase] Add coupons', props<{ coupons: CouponAPI[] }>());
export const removeCoupon = createAction('[Purchase] Remove coupon', props<{ coupon: Coupon }>());
export const notifyInvalidCoupon = createAction('[Purchase] Notify invalid coupon', props<{ message: string; }>());


const all = union({
  updateUnavailableExperiences,
  updateDeliveryInfo,
  updatePaymentInfo,
  selectAddresses,
  nextStep,
  updateStep,
  goToStep,
  reset,
  completeStep,
  makeAnOrder,
  orderPlaced,
  orderFailure,
  validateCoupon,
  addCoupons,
  removeCoupon,
  notifyInvalidCoupon
});

export type PurchaseActionsUnion = typeof all;
