import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'tgw-cart-redirect-dialog',
  template: `
    <span mat-dialog-close class="icon-close" inlineSVG="assets/icons/close.svg"></span>
    <span class="icon" inlineSVG="assets/icons/alert.svg"></span>
    <div class="checkbox-wrapper">
      <p>
        {{ 'tgw-cart-redirect-dialog-message-1' | translate }} 
        <a mat-dialog-close="true" class="link" routerLink="/mi-cuenta">{{'tgw-cart-redirect-dialog-link' | translate}}</a>
        {{ 'tgw-cart-redirect-dialog-message-2' | translate }}
        <br>
        {{ 'tgw-cart-redirect-dialog-message-3' | translate }}
        <a mat-dialog-close="true" class="link" routerLink="/mi-cuenta/registro">{{'tgw-cart-redirect-dialog-link' | translate }}.</a>
      </p>
    </div>
    
    <button mat-dialog-close="true">{{'tgw-commons-accept' | translate}}</button>
  `,
  styleUrls: ['./cart-redirect-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CartRedirectDialogComponent {

  constructor() { }
}
