import { ChangeDetectionStrategy, Component, HostListener } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'tgw-link-footer',
  template: `
    <div class="row-container container">
      <div class="row-links">
        <tgw-link-section-col *ngFor="let section of links" [mobile]="mobile" [section]="section"></tgw-link-section-col>
      </div>
      <div class="row-security">
        <div class="blank"></div>
        <img src="assets/icons/visa.png" >
        <div class="shiping">
          <p>Envio seguro con:</p>
          <img src="assets/icons/DHL.png">
        </div>
      </div>
    </div>

  `,
  styleUrls: ['./link-footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LinkFooterComponent {
  mobile = false
  links = [
    {title: 'ayuda y contacto',  icon: 'assets/icons/footer-link-section-help.svg', links:[{title: '¿Donde está mi pedido?', link: 'https://www.raoriginal.eus/contacto'}, {title:'Mi pedido está dañado', link: 'https://www.raoriginal.eus/contacto'}, {title:'Cómo hacer mi pedido', link: 'https://www.raoriginal.eus/contacto'}, {title: 'Contactar con atencion al cliente', link:'https://www.raoriginal.eus/contacto'}]},
    {title: 'club ra original', icon: 'assets/icons/footer-link-section.svg', links: [{title: 'Cómo funciona nuestro CLUB', link: 'https://www.raoriginal.eus/club'}, {title: 'Qué ventajas tiene nuestro CLUB', link:'https://www.raoriginal.eus/club'}, {title: 'Cómo ser miembro del CLUB', link: 'https://www.raoriginal.eus/club'}]},
    {title: 'únete a nosotros',  icon: 'assets/icons/footer-link-section-group.svg', links:[{title: 'Acerca de nosotros', link: 'https://www.raoriginal.eus/que-es-RAOriginal'}, {title: 'Soy una bodega', link: 'https://www.raoriginal.eus/contacto'}, {title: 'Tengo un restaurante', link: 'https://www.raoriginal.eus/contacto'}]},
    {title: 'envío y transporte',  icon: 'assets/icons/footer-link-section-shipping.svg', links:[{title: 'Entrega 24/72 horas'}, {title: 'Devolución gratuita'}, {title:  this.translate.instant('tgw-landing-free-delivery') },{title: 'DHL'}]}
  ]
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.mobile = event.target.innerWidth <= 991
  }
  constructor(private translate: TranslateService) {
    this.mobile = window.screen.width <= 991
  }

}
