import { Product } from '@tgw-clients/shared/products';
import { DateField, MetaData, ShippingZoneMethod } from '../../utils';
import { CouponAPI } from './../../coupons';

export interface Credentials {
  username: string;
  password?: string;
  remember?: boolean;
}

export enum Role {
  Admin = 'administrator',
  Customer = 'customer',
  manager = 'shop_manager'
}

export interface ShippingAddress {
  id?: number;
  customer_id: string;
  first_name: string;
  last_name: string;
  company: string;
  address_1: string;
  address_2: string;
  state: string;
  city: string;
  country: string;
  postcode: string;
  email: string;
  phone?: string;
  state_slug: string;
  nid?: string;
}

export interface BillingAddress {
  first_name: string;
  last_name: string;
  company: string;
  address_1: string;
  address_2: string;
  state: string;
  city: string;
  country: string;
  postcode: string;
  email: string;
  phone?: string;
  state_slug: string;
}

export interface BillingInfo extends BillingAddress {
  id?: number;
  nid?: string;
}

export interface AccountInfo {
  id?: number;
  first_name?: string;
  last_name?: string;
  email?: string;
  iban?: string;
  gender?: string;
  dob?: string;
  phone2?: string;
  currentPassword?: string;
  newPassword?: string;
}


export interface WarehouseProduct {
  product: Product;
  quantity: string;
  low_stock_qty: string;
  processing: string;
  order_qty: string;
  address_id: string;
  winery_title?: string;
}

export interface Warehouses {
  [addressId: string]: WarehouseProduct[];
}

export interface User extends AccountInfo {
  id_document_number?: string;
  business_name?: string;
  billingAddress?: BillingAddress;
  shippingAddresses?: ShippingAddress[];
  warehouses?: Warehouses;
  orders?: Order[];
  role?: Role;
  methodsPerAddress?: { [id: string]: ShippingZoneMethod[] };
  rappel?: number;
  rao_club?: string;
  substatus?: string;
}

export interface LoginApiResponse {
  wp_user: {
    data: {
      ID: string;
      user_login: string;
      user_pass: string;
      user_nicename: string;
      user_email: string;
      user_url: string;
      user_registered: string;
      user_activation_key: string;
      user_status: string;
      display_name: string;
      spam: string;
      deleted: string;
    };
    ID: number;
    caps: {
      customer: boolean;
    };
    cap_key: string;
    roles: Array<string>;
    allcaps: {
      read: boolean;
      customer: boolean;
    };
    filter: any;
  };
  access_token: string;
  expires_in: number,
  refresh_token: string;
}

export interface CustomerApiResponse {
  id: number;
  date_created: DateField;
  date_modified: DateField;
  email: string;
  first_name: string;
  last_name: string;
  username: string;
  billing: BillingAddress;
  shipping: Array<ShippingAddress>;
  is_paying_customer: boolean;
  meta_data: Array<MetaData>;
  role: Role;
}

export interface RegisterData extends ShippingAddress, BillingAddress {
  email: string;
  password: string;
  birthDate: string;
  gender: string;
  phone: string;
  phone2?: string;
  iban: string;
  subscribed_to_newsletter?: boolean;
  id_document_number: string;
  business_name: string;
}


export interface RegisterShort {
  email?: string;
  password?: string;
  privacy_accept?: boolean;
}


export interface Delivery {
  date: string;
  observations?: string;
}

export enum PaymentMethod {
  Cash = 'bacs',
  CommercialTemplate = 'template'
}

export interface Payment {
  method: PaymentMethod;
  useRappel?: boolean;
}

export enum OrderStatus {
  Processing = 'processing',
  Pending = 'pending',
  Canceled = 'cancel',
  Completed = 'completed'
}

export interface Order {
  ACF: boolean;
  billing: BillingAddress;
  cart_hash: string;
  cart_tax: string;
  coupon_lines: any[];
  created_via: string;
  currency: string;
  currency_symbol: string;
  customer_id: number;
  customer_ip_address: string;
  customer_note: string;
  customer_user_agent: string;
  date_completed: any;
  date_completed_gmt: any;
  date_created: string;
  date_created_gmt: string;
  date_modified: string;
  date_modified_gmt: string;
  date_paid: any;
  date_paid_gmt: any;
  discount_tax: string;
  discount_total: string;
  fee_lines: any[];
  id: number;
  line_items: Product[];
  meta_data: MetaData[];
  number: string;
  order_key: string;
  parent_id: number;
  payment_method: string;
  payment_method_title: string;
  prices_include_tax: boolean;
  refunds: any[];
  shipping: ShippingAddress;
  shipping_lines: any[];
  shipping_tax: string;
  shipping_total: string;
  status: OrderStatus;
  tax_lines: any[];
  total: string;
  total_tax: string;
  transaction_id: string;
  version: string;
  _links: any;
}

export interface OrderAPI {
  id: number;
  parent_id: number;
  number: string;
  order_key: string;
  created_via: string;
  version: string;
  status: string;
  currency: string;
  date_created: string;
  date_created_gmt: string;
  date_modified: string;
  date_modified_gmt: string;
  discount_total: string;
  discount_tax: string;
  shipping_total: string;
  shipping_tax: string;
  cart_tax: string;
  total: string;
  total_tax: string;
  prices_include_tax: boolean;
  customer_id: number;
  customer_ip_address: string;
  customer_user_agent: string;
  customer_note: string;
  billing: BillingAddress,
  shipping: ShippingAddress;
  payment_method: PaymentMethod;
  payment_method_title: string;
  transaction_id: string;
  date_paid: unknown;
  date_paid_gmt: unknown;
  date_completed: unknown;
  date_completed_gmt: unknown;
  cart_hash: string;
  meta_data: MetaData[];
  line_items: Partial<Product>[];
  tax_lines: unknown[];
  shipping_lines: ShippingZoneMethod[];
  fee_lines: unknown[];
  coupon_lines: Partial<CouponAPI>[];
  refunds: unknown[];
  ACF: boolean;
  currency_symbol: string;
}

export interface OrdersResponse {
  orders: OrderAPI[];
}

export interface RegisterPaypal {
  scope: string;
  access_token: string;
  token_type: string;
  app_id: string;
  expires_in: number;
  nonce: string;
}

export interface DetailPlanPaypal {
  id: string;
  product_id: string;
  name: string;
  status: string;
  description: string;
  usage_type: string;
  billing_cycles: [
    {
      pricing_scheme: {
        version: number;
        fixed_price: {
          currency_code: string;
          value: number;
        },
        create_time: string;
        update_time: string;
      },
      frequency: {
        interval_unit: string;
        interval_count: number;
      },
      tenure_type: string;
      sequence: number;
      total_cycles: number;
    }
  ],
  payment_preferences: {
    service_type: string;
    auto_bill_outstanding: boolean;
    setup_fee: {
      currency_code: string;
      value: number;
    },
    setup_fee_failure_action: string;
    payment_failure_threshold: number;
  },
  taxes: {
    percentage: number;
    inclusive: boolean;
  },
  quantity_supported: boolean;
  create_time: DateField;
  update_time: DateField;
}
