import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { CouponsEffects } from './effects/coupons.effects';
import * as fromCoupons from './reducers';
import { CouponsService } from './services/coupons.service';


@NgModule({
  declarations: [],
  imports: [
    EffectsModule.forFeature([CouponsEffects]),
    StoreModule.forFeature(fromCoupons.couponsKey, fromCoupons.reducer)
  ],
  providers: [CouponsService]
})
export class CouponsModule { }
