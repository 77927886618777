import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { CoreConfigService, EndpointType } from '../../utils';
import { Winery, Winery_cat } from '../models/winery.model';

@Injectable({ providedIn: 'root' })
export class WineryService {

  constructor(private _coreConfig: CoreConfigService, private http: HttpClient, private translate: TranslateService) { }

  getWinery(id: string): Observable<Winery> {
    let options = {};
    if (this.translate.currentLang !== this.translate.defaultLang) {
      options = {
        params: new HttpParams().append('lang', this.translate.currentLang)
      }
    }
    return this.http.get<Winery>(`${this._coreConfig.getEndpoint(EndpointType.Wordpress)}winery/${id}/?_embed=wp:featuredmedia`, options).pipe(
      catchError((error) => {
        console.error(error);
        return [<any>{}];
      })
    );
  }

  getWineryBySlug(slug: string): Observable<Winery> {
    let params = new HttpParams()
      .append('slug', slug);
    if (this.translate.currentLang !== this.translate.defaultLang) {
      params = params.append('lang', this.translate.currentLang);
    }
    return this.http.get<Winery>(`${this._coreConfig.getEndpoint(EndpointType.Wordpress)}winery/?_embed=wp:featuredmedia`, { params }).pipe(
      switchMap((wineries) => {
        if (!wineries[0]) {
          return throwError('No winery');
        }
        return [wineries[0]];
      })
    );
  }

  getWineriesBySlugs(slugs: string[], page: number): Observable<Winery[]> {
    let params = new HttpParams()
      .append('slug', slugs.join('+'));
    if (this.translate.currentLang !== this.translate.defaultLang) {
      params = params.append('lang', this.translate.currentLang);
    }
    return this.http.get<Winery[]>(`${this._coreConfig.getEndpoint(EndpointType.Wordpress)}winery/?_embed=wp:featuredmedia&page=${page}`, { params }).pipe(
      switchMap((wineries) => {
        if (!wineries[0]) {
          return throwError('No wineries');
        }
        return [wineries];
      })
    );
  }

  getWineryClaim(id: string): Observable<string> {
    if (!id) {
      return of('');
    }
    return this.getWinery(id).pipe(map(({ winery_attributes }) => winery_attributes?.winery_claim || ''));
  }

  getWineries(page: number, perPage: number = this._coreConfig.getProductsConfig().perPageWinery): Observable<any> {
    const params = new HttpParams()
      .append('wpml_language', this.translate.currentLang)
      .append('page', `${page}`)
      .append('per_page', perPage.toString())
      .append('order', 'asc')
      .append('orderby', 'title');

    return this.http.get(`${this._coreConfig.getEndpoint(EndpointType.Wordpress)}winery/`, {
      params,
      observe: 'response'
    }).pipe(
      map((response: HttpResponse<any>) => ({ products: response.body, totalWineries: +response.headers.get('x-wp-total'), totalPages: +response.headers.get('x-wp-totalPages') }))
    );
  }

  getAllWineries(): Observable<Winery[]> {
    const params = new HttpParams()
      .append('wpml_language', this.translate.currentLang)
      .append('per_page', '100')
      .append('order', 'asc')
      .append('orderby', 'title');

    return this.http.get(`${this._coreConfig.getEndpoint(EndpointType.Wordpress)}winery/`, {
      params,
      observe: 'response'
    }).pipe(
      map((response: HttpResponse<Winery[]>) => (response.body))
    )
  }

  getWineryCategories(): Observable<Winery_cat[]>{
    
    const params = new HttpParams()
      .append('wpml_language', this.translate.currentLang)
      .append('order', 'asc')
      .append('orderby', 'name')


    return this.http.get(`${this._coreConfig.getEndpoint(EndpointType.Wordpress)}winery_cat/`, {
      params,
      observe: 'response'
    }).pipe(
      map((response: HttpResponse<Winery_cat[]>) => (response.body))
    )
  }



}

