import { ChangeDetectionStrategy, Component, HostListener } from '@angular/core';
import { Store } from '@ngrx/store';
import { AuthActions } from '@tgw-clients/shared';

@Component({
  selector: 'tgw-banner-to-register',
  template: `
    <div>
      <p class="hidden-md-up">{{ 'tgw-new-customer-cta-banner' | translate }}</p>
      <p class="hidden-sm-down">{{ 'tgw-new-customer-cta-register' | translate }}<span><strong>{{ 'tgw-new-customer-cta-gift' | translate }}</strong></span></p>
      <span class="hidden-sm-down icon" inlineSVG="assets/icons/arrow.svg"></span>
    </div>
  `,
  styleUrls: ['./banner-to-register.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BannerToRegisterComponent {

  constructor(private store: Store) { }

  @HostListener('click')
  openRegisterDialog(): void {
    this.store.dispatch(AuthActions.showRegisterShortModal());
  }

}
