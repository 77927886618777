import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { CoreConfigService, Footer, fromRouter } from '@tgw-clients/shared';
import { map } from 'rxjs/operators';

@Component({
  selector: 'tgw-footer',
  template: `
    <footer *ngIf="businessType === 'b2c' && 'tgw'!= brand" [class.landing-footer]="businessType === 'b2c' && (isRootUrl$ | async)" [class.footer-black]="businessType === 'b2c'" >
      <tgw-club-footer [newsletterPending]="newsletterPending" (subscribe)="subscribe.next($event)" (notifyError)="notifyError.next($event)"></tgw-club-footer>
      <tgw-link-footer></tgw-link-footer>
      <tgw-section-footer></tgw-section-footer>
    </footer>

    <footer *ngIf="businessType === 'b2b'" [class.footer-b2b-element]="businessType === 'b2b'" >
      <rao-landing-form></rao-landing-form>
      <rao-link-footer ></rao-link-footer>
      <rao-footer></rao-footer>
    </footer>

    <footer *ngIf="businessType === 'b2c' && 'tgw'== brand " [class.footer-b2b-element]="businessType === 'b2c'" >
      <tgw-club-footer-tgw></tgw-club-footer-tgw>
      <tgw-link-footer-tgw></tgw-link-footer-tgw>
      <tgw-section-footer-tgw></tgw-section-footer-tgw>
    </footer>

  `,
  styleUrls: ['./footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FooterComponent {
  @Input() lang: string;
  @Input() menus: Footer;
  @Input() newsletterPending: any;

  @Output() subscribe = new EventEmitter<string>();
  @Output() notifyError = new EventEmitter<string>();

  isRootUrl$ = this.store.pipe(
    select(fromRouter.selectUrl),
    map(url => url === '/')
  );
  businessType =  this._coreConfig.businessType;

  brand =  this._coreConfig.brand;

  constructor(private _coreConfig: CoreConfigService, private store: Store) { }

}
