import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Product } from '../models';

interface WineryProducts{
  winery: string,
  products: Product[]
}

@Injectable({
  providedIn: 'root'
})
export class ProductsDataService {

  private productsSource = new BehaviorSubject<Product[]>(null);
  currentProducts = this.productsSource.asObservable();

  private productSource = new BehaviorSubject<Product>(null);
  currentProduct = this.productSource.asObservable();

  private featuresProductsSource = new BehaviorSubject<Product[]>(null);
  currentFeaturedProducts = this.featuresProductsSource.asObservable();

  private clubProductsSource = new BehaviorSubject<Product[]>(null);
  currentClubProducts = this.clubProductsSource.asObservable();

  private bestSellerProductsSource = new BehaviorSubject<Product[]>(null);
  currentBestSellerProducts = this.bestSellerProductsSource.asObservable();

  private wineryProductsSource = new BehaviorSubject<Product[]>([]);
  currentWineryProducts = this.wineryProductsSource.asObservable();

  private offerProductsSource = new BehaviorSubject<Product[]>(null);
  currentOfferProducts = this.offerProductsSource.asObservable();

  constructor() { }

  updateProducts(products: Product[]){
    this.productsSource.next(products)
  }

  updateProduct(product: Product){
    this.productSource.next(product)
  }

  updateClubProducts(products: Product[]){
    this.clubProductsSource.next(products)
  }

  updateFeaturedProducts(products: Product[]){
    this.featuresProductsSource.next(products)
  }

  updateBestSellerProducts(products: Product[]){
    this.bestSellerProductsSource.next(products)
  }

  updateWineryProducts(products: Product[]){
    this.wineryProductsSource.next(products)
  }

  updateOfferProducts(products: Product[]){
    this.offerProductsSource.next(products)
  }
}
