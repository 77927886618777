import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { ErrorHandlerModule, ErrorHandlerService } from '@tgw-clients/shared';
import { SharedModule } from '../shared';
import { NotificationDialogComponent } from './components/notification-dialog.component';
import { ErrorHandlerWebService } from './services/error-handler-web.service';


@NgModule({
  declarations: [NotificationDialogComponent],
  imports: [
    ErrorHandlerModule,
    SharedModule,
    TranslateModule.forChild(),
  ],
  providers: [
    {
      provide: ErrorHandlerService,
      useClass: ErrorHandlerWebService
    }
  ]
})
export class ErrorHandlerWebModule { }
