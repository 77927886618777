import { createAction, props, union } from '@ngrx/store';
import { Product } from '@tgw-clients/shared';

export const loadProductsClub= createAction('[Products] Load products club page', props<{ page: string }>());
export const saveProductsClub = createAction('[Products] Save products club page', props<{ page: number, products: Product[], totalPages: number }>());


const all = union({
  loadProductsClub,
  saveProductsClub
});

export type ClubsActionsUnion = typeof all;
