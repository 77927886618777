import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'tgw-notification-dialog',
  template: `

    <div *isBrand="'rao'" >
      <span mat-dialog-close class="icon-close" inlineSVG="assets/icons/close.svg"></span>
      <p *ngIf="title" mat-dialog-title class="dialog-title">{{title | translate}}</p>
      <p [innerHTML]="message | translate"></p>
      <button mat-raised-button mat-dialog-close>{{'tgw-commons-accept' | translate}}</button>
    </div>

    <div *isBrand="'tgw'" class="tgw-notification">
      <span mat-dialog-close class="icon-close" inlineSVG="assets/icons/close.svg"></span>
      <p *ngIf="title" mat-dialog-title class="dialog-title">{{title | translate}}</p>
      <p [innerHTML]="message | translate"></p>
      <button mat-raised-button mat-dialog-close>{{'tgw-commons-accept' | translate}}</button>
    </div>
  `,
  styleUrls: ['./notification-dialog.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationDialogComponent {
  title: string;
  message: string;

  constructor(@Inject(MAT_DIALOG_DATA) data: { message: string, title?: string}) {
    this.message = data.message;
    this.title = data?.title;
  }
}
