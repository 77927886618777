<div *ngIf="!mobile; else mobileMenu" class="menu">
    <div class="row">

        <div *ngFor="let item of items" style="max-height: 380px;">

            <div [class]="'column '+item.title.toLowerCase()" (click)="goTo(item.url)">
                <img [src]="item.tgw_icon">
                <p class="title">Colección</p>
                <p class="subtitle">{{item.title}}´ Original</p>
                <p class="content">{{item.description}}.</p>
            </div>
            <!-- <span class="vertical-line"></span> -->
        </div>
        <div class="test-column">
            <p class="test-title">Tres colecciones</p>
            <p class="test-question">¿No sabes cual elegir?</p>
            <p class="test-subtitle">Nuestro test te ayudará a elegir una colección</p>
            <button (click)="goToTest()" class="test-btn btn btn-primary">HAZ EL TEST</button>
            <p (click)="goTo('/colecciones')" style="cursor: pointer;" class="test-compare">¡Compáralas!</p>
        </div>
    </div>
</div>
<ng-template #mobileMenu>
    <div class="mobile-menu">
        <div class="column">
            <div class="item" *ngFor="let item of items; let i = index" [ngClass]="item.title.toLowerCase()">
                <a (click)="goTo(item.url)" class="title">{{item.title}}´Original</a>
                <p (click)="goTo(item.url)" class="subtitle">{{item.description}}</p>
                <span class="desplegado">
                 <svg id="Capa_1" data-name="Capa 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13.38 7.4"><title>arrow</title><polyline points="0.35 0.35 6.69 6.69 13.03 0.35" style="fill:none;stroke:#1d1e1c;stroke-miterlimit:10"/></svg>
                </span>
            </div>
        </div>
    </div>
</ng-template>