import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { CartEffects } from './effects/cart.effects';
import * as fromCart from './reducers';

@NgModule({
  imports: [
    TranslateModule.forChild(),
    StoreModule.forFeature(fromCart.cartKey, fromCart.reducer),
    EffectsModule.forFeature([CartEffects])
  ]
})
export class CartModule { }
