import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CartItem, CoreConfigService, Product, addToDataLayer } from '@tgw-clients/shared';

@Component({
  selector: 'tgw-cart-profesionales',
  template: `
    <button type="button" class="back-btn" (click)="closeCart.next()" inlineSVG="assets/icons/chevron-left.svg"></button>
    <div class="cart-item-wrapper">
      <div *ngFor="let item of cart; trackBy: trackByFn" class="cart-item">
        <div class="btn-wrapper">
          <button id="removeAProductFromTheCart" (click)="removeProductFromCart(item.product, item.quantity, item?.date)" inlineSVG="assets/icons/delete.svg"></button>
        </div>
        <div class="img-wrapper">
          <img [src]="item.product?.images[0]?.src"/>
        </div>
        <div class="desc-wrapper">
          <p class="product-title"><span [innerHTML]="item.product.name"></span> </p>
          <p *isBusinessType="['b2b']">{{item.quantity}} x {{((+item.product.sale_price && (+item.product.sale_price / 1.21)) ||  +findOriginalPrice(item.product) ) | currency:'EUR':'symbol':'1.2-2'}}</p>
          <p *isBusinessType="['!b2b']">{{item.quantity}} x {{(+item.product.sale_price || +item.product.regular_price) | currency:'EUR':'symbol':'1.2-2'}}</p>
          <p *ngIf="item?.date as date"><span [innerHTML]="date"></span> </p>
        </div>
      </div>
    </div>
    <div class="tgw-cart-footer">
      <div class="subtotal-wrapper">
        <p class="subtotal-title">{{'tgw-commons-subtotal' | translate}}</p>
        <p>{{ getSubtotal(cart) | currency:'EUR':'symbol':'1.2-2'}} {{'tgw-cart-vat-included' | translate}}</p>
      </div>

      <button type="button" class="btn btn-primary hidden-md-up" (click)="redirectTo()" >{{'tgw-cart-buy-button-label' | translate}}</button>
      <button type="button" class="btn btn-secondary hidden-sm-down" (click)="redirectTo()">{{'tgw-cart-buy-button-label' | translate}}</button>
    </div>
  `,
  styleUrls: ['./cart-profesionales.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CartProfesionalesComponent {
  @Input() cart: CartItem[];
  @Input() isLoggedIn: boolean;
  // @Output() delete = new EventEmitter<CartItem>();
  @Output() delete = new EventEmitter();
  @Output() closeCart = new EventEmitter<void>();

  businnesType = this._coreConfig.businessType;


  constructor(private router: Router, private dialog: MatDialog, private _coreConfig: CoreConfigService) { }


  getSubtotal(cart: CartItem[]): number {
    return cart.reduce((acc, { product, quantity }) => {
      const price = ['b2c'].includes(this._coreConfig.businessType) ? product.regular_price : this.findOriginalPrice(product);
      const salePrice = ['b2c'].includes(this._coreConfig.businessType) ? product.sale_price : (+product.sale_price / 1.21);
      return acc + ((+salePrice || +price) * quantity);
    }, 0);
  }

  redirectTo(): void {
    this.router.navigate(['/comprar/realizar-pedido']);
    this.closeCart.next()
  }

  trackByFn(_: number, cartItem: CartItem): number {
    return cartItem.product?.id;
  }

  findOriginalPrice(product: Product): string {
    return product.meta_data.find(({ key }) => key === 'original_price')?.value.replace(',', '.') || '';
  }

  removeProductFromCart(product:Product, quantity:number, date:any): void{
    addToDataLayer('addToCart', product, 'quitarUnProductoDelCarrito', this.businnesType, quantity)
    this.delete.next({product, date})
  }



}
