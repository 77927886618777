import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit } from '@angular/core';

@Component({
  selector: 'tgw-link-section-col',
  template: `
    <div class="container-col" (click)="mobile ? changeVisibility() : null">
      <div class="title">
        <div class="footer-title">
          <img [src]="section.icon" *ngIf="section.icon">
          <p>{{section.title}}</p>
        </div>
        <div *ngIf="mobile" class="arrow">
          <img src="assets/icons/footer-link-section-arrow.png" [ngClass]="{'reverse': !close}">
        </div>
      </div>
      <div class="links" *ngIf="!close && !!section?.links.length" (click)="$event.stopPropagation()">
        <div *ngFor="let link of section.links" >
          <a *ngIf="link.link" [href]="link.link"> <p [innerHTML]="link.title"></p> </a>
          <div *ngIf="!link.link && link.title!='DHL'"> <p [innerHTML]="link.title"></p> </div>
          <div *ngIf="!link.link && link.title==='DHL' && mobile">
            <div class="shiping">
              <p>Envio seguro con:</p>
              <img src="assets/icons/DHL.png">
            </div>
          </div>
        </div>
      </div>
    </div>

  `,
  styleUrls: ['./link-section-col.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LinkSectionColComponent implements OnInit, OnChanges {

  @Input() mobile:Boolean;
  @Input() section:any;
  close = false
  constructor() {

  }
  ngOnInit(){
    if(this.mobile){
      this.close = true
    } else{
      this.close = false
    }
  }
  ngOnChanges(){
    if(this.mobile){
      this.close = true
    } else{
      this.close = false
    }
  }

  changeVisibility(){
    this.close = !this.close
  }
}
