import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, tap, switchMap } from 'rxjs/operators';
import { ClubsActions } from '../actions';
import { ProductsService } from '../services/products.service';


@Injectable()
export class ClubsEffects {

  loadProductsClub$ = createEffect(() =>
  this.actions$.pipe(
    ofType(ClubsActions.loadProductsClub),
    switchMap( ({ page }) =>
      this._products.getProductsClub(page).pipe(
        map((productPage) => ClubsActions.saveProductsClub({ ...productPage })),
        catchError(() => [ClubsActions.saveProductsClub({ products: [], page: 1, totalPages: 1 })])
      )
    )
  )
);

  constructor(
    private actions$: Actions,
    private _products: ProductsService
  ) { }
}
