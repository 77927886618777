import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { AuthService } from '../auth/services';
import { ErrorHandlerEffects } from './effects/error-handler.effects';
import { ErrorHandlerService, HttpErrorInterceptor } from './services';



@NgModule({
  imports: [
    TranslateModule.forChild(),
    EffectsModule.forFeature([ErrorHandlerEffects])
  ],
  providers: [
    ErrorHandlerService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      deps: [AuthService, Store],
      multi: true
    }
  ]
})
export class ErrorHandlerModule { }
