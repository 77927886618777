import { MetaData } from '@tgw-clients/shared/utils';
export interface ProductCategory {
  id: number;
  name: string;
  slug: string;
}

export interface Attribute {
  id: number;
  name: string;
  options: string[];
  position: number;
  variation: boolean;
  visible: boolean;
}

export interface ProductImage {
  alt: string;
  date_created: string;
  date_created_gmt: string;
  date_modified: string;
  date_modified_gmt: string;
  id: number;
  name: string;
  src: string;
  url: string;
}

export interface DeniedState {
  id: number;
  name: string;
  slug: string;
  description: string;
}

export interface Item {
  product: Product
  address_id: string
  low_stock_qty: number
  order_id: string
  order_qty: string
  processing: string
  processing_date: any
  provider_email: string
  pvp: string
  quantity: number
  anada?: number
}

export interface Product {
  ACF: {
    current_month: string;
    current_yearly: string;
    collection_yearly: string;
    title: string;
    wines_content: [number];
    logo: string;
  };
  attributes: Attribute[];
  average_rating: string;
  backordered: boolean;
  backorders: string;
  backorders_allowed: boolean;
  button_text: string;
  catalog_visibility: string;
  categories: ProductCategory[];
  cross_sell_ids: any[];
  date_created: string;
  date_created_gmt: string;
  date_modified: string;
  date_modified_gmt: string;
  date_on_sale_from: any;
  date_on_sale_from_gmt: any;
  date_on_sale_to: any;
  date_on_sale_to_gmt: any;
  default_attributes: any[];
  description: string;
  dimensions: { length: string };
  download_expiry: number;
  download_limit: number;
  downloadable: boolean;
  downloads: any[];
  external_url: string;
  featured: boolean;
  grouped_products: any[];
  id: number;
  images: any[];
  manage_stock: boolean;
  menu_order: number;
  meta_data: MetaData[];
  name: string;
  on_sale: boolean;
  parent_id: number;
  permalink: string;
  price: string;
  price_html: string;
  purchasable: boolean;
  purchase_note: string;
  rating_count: number;
  regular_price: string;
  related_ids: any[];
  reviews_allowed: boolean;
  sale_price: string;
  selling_denied_states: { [idState: number]: DeniedState };
  shipping_class: string;
  shipping_class_id: number;
  shipping_required: boolean;
  shipping_taxable: boolean;
  short_description: string;
  sku: string;
  slug: string;
  sold_individually: boolean;
  status: string;
  stock_quantity: any;
  stock_status: string;
  tags: any[];
  tax_class: string;
  tax_status: string;
  total_sales: number;
  type: string;
  upsell_ids: any[];
  variations: any[];
  virtual: boolean;
  weight: string;
  _links: any;
  winerySubtitle?: string;
  quantity?: number;
  winery_title?: string;
  product_id?: number;
  image?: string
  total?: string;
  total_tax?: string;
  winery?: any
  selling_denied_postcodes?: string[];
  rappel?: number;
  title?: string;
}

export const DefaultProductValue: Product = {
  "id": 0,
  "name": "Default",
  "slug": "Default",
  "permalink": "",
  "date_created": "2022-09-23T18:24:26",
  "date_created_gmt": "2022-09-23T16:24:26",
  "date_modified": "2022-09-23T18:24:26",
  "date_modified_gmt": "2022-09-23T16:24:26",
  "type": "tgw-external",
  "status": "publish",
  "featured": false,
  "catalog_visibility": "visible",
  "description": "",
  "short_description": "",
  "sku": "",
  "price": "",
  "regular_price": "",
  "sale_price": "",
  "date_on_sale_from": null,
  "date_on_sale_from_gmt": null,
  "date_on_sale_to": null,
  "date_on_sale_to_gmt": null,
  "price_html": "",
  "on_sale": false,
  "purchasable": false,
  "total_sales": 0,
  "virtual": false,
  "downloadable": false,
  "downloads": [],
  "download_limit": -1,
  "download_expiry": -1,
  "external_url": "",
  "button_text": "",
  "tax_status": "taxable",
  "tax_class": "",
  "manage_stock": false,
  "stock_quantity": null,
  "stock_status": "instock",
  "backorders": "no",
  "backorders_allowed": false,
  "backordered": false,
  "sold_individually": false,
  "weight": "",
  "dimensions": {
    "length": ""
  },
  "shipping_required": true,
  "shipping_taxable": true,
  "shipping_class": "",
  "shipping_class_id": 0,
  "reviews_allowed": true,
  "average_rating": "0",
  "rating_count": 0,
  "related_ids": [1507,1305,1381,1201,1191],
  "upsell_ids": [],
  "cross_sell_ids": [],
  "parent_id": 0,
  "purchase_note": "",
  "categories": [
    {
      "id": 15,
      "name": "Vino",
      "slug": "vino"
    }
  ],
  "tags": [],
  "images": [],
  "attributes": [],
  "default_attributes": [],
  "variations": [],
  "grouped_products": [],
  "menu_order": 0,
  "meta_data": [],
  "selling_denied_states": [],
  "selling_denied_postcodes": [],
  "winery_title": "",
  "ACF": null,
  "_links": {}
}

export interface Experience {
  ACF: {
    duration: string;
    itinerary: string;
    route: string;
    transport: string;
    when: string;
    where: string;
  };
  attributes: any[];
  average_rating: string;
  backordered: boolean;
  backorders: string;
  backorders_allowed: boolean;
  button_text: string;
  catalog_visibility: string;
  categories: any[];
  cross_sell_ids: any[];
  date_created: string;
  date_created_gmt: string;
  date_modified: string;
  date_modified_gmt: string;
  date_on_sale_from: string;
  date_on_sale_from_gmt: string;
  date_on_sale_to: string;
  date_on_sale_to_gmt: string;
  default_attributes: any[];
  description: string;
  dimensions: any;
  download_expiry: number;
  download_limit: number;
  downloadable: number;
  downloads: any[];
  external_url: string;
  featured: boolean;
  grouped_products: any[];
  id: number;
  images: any[];
  manage_stock: boolean;
  menu_order: number;
  meta_data: any[];
  name: string;
  on_sale: boolean;
  parent_id: number;
  permalink: string;
  price: string;
  price_html: string;
  purchasable: boolean;
  purchase_note: string;
  rating_count: number;
  regular_price: string;
  related_ids: any[];
  reviews_allowed: boolean;
  sale_price: string;
  selling_denied_states: any[];
  shipping_class: string;
  shipping_class_id: number;
  shipping_required: boolean;
  shipping_taxable: boolean;
  short_description: string;
  sku: string;
  slug: string;
  sold_individually: false
  status: string;
  stock_quantity: null
  stock_status: string;
  tags: any[];
  tax_class: string;
  tax_status: string;
  total_sales: number;
  type: string;
  upsell_ids: any[];
  variations: any[];
  virtual: boolean;
  weight: string;
  winery_title: string;
}

export interface Collection {
  ACF: {
    collection_yearly: string;
    duration: string;
    itinerary: string;
    route: string;
    transport: string;
    when: string;
    where: string;
    features: [{ description: string, image: string }];
    logo: string;
    logo_test: string;
    subtitle: string;
    collection: [number];
    content: {
      colection_description: string,
      custom_box_image: string,
      description: string,
      image: string,
      image_final: string,
      mobile_image: string,
      tasting_sheet_image: string,
      title: string
    };
    choose_plan: string;
    title_image_final: string;
    description_image_final: string;
    extra_best: string;
    suscription: {
      id_plan_paypal_month: string;
      id_plan_paypal_year: string;
    }
  };
  attributes: any[];
  average_rating: string;
  backordered: boolean;
  backorders: string;
  backorders_allowed: boolean;
  button_text: string;
  catalog_visibility: string;
  categories: any[];
  cross_sell_ids: any[];
  date_created: string;
  date_created_gmt: string;
  date_modified: string;
  date_modified_gmt: string;
  date_on_sale_from: string;
  date_on_sale_from_gmt: string;
  date_on_sale_to: string;
  date_on_sale_to_gmt: string;
  default_attributes: any[];
  description: string;
  dimensions: any;
  download_expiry: number;
  download_limit: number;
  downloadable: number;
  downloads: any[];
  external_url: string;
  featured: boolean;
  grouped_products: any[];
  id: number;
  images: any[];
  manage_stock: boolean;
  menu_order: number;
  meta_data: any[];
  name: string;
  on_sale: boolean;
  parent_id: number;
  permalink: string;
  price: string;
  price_html: string;
  purchasable: boolean;
  purchase_note: string;
  rating_count: number;
  regular_price: string;
  related_ids: any[];
  reviews_allowed: boolean;
  sale_price: string;
  selling_denied_states: any[];
  shipping_class: string;
  shipping_class_id: number;
  shipping_required: boolean;
  shipping_taxable: boolean;
  short_description: string;
  sku: string;
  slug: string;
  sold_individually: false
  status: string;
  stock_quantity: null
  stock_status: string;
  tags: any[];
  tax_class: string;
  tax_status: string;
  total_sales: number;
  type: string;
  upsell_ids: any[];
  variations: any[];
  virtual: boolean;
  weight: string;
  winery_title: string;
}

export interface MenuItems {
  id?: number;
  name?: string;
  slug?: string;
  type?: string;
  order_by?: string;
  has_archives?: boolean;
}

export interface Term {
  id?: string;
  name: string;
  count: number;
}

export interface AttributeCount {
  label: string;
  terms: { [name: string]: Term }
}


export interface ProductsCustomAPI {
  counts: {
    found: number;
    page: number;
    pages: number;
    max: number;
    min: number;
    per_page: number;
    attributes: { [attr: string]: AttributeCount };
    wineries: { [id: string]: { id: number; name: string; count: number } };
    max_price: number;
    min_price: number;
    products_ids: number[]
  };
  products: Product[]
}

export interface ExperienceDate {
  date: string;
  num_tickets: string;
  ts: number;
  name: string
}

