
import { animate, style, transition, trigger } from '@angular/animations';
import { AbstractControl, FormGroup } from '@angular/forms';
import { ActivatedRouteSnapshot, Data } from '@angular/router';
import { BusinessType, CartItem, Experience, OrderAPI, Product } from '@tgw-clients/shared';
import moment from 'moment';
declare global {
  interface Window { dataLayer: any[]; }
}

/* Forms utils */
export function getFormErrors(form: FormGroup): { [error: string]: boolean } {
  return Object.keys(form.controls).reduce((acc, key) => {
    if (form.get(key) instanceof FormGroup) {
      return { ...acc, ...form.get(key).errors, ...getFormErrors(form.get(key) as FormGroup) };
    }
    return { ...acc, ...form.get(key).errors };
  }, { ...form.errors });
}

export function validateSamePassword(passwordKey: string, passwordConfirmKey: string) {
  return (form: FormGroup): { [key: string]: boolean } => {
    const password = form.value[passwordKey];
    const passwordValidation = form.value[passwordConfirmKey];
    if (password && password !== passwordValidation) {
      return { notsamekey: true };
    }
    return null;
  }
}

export function validateDate(dayField: string, monthField: string, yearField: string) {
  return (form: FormGroup): { [key: string]: boolean } => {
    let day = form.value[dayField];
    let month = form.value[monthField];
    let year = form.value[yearField];
    let _isValid = moment(`${year}-${month}-${day}`, "YYYY-MM-DD").isValid();
    if (!_isValid) {
      return { invalidDate: true };
    }
    return null;
  }
}

export function validateAdult(dayField: string, monthField: string, yearField: string) {
  return (form: FormGroup): { [key: string]: boolean } => {
    let day = form.value[dayField];
    let month = form.value[monthField];
    let year = form.value[yearField];
    let age = moment().diff(`${year}-${month}-${day}`, "year");
    if (age < 18) {
      return { underAge: true };
    }
    return null;
  }
}

export function validateDni(control: AbstractControl): { invalidDni: true } | null {
  const dniField = control.value;
  if (dniField!='') {
    let numero, aux, letra;
    const expresion_regular_dni = /^[XYZ]?\d{5,8}[A-Z]$/;
    const expresion_regular_cif = /^[a-zA-Z]{1}\d{7}[a-zA-Z0-9]{1}$/;

    let dni = dniField.toUpperCase();

    if (expresion_regular_dni.test(dni) === true) {
      numero = dni.substr(0, dni.length - 1);
      numero = numero.replace('X', 0);
      numero = numero.replace('Y', 1);
      numero = numero.replace('Z', 2);
      aux = dni.substr(dni.length - 1, 1);
      numero = numero % 23;
      letra = 'TRWAGMYFPDXBNJZSQVHLCKET';
      letra = letra.substring(numero, numero + 1);
      if (letra !== aux) {
        return {invalidDni: true};
      }
    } else if (expresion_regular_cif.test(dni) !== true) {
      return {invalidDni: true};
    } else {
      return null;
    }
  } else {
    return null;
  }
}


export function phoneValidator(control: AbstractControl): { invalidPhone: true } | null {
  const phoneNumber = control.value;
  const phoneRegex = /^[6789][0-9]{8}$/;
  if (!phoneNumber) {
    return null;
  }
  if (!phoneRegex.test(phoneNumber)) {
    return { invalidPhone: true };
  }
  return null;
}

/* Route functions */
export function collectRouteData(root: ActivatedRouteSnapshot): Data {
  const data: Data = {};
  (function mergeParamsFromSnapshot(snapshot: ActivatedRouteSnapshot) {
    Object.assign(data, snapshot.data);
    snapshot.children.forEach(mergeParamsFromSnapshot);
  })(root);
  return data;
}

/* Animations */
export const menuAnimation = trigger('menu', [
  transition(':leave', [
    style({ height: '100vh' }),
    animate('.3s ease-out', style({ height: '0' })),
  ]),
  transition(':enter', [
    style({ height: '0' }),
    animate('.3s ease-in', style({ height: '100vh' })),
  ])
]);

export function getMetaData(product: any, metaData: string): string | string[] {
  const value = (product.meta_data.find(({ key }) => key === metaData)?.value) || '';
  if (['original_price'].includes(metaData)) {
    return (value as string).replace(',', '.');
  }
  return value;
}

//ESTAR DATALAYER
export function addToDataLayerStep(cart: CartItem[], step: number, businnesType: BusinessType): boolean {
  if(!Array.isArray(window.dataLayer)){
    return;
  }
  const products = getProducstInfo(cart, businnesType);
  window.dataLayer.push(
    {
      'event': 'checkout',
      'ecommerce': {
        'checkout': {
          'actionField': {'step': step},
          'products': products
       }
     }
  });
  return true
}

export function addToDataLayerPay(info: { orders: OrderAPI[], cart: CartItem[] }, businnesType: BusinessType): boolean {
  if(!Array.isArray(window.dataLayer)){
    return;
  }
  const products = getProducstInfo(info?.cart, businnesType)
  const allOrders = info?.orders?.map((item) => {
    return {
      'id': item?.id || null,
      'total': Number(item?.total)?.toFixed(2) || null,
      'impuestos': item?.tax_lines || [],
      'gastosDeEnvio': Number(item?.shipping_total)?.toFixed(2) || null,
      'cuponUtilizado': item?.coupon_lines || []
    }
  }) || [];

  window.dataLayer.push({
    'ecommerce': {
      'purchase': {
        'actionField': allOrders,
        'products': products
      }
    }
  });
  return true
}

export function addToDataLayer(event: string = 'productClick', product: Product | Experience, dataLayerItem: string, businnesType: BusinessType, quantity?: number): void {
  if(!Array.isArray(window.dataLayer)){
    return;
  }
  const data = getInfroProducts(product, quantity, businnesType);
  if(event === 'productClick'){
    window.dataLayer.push({
      event: event,
      'ecommerce': {
        'click': {
          'actionField': { list: dataLayerItem },
          'products': [data]
        }
      }
    });
  }else{
    window.dataLayer.push({
      event: event,
      'ecommerce': {
        // 'currencyCode': 'EUR',
        'add': {
          'products': [data]
        }
      }
    });
  }
}

export function getProducstInfo(cart: CartItem[], businnesType: BusinessType): CartItem[] {
  return cart?.map(({ product, quantity }) => {
    return getInfroProducts(product, quantity, businnesType)
  }) || [];
}

export function getInfroProducts( product: Product |Experience, quantity: number, businnesType: BusinessType ): any{
  const { id, name, regular_price, winery_title, categories } = product;
  const isWine = (categories || []).some(({ name }) => name === 'Vino')
  let data: any = { id, name, category: categories }
  if (businnesType === 'b2c' || businnesType === 'winery') data = { ...data, price: Number(regular_price).toFixed(2) }
  if (businnesType === 'b2b') data = { ...data, price: Number(getMetaData(product, 'original_price')).toFixed(2) }
  if (isWine) data = { ...data, brand: winery_title }
  if (quantity) data = { ...data, quantity }
  return data
}
//END DATALAYER


