import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { CoreConfigService, Footer, fromRouter, LayoutActions, Logo, MenuItem, LayoutService  } from '@tgw-clients/shared';
import { FormControl, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';

@Component({
  selector: 'tgw-club-footer',
  template: `
    <div class="footer-club-rao">
      <div class="container">
        <div class="row">
          <div class="col-one col-md-6 col-12">
            <div>
              <h3>¿Quieres tener acceso a las ventas <br> privadas del CLUB Rioja Alavesa Original?</h3>
              <p>Recibe un cupón descuento para realizar tu primera compra y pertenecer al CLUB Rioja Alavesa Original.</p>
              <p class="subscribe">Suscríbete y entérate de lo mejor del territorio de Rioja Alavesa</p>
            </div>
          </div>
          <div class="col-two col-md-6 col-12">
            <div class="newsletter">
              <p>Tu Correo Electrónico: </p>
                <mat-form-field class="input-newsletter" appearance="none">
                  <input matInput [formControl]="newsletterEmail" type="email" placeholder="email@ejemplo.com"/>
                </mat-form-field>
                <div class="row preference">
                  <div class="col-md-6 col-12">
                    <p>Gestionar tus preferencias</p>
                    <span>¿Cuál es tu preferencia?</span>
                  </div>
                  <div class="col-md-6 col-12">
                    <div>
                      <input [(ngModel)]="newsletterSelection" type="radio" id="particular" name="preference" value="particular">
                      <label for="particular">Soy un particular</label>
                    </div>
                    <div>
                      <input [(ngModel)]="newsletterSelection" type="radio" id="restaurant" name="preference" value="hosteleria">
                      <label for="restaurant">Soy un restaurante</label>
                    </div>
                  </div>
                </div>
                <button mat-raised-button matSuffix type="button" (click)="subscribeNewsletter()" [disabled]="newsletterEmail.disabled">{{'tgw-newsletter-button' | translate}}</button>
                <span class="newsletter-bottom">Revisa nuestra <a href="/politica-de-privacidad">política de privacidad</a> para saber más sobre el procesamiento de tus datos. Puedes <a href="/contacto">darte de baja</a> cuando quieras, sin coste alguno.</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="white-space"></div>
  `,
  styleUrls: ['./club-footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ClubFooterComponent {
  @Input() set newsletterPending(pending: boolean) {
    if (pending && this.newsletterEmail.enabled) {
      this.newsletterEmail.disable();
    } else if (!pending && this.newsletterEmail.disabled) {
      this.newsletterEmail.reset({ value: '', disabled: false });
    }
  }
  @Output() subscribe = new EventEmitter<{}>();
  @Output() notifyError = new EventEmitter<string>();


  businessType =  this._coreConfig.businessType;
  newsletterEmail = new FormControl('', [Validators.email, Validators.required]);
  newsletterSelection: string = 'particular';
  constructor(private _coreConfig: CoreConfigService, private store: Store, private layoutService: LayoutService) { }
  onSelectionChange(value) {
    this.newsletterSelection = value;
  }
  subscribeNewsletter(): void {
    if (this.newsletterEmail.invalid) {
      const message = this.newsletterEmail.errors?.required ? 'tgw-newsletter-email-required' : 'tgw-form-errors-email';
      this.notifyError.next(message);
      return;
    }


    this.store.dispatch(LayoutActions.subscribeNewsletter({ email: this.newsletterEmail.value, selection: [this.newsletterSelection] }));
    // this.layoutService.subscribeNewsletter(this.newsletterEmail.value, [this.newsletterSelection]).subscribe(data => {
    //   console.log('club: newsletter')
    //   console.log(data)
    // }, err => console.log(err))
  }
}
