import { createReducer, on } from '@ngrx/store';
import { CartActions } from '../actions';
import { CartItem } from '../models/cart.model';


export interface State {
  cart: CartItem[];
  pending: boolean;
}

const initialState: State = {
  cart: null,
  pending: false
}

const shopReducer = createReducer(
  initialState,
  on(CartActions.addToCart, (state, { product, quantity, date, subscription, instock }) => {
    const cart = state.cart?.slice() || [];
    const productIdx = cart.findIndex(({ product: { id }, date: itemDate }) => id === product.id && (!date || date === itemDate));

    if ((cart.filter(product => product.subscription).length == 0 && !subscription) || (cart.length == 0 && subscription) || (productIdx == 0 && subscription && cart.length == 1)) {

      if (productIdx < 0) {
        return {
          ...state, cart: cart.concat([{ product, quantity, date, subscription, instock }])
        };
      }

      if(subscription){
        cart[productIdx] = { ...cart[productIdx], quantity: cart[productIdx].quantity, subscription: subscription, instock: instock };
      }else{
        cart[productIdx] = { ...cart[productIdx], quantity: cart[productIdx].quantity + quantity, subscription: cart[productIdx].subscription, instock: cart[productIdx].instock };
      }
    }
    return { ...state, cart };
  }),
  on(CartActions.addToCartMultiple, (state, { items }) => {
    let newCart = state.cart?.slice() || [];
    for (let { product, quantity, date, subscription, instock } of items) {
      const idx = newCart.findIndex(({ product: { id } }) => id === product.id);
      if (idx < 0) {
        newCart = newCart.concat({ product, quantity, date, subscription, instock });
      } else {
        newCart[idx] = { product, quantity: newCart[idx].quantity + quantity, date, subscription, instock };
      }
    }
    return { ...state, cart: newCart, pending: false };
  }),
  on(CartActions.addToCartrepeatOrder, (state, { items }) => {
    let newCart = state.cart?.slice() || [];
    for (let { product, quantity, date, subscription, instock } of items) {
      const idx = newCart.findIndex(({ product: { id } }) => id === product.id);
      if (idx < 0) {
        newCart = newCart.concat({ product, quantity, date, subscription, instock });
      } else {
        newCart[idx] = { product, quantity: newCart[idx].quantity + quantity, date, subscription, instock };
      }
    }
    return { ...state, cart: newCart, pending: false };
  }),
  on(CartActions.removeProductFromCart, (state, { item: { product, date } }) => (
    {
      ...state,
      cart: state.cart.filter(cartItem => cartItem.product.id !== product.id || date !== cartItem.date)
    }
  )),
  on(CartActions.updateItemQuantity, (state, { product, quantity, date, subscription, instock }) => {
    const cart = state.cart.slice();
    const productIdx = cart.findIndex(({ product: { id }, date: itemDate }) => id === product.id && (!date || date === itemDate));
    cart[productIdx] = { product, quantity, date, subscription, instock };
    return { ...state, cart };
  }),
  on(CartActions.clearCart, (state) => ({ ...state, cart: [] })),
  on(CartActions.loadCartItems, (state) => ({ ...state, pending: true })),
  on(CartActions.addToCartMultiple, (state) => ({ ...state, pending: false })),
);

export function reducer(state: State | undefined, action: CartActions.CartActionsUnion) {
  return shopReducer(state, action);
}

export const getCart = (state: State) => state?.cart;

export const getPending = (state: State) => state?.pending;




