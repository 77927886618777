import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { forkJoin, Observable } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { CartItem } from '../../cart';
import { PacksActions } from '../actions';
import { Product } from '../models';
import { ProductsService } from '../services/products.service';

@Injectable()
export class PacksEffects {

  loadPacks$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PacksActions.loadPacks),
      switchMap(({ page: currentPage }) =>
        this._products.getPacks(currentPage).pipe(
          map(({ products, totalPages, page }) => PacksActions.savePacks({ page: page, packs: products, totalPages: totalPages })), //quitamos data de aqui
          catchError(() => [PacksActions.savePacks({ page: 1, packs: [], totalPages: 1 })])
        )
      )
    )
  );

  loadPackProducts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PacksActions.loadPackProducts),
      switchMap(({ pack }) =>
        this._getProductsInWeeklyPack(pack).pipe(
          map((products) => PacksActions.savePackProducts({ productsInPack: products, packId: pack.id })),
          catchError(() => [PacksActions.savePackProducts({ productsInPack: [], packId: pack.id })])
        )
      )
    )
  );


  constructor(
    private actions$: Actions,
    private _products: ProductsService
  ) { }

  private _getProductsInWeeklyPack(pack: Product): Observable<CartItem[]> {
    const products: { sku: string; quantity: string }[] = (pack.meta_data.find(({ key }) => key === 'selection_products_detail')?.value) || [];
    return forkJoin(
      products.map(({ sku, quantity }) =>
        this._products.getProductBySku(sku).pipe(
          map((product) => ({ product, quantity: +quantity }))
        )
      )
    );
  }
}
