<div class="footer-bottom">
    <div class="container">
      <div class="footer-legal">
        <a href="/politica-de-privacidad">Política de Privacidad</a>
        <a href="/politica-de-cookies">Política de Cookies</a>
        <a href="/condiciones-generales">Condiciones Generales</a>
      </div>
      <div class="row comunidad-row">
        <div class="social-media col-md-6 col-12">
          <span class="feel-territory">Siente el territorio RA Original</span>
          <a href="https://www.facebook.com/RAOriginal/"><span inlineSVG="assets/icons/facebook.svg"></span></a>
          <a href="https://www.instagram.com/raoriginal_/"><span inlineSVG="assets/icons/instagram-footer.svg"></span></a>
          <a href="https://twitter.com/OriginalRioja/"><span inlineSVG="assets/icons/twitter-footer.svg"></span></a>
          <!-- <a href="https://www.pinterest.es/RA_Original/"><span inlineSVG="assets/icons/pinterest.svg"></span></a> -->
        </div>

        <div class="ekomi-rating">
          <div class="ekomi-col-1">
            <span class="info-icon" inlineSVG="assets/icons/ekomi-icon.svg"></span>
          </div>
          <div class="ekomi-col-2">
            <p class="ekomi-title">THE GRAND WINES</p>
            <div class="ekomi-stars-row">
              <div class="stars">
                <img src="assets/icons/star_oro.png" alt="star" class="star">
                <img src="assets/icons/star_oro.png" alt="star" class="star">
                <img src="assets/icons/star_oro.png" alt="star" class="star">
                <img src="assets/icons/star_oro.png" alt="star" class="star">
                <img src="assets/icons/star_plata.png" alt="star" class="star">
              </div>
              <p class="ekomi-title">(4/5)</p>
            </div>
            <p class="ekomi-valorations">3.108 Valoraciones de clientes </p>
          </div>
        </div>

        <div class="app col-md-3 col-12">
          <div class="app-mobile">
            <div>
              <a href="https://play.google.com/store/apps/details?id=com.tgw.cartadevinos"><img src="assets/icons/googleplay.png"></a>
              <a href="https://apps.apple.com/es/app/thegrandwines-carta-de-vinos/id1526305006"><img src="assets/icons/appstore.png"></a>
            </div>
            <p>APP  Profesionales The Grand Wines</p>
          </div>
        </div>
        <div class="confianza col-md-3 col-12">
          <div class="logo">
            <img src="assets/icons/logo-araba-alava.svg"/>
          </div>
        </div>
      </div>
      <div class="copyright">
        <span>Copyright © 2022 RAOriginal. Todos los derechos reservados</span>
      </div>
    </div>
  </div>
