import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { AuthEffects } from './effects/auth.effects';
import * as fromAuth from './reducers';
import { AuthService } from './services';
import { isSavedGuard } from './services/route.guard';

@NgModule({
  imports: [
    StoreModule.forFeature(fromAuth.authKey, fromAuth.reducer),
    EffectsModule.forFeature([AuthEffects]),
  ],
  providers: []
})
export class AuthModule { }
