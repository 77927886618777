import { createReducer, on } from '@ngrx/store';
import { OffersActions } from '../actions';
import { Product } from '../models';

export const offersKey = 'offers';

export interface State {
  products: { [page: number]: Product[] };
  currentPage: number;
  totalPages: number;
  found: number;
  max_price: number;
  min_price: number;
  products_ids: number[]
}


const initialState: State = {
  products: {},
  currentPage: 1,
  totalPages: 1,
  found: 0,
  max_price: 0,
  min_price: 0,
  products_ids: []
}

const offersReducer = createReducer(
  initialState,
  on(OffersActions.saveOfferProducts, (state, { page, products, totalPages, found, maxPrice, minPrice }) => {
    return {
      ...state,
      products: { ...state.products, [page]: products },
      totalPages,
      currentPage: page,
      found,
      maxPrice,
      minPrice
    }
  })
);

export function reducer(state: State | undefined, action: OffersActions.OffersActionsUnion) {
  return offersReducer(state, action);
}

export const getCurrentPage = (state: State) => state.currentPage;

export const getTotalPages = (state: State) => state.totalPages;

export const getTotalfound = (state: State) => state.found;

export const getOfferProducts = (state: State) => state.products;



