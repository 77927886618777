import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, HostListener, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { ActivatedRoute, PRIMARY_OUTLET, Router, UrlSegmentGroup, UrlSegment } from '@angular/router';

@Component({
  selector: 'tgw-paginator',
  template: `
  <div class="paginator">
  <ng-container *ngIf="currentPage as currentPage">
  <div *ngIf="totalPages as totalPages" class="paginator">
    <button class="last" *ngIf="currentPage != 1" [routerLink]="['./../' + 1]"  [queryParams]="queryParams"
       type="button">{{ isMobile? '<<' : 'tgw-magazine-paged-first' | translate }}</button>

    <div *ngIf="!queryParams">
      <button class="last" *ngIf="currentPage != 1" (click)="goTo(-1)" 
        type="button paged-previous">{{ isMobile? '<' : 'tgw-magazine-paged-previous' | translate }}</button>
    </div>
    <div *ngIf="queryParams">
      <button class="last" *ngIf="currentPage != 1" [routerLink]="['./../' + last]"  [queryParams]="queryParams"
        type="button paged-previous">{{ isMobile? '<' : 'tgw-magazine-paged-previous' | translate }}</button>
    </div>

    <button *ngFor="let page of pages" [routerLink]="['./../' + page]"  [queryParams]="queryParams" [class.active]="page == currentPage"
      [disabled]="page == currentPage" type="button">{{page}}</button>

    <div *ngIf="!queryParams">
      <button *ngIf="currentPage != totalPages" (click)="goTo(1)"
        type="button" class="nex paged-next">{{ isMobile? '>' : 'tgw-magazine-paged-next' | translate }}</button>
    </div>
    <div *ngIf="queryParams">
      <button *ngIf="currentPage != totalPages" [routerLink]="['./../' + nex]"  [queryParams]="queryParams"
        type="button" class="nex paged-next">{{ isMobile? '>' : 'tgw-magazine-paged-next' | translate }}</button>
    </div>

    <button class="last paged-last" *ngIf="currentPage != totalPages" [routerLink]="['./../' + totalPages]"
      [queryParams]="queryParams" type="button">{{ isMobile? '>>' : 'tgw-magazine-paged-last' | translate }}</button>
  </div>
</ng-container>
    </div>
  `,
  styleUrls: ['./paginator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaginatorComponent implements OnChanges {
  @Input() currentPage: number;
  @Input() totalPages: number;
  @Input() queryParams: Object;

  @Output() pagedRequest = new EventEmitter<UrlSegmentGroup>();

  private _allPages: number[] = [];
  public pages: number[] = [];
  public idCategorie: string;
  private rutaId: UrlSegmentGroup;
  public nex: number;
  public last: number;

  cleanQueryParams() {
    let auxParams = {};
    for (const param in this.queryParams) {
      if (this.queryParams[param] === "") auxParams[param.replace(/ /g, "-")] = "all";
      else auxParams[param.replace(/ /g, "-")] = this.queryParams[param].replace(/ /g, "-");
    }
    return auxParams;
  }

  innerWidth = window.innerWidth 
  isMobile: boolean = false;

  constructor(private router: Router, private cdRef: ChangeDetectorRef, private route: ActivatedRoute) {
    this.rutaId = this.router.parseUrl(this.router.url).root.children[PRIMARY_OUTLET];
    this.numberPagedShort();
    if(this.innerWidth < 1000){
      this.isMobile = true;
    }
  }

  ngOnInit(): void {
    this._allPages = this.pagesPaged(this.totalPages);
    this.numberPagedShort();
    
    this.route.params.subscribe(params => {
      if(!params.productPage){
        
        this.currentPage = +params.currentPage;
        this.cdRef.markForCheck();
      }
    })
  }

  goTo(n: number){
    this.currentPage += n
    const urlWithNoParams = this.router.parseUrl(this.router.url).root.children[PRIMARY_OUTLET].segments.splice(0, this.rutaId.segments.length - 1)

    this.router.navigate([urlWithNoParams.map(it => it.path).join('/')+ '/' +this.currentPage])
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.totalPages == undefined) {
      this.getPagedRouter();
    } else {
      this.numberPagedShort();
    }
  }


  numberPagedShort(): void {
    this.currentPage = this.rutaId.segments[3] != undefined ? +this.rutaId.segments[3].path : +this.rutaId.segments[1].path;
    this.nex = (1 * this.currentPage) + 1;
    this.last = (1 * this.currentPage) - 1;
    const lower = this.currentPage < 4 ? 0 : this.currentPage - 3;
    const upper = this.currentPage < 4 ? 5 : this.currentPage + 2;
    this.pages = this._allPages.slice(lower, upper);
    this.cdRef.markForCheck();
  }

  getPagedRouter(): void {
    this.numberPagedShort();
  }

  pagesPaged(pagTotal: number): any[] {
    let pagedArray: number[] = [];
    for (let paged = 1; paged <= pagTotal; paged++) {
      pagedArray.push(paged);
    }
    return pagedArray;
  }

  @HostListener('window:resize', ['$event'])
    onResize(event) {
      if(window.innerWidth < 1000){
        this.isMobile = true
      }else{
        this.isMobile = false
      }
      this.innerWidth = window.innerWidth;
    }

}
