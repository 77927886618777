<div class="row-container container">
  <div class="row">
    <div class="col-12 col-lg-6">
      <div class="row">

        <div class="container-col col-6" (click)="mobile ? changeVisibility() : null">
          <div class="title">
            <div class="footer-title">
              <p>CONTACTO</p>
            </div>
            <div *ngIf="mobile" class="arrow">
              <img src="assets/icons/footer-link-section-arrow.png" [ngClass]="{'reverse': !close}">
            </div>
          </div>

          <div class="links" *ngIf="!close" (click)="$event.stopPropagation()">
            <span [inlineSVG]="logo" alt="The Grand Wines" class="rao-logo"></span><br>
            <div class="email-tlf-links">
              <p>info@raoriginal.eus</p>
              <p>654 68 22 96</p>
            </div>
          </div>
        </div>
        <div class="col-6 row-links">
          <tgw-link-section-col *ngFor="let section of links" [mobile]="mobile" [section]="section">
          </tgw-link-section-col>
        </div>
      </div>

    </div>
    <div class="col-12 col-lg-6">

      <div class="container-col" (click)="mobile ? closeNewsletter = !closeNewsletter : null">
        <div class="title">
          <div class="footer-title">
            <p>NEWSLETTER</p>
          </div>
          <div *ngIf="mobile" class="arrow">
            <img src="assets/icons/footer-link-section-arrow.png" [ngClass]="{'reverse': !closeNewsletter}">
          </div>
        </div>

        <div class="links-newsletter" *ngIf="!closeNewsletter" (click)="$event.stopPropagation()">

          <div class="subscribe">
            <mat-form-field class="newsletter-input" appearance="none">
              <input matInput [formControl]="newsletterEmail" type="email"
                [placeholder]=" 'tgw-magazine-write-emai-newsletter' | translate " />
            </mat-form-field>

            <button mat-raised-button matSuffix class="newsletter-button" type="button btn-primary" (click)="subscribeNewsletter()"
              [disabled]="newsletterEmail.disabled">{{'tgw-newsletter-button' | translate}}</button>
          </div>
          <div class="legal-disclaimer">
            <p>Al rellenar dicho formulario, aceptas nuestros <a href="">Términos y condiciones</a> , la <a href="">Política de privacidad</a> y la <a href="">Política de revocación.</a></p>
          </div>
            


          <div class="ekomi-rating">
            <div class="ekomi-col-1">
              <span class="info-icon" inlineSVG="assets/icons/ekomi-icon.svg"></span>
            </div>
            <div class="ekomi-col-2">
              <p class="ekomi-title">THE GRAND WINES</p>
              <div class="ekomi-stars-row">
                <div class="stars">
                  <img src="assets/icons/star_oro.png" alt="star" class="star">
                  <img src="assets/icons/star_oro.png" alt="star" class="star">
                  <img src="assets/icons/star_oro.png" alt="star" class="star">
                  <img src="assets/icons/star_oro.png" alt="star" class="star">
                  <img src="assets/icons/star_plata.png" alt="star" class="star">
                </div>
                <p class="ekomi-title">(4/5)</p>
              </div>
              <p class="ekomi-valorations">3.108 Valoraciones de clientes </p>
            </div>
          </div>

        </div>
      </div>

    </div>
  </div>
</div>