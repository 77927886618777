import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { CoreConfigService, ErrorHandlerActions, LayoutActions } from '@tgw-clients/shared';
import { WoocommerceService } from '@tgw-clients/shared/utils/services/woocommerce.service';
import { startWith } from 'rxjs/operators';

@Component({
  selector: 'rao-landing-form',
  templateUrl: './rao-landing-form.component.html',
  styleUrls: ['./rao-landing-form.component.scss']
})
export class RAOLandingFormComponent implements OnInit {

  spainStates$ = this._woocommerce
    .getStates('ES')
    .pipe(startWith({ code: 'ES', name: 'España', states: [] }));

  public form = new FormGroup({
    email: new FormControl('', [Validators.email, Validators.required]),
    nameRest: new FormControl('', Validators.required),
    province: new FormControl('', Validators.required),
    name: new FormControl('', Validators.required),
    telefon: new FormControl('', Validators.required)
  });

  constructor(private _woocommerce: WoocommerceService, private _coreConfig: CoreConfigService, private store: Store) { }

  ngOnInit(): void {
  }

  subscribeB2c(): void {
    console.log(this.form.valid)
    if (this.form.valid) {
      this.store.dispatch(LayoutActions.subscribeB2c({ email: this.form.get('email').value, nameRest: this.form.get('nameRest').value, province: this.form.get('province').value, name: this.form.get('name').value, telefon: this.form.get('telefon').value }));
    }else{
      console.log(this.form.valid);
      this.store.dispatch(ErrorHandlerActions.notifyUser({ message: 'tgw-form-service-failure-validate' }));
    }
  }

}
