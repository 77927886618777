// Inyected by webpack
declare const VERSION: string;

// export const Languages = ['es', 'en'];
export const Languages = ['es'];


export const DefaultLang = 'es';

export const ProductsConfig = {
  perPage: 12,
  perPageMagazine: 6,
  perPageExperience: 6
}

export const BusinessType = 'b2c';

export const Brand = 'tgw';
