import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { NotificationDialogComponent } from '../components/notification-dialog.component';

@Injectable()
export class ErrorHandlerWebService {

  constructor(public dialog: MatDialog) { }

  public notify({ message, title }: { message: string, title?: string }, messageType?: 'error' | 'success' | 'warning'): Observable<any> {

    if (messageType === 'error') {
      console.error(`%c Notifier: ${message}`, 'background: #f00; color: #fff; padding: 4px; font-weight: 600;');
    }

    return this.dialog.open(NotificationDialogComponent, { data: { message, title } }).afterClosed();
  }

}
