import { ChangeDetectionStrategy, Component, HostListener, Input, OnChanges, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { CoreConfigService, ErrorHandlerActions, LayoutActions, Logo } from '@tgw-clients/shared';

@Component({
  selector: 'rao-link-footer',
  templateUrl: './rao-link-footer.component.html',
  styleUrls: ['./rao-link-footer.component.scss']
})
export class RaoLinkFooterComponent implements OnInit, OnChanges {
  mobile = false
  newsletterEmail = new FormControl('', [Validators.email, Validators.required]);
  
  logo = this._coreConfig.getLogo(Logo.Header);
  close: boolean = false;
  closeNewsletter: boolean = false;

  links = [
    // {title: 'CONTACTO', links:[{logo: this.logo, link: 'https://www.raoriginal.eus/'} , {title:'info@raoriginal.eus'}, {title: '654 68 22 96'}]},
    {title: 'INFORMACIÓN', links: [{title: 'Método', link: 'https://www.raoriginal.eus/club'}, {title: 'Historia', link:'https://www.raoriginal.eus/club'}, {title: 'Contáctanos', link: 'https://www.raoriginal.eus/club'}]},
  ]
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.mobile = event.target.innerWidth <= 991
  }
  constructor(private _coreConfig: CoreConfigService, private store: Store) {
    this.mobile = window.screen.width <= 991
  }
  ngOnInit(): void {
    if(this.mobile){
      this.close = true
      this.closeNewsletter = true
    } else{
      this.close = false
      this.closeNewsletter = false
    }
  }
  
  ngOnChanges(){
    if(this.mobile){
      this.close = true
      this.closeNewsletter = true
    } else{
      this.close = false
      this.closeNewsletter = false
    }
  }

  changeVisibility(){
    this.close = !this.close
  }

  subscribeNewsletter(): void {
    if (this.newsletterEmail.invalid) {
      const message = this.newsletterEmail.errors?.required ? 'tgw-newsletter-email-required' : 'tgw-form-errors-email';
      this.notifyError(message);
      return;
    }

    this.subscribeEmail(this.newsletterEmail.value);
  }
  
  subscribeEmail(email: string): void {
    this.store.dispatch(LayoutActions.subscribeNewsletter({ email, selection: ['particular'] }));
    this.newsletterEmail.reset({ value: '', disabled: false });
  }
  
  notifyError(message: string): void {
    this.store.dispatch(ErrorHandlerActions.notifyUser({ message }));
  }

}
