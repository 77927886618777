<ng-container *ngTemplateOutlet="header"></ng-container>
<ng-container *ngIf="scrolled">
    <ng-container *ngTemplateOutlet="header; context: { $implicit: true }"></ng-container>
</ng-container>
<ng-template #header let-scrolled>
    <header [ngClass]="type" [class.scrolled]="scrolled" [class.clubrao]="memberRao"
        [class.b2c-header]="isB2cBusinessType">
        <div class="container">
            <mat-toolbar>
                <mat-toolbar-row #mainRow class="header-actions" [class.b2c-lang-row]="isB2cBusinessType" [ngClass]="{'menu-wrapper-no-landing ':  !isOnLandingPage() }">
                    <ng-container *ngIf="['b2c'].includes(businnesType)">
                        <ul *ngIf="languages.length" [disabled]="languages.length === 0" #langSelector
                            (change)="changeLang.next(langSelector.value)" class="lang-selector">
                            <li *ngFor="let language of languages; trackBy: trackByFn" [selected]="lang === language"
                                [value]="language">
                                {{ language | uppercase }}
                            </li>
                        </ul>
                    </ng-container>
                    <ng-template [ngIf]="type === 'professionals'">
                        <button *isBrand="'rao'" class="mobile-menu-button" mat-icon-button (click)="openMenu()">
                            <span class="icon" inlineSVG="assets/icons/menu_icon.svg"></span>
                        </button>
                        <tgw-mobile-menu class="mobile-menu" *ngIf="showMenu" (hideMenu)="hideMenu()"
                            [languages]="languages" [lang]="lang" [type]="type" [user]="username" [menu]="auxMenu"
                            (hide)="hideMenu()" (changeLang)="changeLang.next($event)" (logout)="logout.next()"
                            (search)="searchSubmit($event)" @menu></tgw-mobile-menu>
                    </ng-template>
                    <!-- <div *ngIf="!scrolled" class="fill-container"></div> -->
                    <div class="professionals-logo" *ngIf="type === 'professionals' && !scrolled">
                        <a [routerLink]="['/' | localize: lang]">
                            <img class="logo-desktop" [src]="isOnLandingPage() ?'assets/icons/logo_tgw_2.svg':'assets/icons/logo tgw_Mesa de trabajo 1 2.png'" alt="The Grand Wines" />
                            <img class="logo-mobile" src="assets/icons/logo_tgw_mobile_black.svg"
                                alt="The Grand Wines" />
                        </a>
                    </div>
                </mat-toolbar-row>
                <mat-toolbar-row *ngIf="!scrolled" class="header-intro">
                    <div *ngIf="type === 'landing'">
                        <h2>{{ 'tgw-winery-header-title' | translate | uppercase }}</h2>
                        <h3>{{ 'tgw-winery-header-subtitle' | translate }}</h3>
                    </div>
                    <!-- <h3>{{ 'tgw-winery-header-subtitle' | translate }}</h3> -->
                    <!-- <ng-container *isBrand="'tgw'">
                        <img *ngIf="type === 'landing'" class="img-prize" [src]="prizeLogo" />
                    </ng-container> -->
                </mat-toolbar-row>
                <mat-toolbar-row *ngIf="type === 'professionals' && auxMenu?.length" class="menu-row" [ngClass]="{'menu-wrapper-no-landing ':  !isOnLandingPage() }">
                    <div class="menu-wrapper" [ngClass]="{'menu-wrapper-no-landing ':  !isOnLandingPage() }"
                        *ngIf="auxMenu">
                        <ng-container *ngFor="
                    let item of auxMenu;
                    let i = index;
                    trackBy: trackById
                  ">
                            <span [class.b2c-separator]="isB2cBusinessType">|</span>
                            <div>
                                <ng-template [ngIf]="item.children?.length" [ngIfElse]="linkItem">
                                    <!-- (mouseleave)="hideSubmenu($event)"  linea 224 -->
                                    <a *ngIf="item.tgw_slug == 'tienda'" (mouseenter)="
                          scrolled
                            ? (showSubmenuScrolled = true)
                            : (showSubmenu = true)
                        " (click)="
                          scrolled
                            ? (showSubmenuScrolled = false)
                            : (showSubmenu = false)
                        " [routerLink]="item.tgw_slug" routerLinkActive="current">{{ item.title }}</a>
                                    <a *ngIf="item.tgw_slug == 'colecciones'" (mouseenter)="
                          scrolled
                            ? (showCollectionsScrolled = true)
                            : (showCollectionsMenu = true)
                        " (click)="
                          scrolled
                            ? (showCollectionsScrolled = false)
                            : (showCollectionsMenu = false)
                        " [routerLink]="item.tgw_slug" routerLinkActive="current">{{ item.title }}</a>

                                    <ng-container *ngIf="!scrolled;">
                                        <div *ngIf="item.tgw_slug == 'tienda'" #submenu
                                            (mouseleave)="showSubmenu = false"
                                            [ngClass]="'submenu-item-'+ item.tgw_slug" [class.displayed]="showSubmenu"
                                            [class.extramargin]="memberRao">
                                            <!-- [ngClass]="[item.children?.length ? 'submenu-item-'+ item.tgw_slug: '']" -->
                                            <div class="submenu-section" *ngFor="let subItem of item?.children">
                                                <p>{{ subItem.title }}</p>
                                                <p>
                                                    <tgw-header-submenu (hideSubmenuOnClick)="showSubmenu = false"
                                                        [show]="show" [menuId]="subItem?.tgw_id"
                                                        [slug]="subItem?.title" [urlFilter]="subItem?.description"></tgw-header-submenu>
                                                </p>

                                            </div>
                                        </div>

                                        <div *ngIf="item.tgw_slug == 'colecciones'" #collectionsMenuElement
                                            (mouseleave)="showCollectionsMenu = false"
                                            [ngClass]="'submenu-item-'+ item.tgw_slug"
                                            [class.displayed]="showCollectionsMenu" [class.extramargin]="memberRao">
                                            <!-- <ng-template class="collection-submenu"> -->
                                            <div class="submenu-section">
                                                <collections-submenu [items]="item.children"
                                                    (hideMenu)="hideCollectionsMenu($event)"></collections-submenu>
                                            </div>
                                            <!-- </ng-template> -->
                                        </div>
                                    </ng-container>
                                    <!-- <ng-template #scrolledSubmenu>
                                        <div *ngIf="item.tgw_slug == 'tienda'" #submenuScrolled
                                            (mouseleave)="showSubmenuScrolled = false"
                                            [class.displayed]="showSubmenuScrolled"
                                            [class.reposition]="showSubmenuScrolled"
                                            [ngClass]="'submenu-item-'+ item.tgw_slug"
                                            [class.scrolledextramargin]="memberRao">
                                            <div class="submenu-section" *ngFor="let subItem of item?.children">
                                                <p>{{ subItem.title }}</p>
                                                <p>
                                                    <tgw-header-submenu
                                                        (hideSubmenuOnClick)="showSubmenuScrolled = false" [show]="show"
                                                        [menuId]="subItem?.tgw_id" [slug]="subItem?.title">
                                                    </tgw-header-submenu>
                                                </p>
                                            </div>
                                        </div>
                                        <div *ngIf="item.tgw_slug == 'colecciones'" #collectionsScrolledElement
                                            (mouseleave)="showCollectionsScrolled = false"
                                            [class.displayed]="showCollectionsScrolled"
                                            [class.reposition]="showCollectionsScrolled"
                                            [ngClass]="'submenu-item-'+ item.tgw_slug"
                                            [class.scrolledextramargin]="memberRao">

                                            <div class="submenu-section">
                                                <collections-submenu [items]="item.children"
                                                    (hideMenu)="hideCollectionsMenu($event)"></collections-submenu>
                                            </div>

                                        </div>
                                    </ng-template> -->
                                </ng-template>
                                <ng-template #linkItem>
                                    <ng-template [ngIf]="item.tgw_slug" let-slug [ngIfElse]="disabled">
                                        <a *ngIf="item.type_label !== 'Enlace externo'" (mouseenter)="
                            scrolled
                              ? (showSubmenuScrolled = false)
                              : (showSubmenu = false)
                          " [routerLink]="slug" routerLinkActive="current">
                                            <ng-container *ngIf="item.title !=='Club RAO'">
                                                {{ item.title }}
                                            </ng-container>
                                            <!-- <ng-container *ngIf="item.title ==='Club RAO'">
                                                <span [class.b2c-logo-RAO]="isB2cBusinessType" class="icon"
                                                    inlineSVG="assets/icons/clubRao.svg"></span>
                                            </ng-container> -->
                                        </a>
                                        <a *ngIf="item.type_label === 'Enlace externo'" (mouseenter)="
                            scrolled
                              ? (showSubmenuScrolled = false)
                              : (showSubmenu = false)
                          " [href]="slug" routerLinkActive="current">{{ item.title }}</a>

                                    </ng-template>
                                    <ng-template #disabled>
                                        <a class="disabled">{{ item.title }}</a>
                                    </ng-template>
                                </ng-template>
                            </div>
                        </ng-container>
                        <span *ngIf="!mobile" [class.b2c-separator-extra]="isB2cBusinessType">|</span>
                    </div>

                    <div class="header-top-nav" [ngClass]="{'menu-wrapper-no-landing ':  !isOnLandingPage() }"
                        [class.b2c-header-top-nav]="isB2cBusinessType">
                        <form *isBusinessType="'!winery'" class="search-wrapper hidden-sm-down" [formGroup]="searchForm"
                            (ngSubmit)="searchSubmit(searchForm.value.search)"
                            [ngClass]="{'menu-wrapper-no-landing ':  !isOnLandingPage() }">
                            <fieldset>
                                <input formControlName="search" placeholder="Buscar..." type="text" />
                                <button type="button" type="submit">
                                    <span *isBrand="'rao'" class="icon search"
                                        inlineSVG="assets/icons/search.svg"></span>
                                    <span *isBrand="'tgw'" class="icon " [ngClass]="{'search ':  isOnLandingPage() }"
                                        inlineSVG="assets/icons/search.svg"></span>
                                </button>
                            </fieldset>
                        </form>
                        <div class="user-section" [ngClass]="{'menu-wrapper-no-landing ':  !isOnLandingPage() }">
                            <div [ngClass]="{'logueado' : logueado}">
                                <a [routerLink]="['/mi-cuenta' | localize: lang]" *ngIf="!logueado">
                                    <span class="icon" inlineSVG="assets/icons/user_icon.svg"></span>
                                </a>
                                <a [routerLink]="['/mi-cuenta/datos' | localize: lang]" *ngIf="logueado">
                                    <span class="icon" inlineSVG="assets/icons/user_icon.svg"></span>
                                </a>
                            </div>
                        </div>
                        <ng-template [ngIf]="type === 'professionals'">
                            <div class="icon cart-icon" [class.cart-reduce]="scrolled"
                                [class.b2c-cart-icon]="isB2cBusinessType" (mouseenter)="showCart(cart)"
                                [ngClass]="{'menu-wrapper-no-landing ':  !isOnLandingPage() }">
                                {{ 'tgw-cart-your-cart' | translate }} ({{ getCartCount(cart) }})
                            </div>
                            <div class="cart-icon cart-reduce mobile-cart hidden-md-up" (click)="toggleCart(cart)">
                                <span class="icon" inlineSVG="assets/icons/bolsa.svg"></span>
                            </div>
                            <tgw-cart [scrolled]="scrolled" *ngIf="isCartVisible" [cart]="cart"
                                (delete)="removeCartProduct.next($event)" (mouseleave)="hideCart()"
                                (closeCart)="hideCart()" [isLoggedIn]="isLoggedIn$ | async" [clubUser]="memberRao">
                            </tgw-cart>
                        </ng-template>
                    </div>
                </mat-toolbar-row>
            </mat-toolbar>
            <tgw-search *isBrand="'tgw'" class="search-nav" (openSearchMenu)="openMenu()"></tgw-search>
        </div>
    </header>
</ng-template>
