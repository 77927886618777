import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, Output, Renderer2, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { PRIMARY_OUTLET, Router } from '@angular/router';
import { CartItem, CoreConfigService, Product, addToDataLayer, TrackerService, AuthService } from '@tgw-clients/shared';
@Component({
  selector: 'tgw-product-card-tgw',
  template: `
    <div *ngIf="product" [ngClass]="{'new-b2c-design': ['b2c'].includes(businnesType)}" [id]="divId" (click)="addToDataLayer('productClick', product, dataLayerItemName, businnesType)" [routerLink]="['/tienda/producto/' + product.slug | localize]">
      <div class="product-inner" [class.price]="['b2c'].includes(businnesType) || showPrice">
        <img  [id]="'img-product-' + product.id" *ngIf="product.images[0] as image" [src]="image.src"/>

        <ng-container *ngIf="['b2c'].includes(businnesType)">
          <img #sixPack  *ngIf="1 < +getMetadataPack(product) && !enter" class="six-pack-icon" [src]="'assets/icons/bottlex' + getMetadataPack(product) + '.png'"/>
          <!-- Porcentaje de descuento solo para miembros de Rao, se muestra el descuento de rao si existe sino el descuento clasico -->
          <div *ngIf="clubUser && (getRaoPrice(product) != undefined ||  product.sale_price != '')" class="product-discount-icon" >{{getDiscountPercentage(product, clubUser)}} </div>
        </ng-container>

        <div *ngIf="!['b2c'].includes(businnesType) && showPrice" class="add-to-cart">
          <ng-container *ngIf="+getMetadataPack(product) as pack">
            <span *ngIf="1 < pack">{{'tgw-product-pack' | translate}} {{ pack }}</span>
            <span *ngIf="pack === 1"> {{ pack }} {{'tgw-commons-bottle' | translate}}</span>
          </ng-container>

          <span *isBusinessType="['b2b']" class="price" [class.discounted]="+product.sale_price">{{ findOriginalPrice(product) | currency:'EUR':'symbol':'1.2-2' }}<span>{{ 'tgw-cart-vat-no-included' | translate }}</span></span>

          <span *isBusinessType="['b2c']" class="price" [class.discounted]="+product.sale_price || (clubUser && getRaoPrice(product))">{{ product.regular_price | currency:'EUR':'symbol':'1.2-2' }}<span>{{ 'tgw-cart-vat-included' | translate }}</span></span>

          <span class="price" *ngIf="+product.sale_price as salePrice">
            <ng-container *isBusinessType="'b2c'">
              {{ salePrice | currency:'EUR':'symbol':'1.2-2' }} <span>{{'tgw-cart-vat-included' | translate}}</span>
            </ng-container>
            <ng-container *isBusinessType="'b2b'">
              {{ (salePrice / 1.21) | currency:'EUR':'symbol':'1.2-2' }} <span>{{'tgw-cart-vat-no-included' | translate}}</span>
            </ng-container>
          </span>

          <ng-container *ngIf="['b2c'].includes(businnesType) || showPrice">
            <div class="qty">
              <button type="button" (click)="addQuantity($event, -1)"><mat-icon>-</mat-icon></button>
              <input [formControl]="quantity" type="number" min="1" step="1" readonly (click)="$event.stopPropagation()"/>
              <button type="button" (click)="addQuantity($event, 1)"><mat-icon>+</mat-icon></button>
            </div>
            <button class="btn btn-primary" id="addProductToCart" (click)="addToCart($event)">{{'tgw-cart-buy-button-label' | translate}}</button>
          </ng-container>
        </div>
      </div>


      <div *isBusinessType="['b2c']" class="product-card-content" (click)="$event.stopPropagation()">
        <div class="product-row">
          <p class="do-title" style="display:inline-block">{{getAttribute(product, 'Denominacion de origen')}}</p> <!-- DENOMINACION DE ORIGEN -->
        </div>
        <div *ngIf="!enter; else productInfo" class="product-title">
          <div class="product-row">
            <p class="winery-title" style="display:inline-block" [innerHTML]="product?.winery_title"></p>
            <p *ngIf="(!clubUser && product?.sale_price != '') || (clubUser && (getRaoPrice(product) != undefined || product?.sale_price != ''))" class="product-original-price" >{{product?.regular_price | currency:'EUR':'symbol':'1.2-2' }}</p>
          </div>

          <div class="product-row">
            <p class="title" style="display:inline-block" [innerHTML]="product.name + ' ' + getAttribute(product, 'Añada')"></p>
            <p class="product-price" style="display:inline-block;">{{ getPrice(product, clubUser) | currency:'EUR':'symbol':'1.2-2' }}</p>
          </div>
        </div>
        <ng-template #productInfo>
          <div class="info-hover">
            <p>{{product.short_description.slice(3, product.short_description.length - 5)}}</p>
          </div>
        </ng-template>

        <div class="add-t-cart" *ngIf="+product?.regular_price && (['b2c'].includes(businnesType) || showPrice)" (click)="$event.stopPropagation()">
          <div class="qty">
            <button type="button" (click)="addQuantity($event, -1)"><mat-icon>-</mat-icon></button>
            <input [formControl]="quantity" type="number" min="1" step="1" readonly (click)="$event.stopPropagation()"/>
            <button type="button" (click)="addQuantity($event, 1);$event.stopPropagation()"><mat-icon>+</mat-icon></button>
          </div>
          <button class="btn btn-primary" id="addProductToCart" style="display:inline-block;"  (click)="addToCart($event);$event.stopPropagation()">{{'tgw-cart-buy-button-label' | translate}}</button>
        </div>
      </div>

      <div *ngIf="!['b2c'].includes(businnesType)" class="product-title">
        <p class="title" [innerHTML]="product.name + ' ' + getAttribute(product, 'Añada')"></p>
        <p>{{getAttribute(product, 'Municipio')}}</p>
      </div>
    </div>
  `,
  styleUrls: ['./product-card-tgw.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductCardTgwComponent {
  addToDataLayer = addToDataLayer;
  @Input() hover: boolean = false;
  @Input() product: Product;
  @Input() showPrice: boolean;
  @Input() dataLayerItemName: string;
  @Input() divId: string;
  @Input() clubUser: boolean;
  @Output() add = new EventEmitter<CartItem>();
  @ViewChild("sixPack") sixPack: ElementRef;

  enter: boolean = false;
  quantity = new FormControl(1, Validators.min(1));
  businnesType = this.coreConfig.businessType;


  constructor(private coreConfig: CoreConfigService, private trackerService: TrackerService, private authService: AuthService,private router: Router,private renderer: Renderer2) {

  }

  ngAfterViewInit(): void {
    let rutaId = this.router.parseUrl(this.router.url).root.children;
    if(this.sixPack && !rutaId.primary){
      this.renderer.addClass(this.sixPack.nativeElement, "six-pack-icon-landing");
    }
  }


  getAttribute(product: Product, attr: string): string {
    const productOptions = product.attributes.find(({ name }) => (name.split(";").length == 2 ? name.split(";")[1] : name) === attr)?.options[0] || '';
    if (attr === 'Añada') {
      return /[0-9]+$/.test(productOptions) ? productOptions : '';
    }
    else {
      return productOptions
    }
  }

  getRaoPrice (product: Product) {
    let aux = product.meta_data.find(p => p.key === "rao_club_price");
    if (aux && aux.value != "") return parseFloat(aux.value.replace(',','.'));
    else return undefined;
  }

  getPrice(product: Product, isRao: boolean) {
    const sale_price = product.sale_price;
    const regular_price = product.regular_price;
    if (isRao) {
      const rao_price = this.getRaoPrice(product);
      if (rao_price) return rao_price;
      else if (sale_price != "") {
        return sale_price
      } else {
        return regular_price;
      }
    } else {
      if (sale_price != "") return sale_price;
      else return regular_price;
    }
  }

  addToCart(event: MouseEvent): void {
    event.stopPropagation();
    addToDataLayer('addToCart', this.product, 'agregarProductoAlCarrito', this.businnesType, +this.quantity.value)
    this.add.next({ product: this.product, quantity: +this.quantity.value });
    this.quantity.reset(1);
    if (this.businnesType == "b2c") {
      let payload:any = {};
      payload.eventName = "AddToCart";
      payload.contentId = this.product.id;
      payload.price = this.product.price;
      if (this.authService.hasToken()) {
        this.authService.getUserInfo().subscribe((data:any)=> {
          payload.firstName = data.first_name;
          payload.lastName = data.last_name;
          payload.email = data.email
          this.trackerService.sendData({
            service: "facebook",
            payload
          }).subscribe();
        });
      } else {
        payload.firstName = "";
        payload.lastName = "";
        payload.email = "";
        this.trackerService.sendData({
          service: "facebook",
          payload
        }).subscribe();
      }
    }
  };


  addQuantity(event: MouseEvent, quantity: number): void {
    event.stopPropagation();
    const result = this.quantity.value + quantity;
    if (0 < result) {
      this.quantity.setValue(result);
    }
  };

  getMetadataPack(product: Product): number {
    return product?.meta_data.find(item => item?.key === 'pack')?.value || 0;
  }

  findOriginalPrice(product: Product): string {
    return product.meta_data?.find(({ key }) => key === 'original_price')?.value?.replace(',','.') || '';
  }

  getDiscountPercentage(product: Product, isRao: boolean = false): string {
    return '-' + (100 - ( (+this.getPrice(product, isRao) / +product?.regular_price)*100)).toFixed(0) + '%';
  }

  mouseEvent(product, isEnter, hover) {
    if (hover && product && product.images.length > 1) {
      this.enter = isEnter;
      let element: HTMLImageElement;
      element = <HTMLImageElement>document.getElementById("img-product-" + product.id);
      element.src=isEnter ? product.images[1].src : product.images[0].src;
    }
  }

}
