import { HttpClient, HttpParams } from '@angular/common/http';
import { Capacitor } from '@capacitor/core';
import { Preferences } from '@capacitor/preferences';
import { TranslateLoader, TranslateService } from '@ngx-translate/core';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Environment } from '../../utils';

export class CustomI18nLoader implements TranslateLoader {

  private literalSource = new Subject<any>();
  public literal = this.literalSource.asObservable()

  constructor(private http: HttpClient, private env: Environment) {
    this.setLiteral();
   }

  getTranslation(lang: string): Observable<any> {
    let request: Observable<any> = throwError('Invalid locale');
    let params;
    switch (lang) {
      case 'es':
        request = this.literal
        break;
      case 'en':
        params = new HttpParams()
          .append('lang', lang);
        request = this.http.get(this.env.literalEndpoint, { params });
        break;
      case 'eu':
        params = new HttpParams()
          .append('lang', lang);
        request = this.http.get(this.env.literalEndpoint, { params });
        break;
      default:
        console.error('Invalid locale');
    }
    return request.pipe(
      catchError(() => [{}])
    );
  }

  public async setLiteral(): Promise<void> {
    const {value} = await Preferences.get({ key: 'literal' });
    if(value && Capacitor.getPlatform() !== 'web'){
      this.literalSource.next(JSON.parse(value))
    }else{
        const literal = (await this.http.get(this.env.literalEndpoint).toPromise())
        if(literal)
            this.literalSource.next(literal)

        await Preferences.set({
            key: 'literal',
            value: JSON.stringify(literal)
        });
    }
}


}


export function appInitTranslations(translate: TranslateService, languages: string[], defaultLang: string): Promise<any> {
  return new Promise<void>(resolve => {
    translate.addLangs(languages);
    translate.setDefaultLang(defaultLang);
    translate.use(defaultLang).subscribe(() => resolve());
  });
}


